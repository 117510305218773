import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MESSAGE, TICKET, PROP, LIST_BET_TYPE_3, LIST_BET_TYPE_2, LIST_BET_TYPE_1 } from '../../../utils/constants';
import { formatDecimal, replaceCost, getDecimalCount } from '../../../utils/common';
import { showNotification } from '../../../utils/notification';
import HeaderLottie from '../../../components/HeaderLottie';
import Loading from '../../../components/Loading';
import fight from '../../../assets/lottie/fight.json';
import { Button, Modal, Spin, Icon } from 'antd';
import { TitleSuccess } from '../../../components/frame';
import { createRoomApi, getListRoomApi, joinRoomApi, checkIsInRoomApi, joinLookRoomApi } from '../../../api/question/fight/lobby';
import { getQuestionLevelSelectApi } from '../../../api/question/fight/fight';
import { getCategory } from '../../../actions/category';
import InfiniteScroll from 'react-infinite-scroller';
import TooltipUser from '../../../components/TooltipUser';
import { withRouter } from 'react-router-dom';
import { showModalBuyVip } from '../../../actions/home/modalBuyVip';

class Lobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            showModal: false,
            roomName: 'Chiến đấu nào!',
            betTypeSelect: 3,
            questionLevelSelect: '',
            betValue: LIST_BET_TYPE_3[0].value,
            maxPlayer: 2,
            timeLimit: 15,
            fightType: 1,
            listRoom: {},
            endListRoom: {},
            isLoadingListRoom: true,
            isJoinRoom: false,
            listSelect: [],
            isInRoom: false
        };
        this.page = 1;
    }

    checkIsInRoom = () => {
        checkIsInRoomApi().then(res => {
            if (res.code == 1) // trong phòng
                this.props.history.push('/question-fight/room?id=' + res.roomId);
            else if (res.code == 2) //đang fight
                this.props.history.push('/question-fight/fight');
            else this.getListRoom()
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    getListRoom = () => {
        getListRoomApi(this.page).then(res => {
            if (res.code == 1) {
                this.setState({
                    listRoom: this.page == 1 ? res.listRoom : [...this.state.listRoom, ...res.listRoom],
                    endListRoom: res.listRoom.length < 20,
                    isLoadingListRoom: false
                });
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    changeBetType = e => {
        let listBet = LIST_BET_TYPE_3;
        const betTypeSelect = e.target.value;
        if (betTypeSelect == 2) {
            listBet = LIST_BET_TYPE_2;
        }
        else if (betTypeSelect == 1) {
            listBet = LIST_BET_TYPE_1;
        }
        this.setState({
            betTypeSelect,
            betValue: listBet[0].value
        });
    }

    // changeBetValue = e =>{
    //     let betValue = e.target.value;
    //     if(this.state.betTypeSelect == 1) this.setState({betValue: betValue?.replace(/,|\./g, '')});
    //     else this.setState({betValue: formatDecimal(betValue?.replace('.', ''))});
    // }

    createRoom = () => {
        if (!this.state.isPress) {
            let idNotification = '#notificationCreateRoomFight';
            let { roomName, questionLevelSelect, betTypeSelect, betValue, maxPlayer, timeLimit, fightType } = this.state;
            if (betTypeSelect == 1) {
                if (betValue < 0) return showNotification('Số Gold[z] không được để trống', -1, idNotification);
                if (getDecimalCount(betValue) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            }
            else {
                betValue = replaceCost(betValue, false);
                if (betValue < 0) return showNotification('Số $[z] / đ[z] không được để trống', -1, idNotification);
            }
            if (questionLevelSelect === '') return showNotification('Bạn chưa chọn đấu trường', -1, idNotification);
            this.setState({ isPress: true });
            createRoomApi(roomName, questionLevelSelect, betTypeSelect, betValue, maxPlayer, timeLimit, fightType).then(res => {
                if (res.code == 1) {
                    let roomId = res.roomId;
                    setTimeout(() => this.props.history.push('/question-fight/room?id=' + roomId), 1500);
                    showNotification(res.message, res.code, idNotification);
                }
                else if (res.code == -99) {
                    this.setState({
                        showModal: false,
                        isPress: false
                    })
                    this.props.showModalBuyVip();
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ isPress: false });
            })
        }
    }

    joinLookRoom = roomId => {
        if (!this.state.isJoinRoom) {
            let idNotification = '#notificationRoomFightLobby' + roomId;
            this.setState({ isJoinRoom: roomId });
            joinLookRoomApi(roomId).then(res => {
                if (res.code == 1) {
                    setTimeout(() => this.props.history.push('/question-fight/room?id=' + roomId), 500);
                }
                else if (res.code == 2) {
                    setTimeout(() => this.props.history.push('/question-fight'), 500);
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                    this.setState({ isJoinRoom: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isJoinRoom: false });
            })
        }
    }

    loadMore = () => {
        this.page += 1;
        this.getListRoom();
    }

    getQuestionLevelSelect = () => {
        getQuestionLevelSelectApi().then(res => {
            if (res.code == 1) {
                this.setState({ listSelect: res.listSelect });
            }
        })
    }

    renderBetValue = () => {
        const { betTypeSelect, betValue } = this.state;
        if (betTypeSelect == 3) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_3.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        if (betTypeSelect == 2) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_2.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        if (betTypeSelect == 1) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_1.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        return null
    }

    onChangeQuestionLevel = e => {
        const value = e.target.value
        this.setState({
            questionLevelSelect: value,
        })
        if(this.state.questionLevelSelect == 100 && value != 100){
            this.changeBetType({target: {value: 3}})
        }
        else if(value == 100){
            this.setState({betValue: 0})
        }
    }

    componentDidMount() {
        this.checkIsInRoom();
        this.getQuestionLevelSelect();
    }

    render() {
        let { roomName, questionLevelSelect, betTypeSelect, maxPlayer, fightType, timeLimit, showModal, listRoom, endListRoom, isLoadingListRoom, isPress, isJoinRoom, listSelect } = this.state;
        if (listSelect.length == 0 || isLoadingListRoom) return <Loading />;
        return (
            <div>
                <HeaderLottie
                    source={fight}
                    disableLoop
                    size='40vh'
                />
                <div className='flex flex-column item-center mgt'>
                    {/* <p className='bold title-success mgb'>
                        {this.props.countMission.FightCount > 0 ?
                            `Chiến thắng ${this.props.countMission.FightCount} trận so tài bạn sẽ vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách so tài ngày hôm nay'
                        }
                    </p> */}
                    {/* <iframe
                        src="https://www.youtube.com/embed/T3iH8pNUpVc?start=443"
                        frameborder="0"
                        allow="encrypted-media"
                        allowFullScreen="allowFullScreen"
                        style={{width: '100%', height: '50vh', borderRadius: '2vh'}}
                    />
                    <p className='text-center'><i>Video thực tế vượt qua thử thách đấu trường</i></p> */}
                    <Button
                        className='bg-success mgt'
                        onClick={() => this.setState({ showModal: true })}
                    >
                        Tạo phòng
                    </Button>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endListRoom}
                    loader={<Loading key={0} small />}
                    threshold={10}
                >
                    {listRoom.map((item, key) =>
                        <Spin
                            spinning={item.QuestionFightRoomId === isJoinRoom}
                            tip="Đang vào phòng"
                            indicator={<Icon type="loading" style={{ fontSize: '3vh' }} spin />}
                            key={key}
                        >
                            <div
                                className='room-fight-list row opacity-hover'
                                onClick={() => this.joinLookRoom(item.QuestionFightRoomId)}
                            >
                                <div className='col-sm-6 col-xs-12 flex align-center'>
                                    <img
                                        src={item.FightType == 1 ? require('../../../assets/image/fight-solo.png') : require('../../../assets/image/fight-team.png')}
                                        className='room-fight-image'
                                    />
                                    <div>
                                        <p className='text-large'>{item.RoomName}</p>
                                        <p>Đấu: {item.Title || 'Khởi nghiệp hỗn hợp'}</p>
                                        <p>Cược: {item.BetName}</p>
                                        <p>Thời gian trả lời: {item.TimeLimit} giây</p>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-xs-12 flex align-center'>
                                    <TooltipUser userInfo={item} />
                                    <div className=' mgl-2'>
                                        <p className='text-large'>{item.FullName}</p>
                                        <p className='text-large'>({item.Player}/{item.MaxPlayer})</p>
                                    </div>
                                </div>
                                <div id={'notificationRoomFightLobby' + item.QuestionFightRoomId} />
                            </div>
                        </Spin>
                    )}
                </InfiniteScroll>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <TitleSuccess>Tạo phòng thách đấu</TitleSuccess>
                    <input
                        className='form-control mgt text-center'
                        placeholder='Nhập tên phòng'
                        value={roomName}
                        onChange={e => this.setState({ roomName: e.target.value })}
                    />
                    <select
                        className='form-control mgt'
                        onChange={this.onChangeQuestionLevel}
                        defaultValue={questionLevelSelect}
                    >
                        <option>Chọn đấu trường</option>
                        {listSelect.map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    {key == 1 &&
                                        <option
                                            value='0'
                                        >
                                            Khởi nghiệp hỗn hợp
                                        </option>
                                    }
                                    <option
                                        value={item.QuestionLevelId}
                                    >
                                        {item.Title}
                                    </option>
                                </React.Fragment>
                            )
                        })}
                    </select>
                    {questionLevelSelect != 100 &&
                        <>
                            <select
                                className='form-control mgt'
                                onChange={this.changeBetType}
                                defaultValue={betTypeSelect}
                            >
                                <option value='1'>Cược Gold[z]</option>
                                <option value='2'>Cược $[z]</option>
                                <option value='3'>Cược đ[z]</option>
                            </select>
                            {this.renderBetValue()}
                        </>
                    }
                    {/* <input
                        value={betValue}
                        onChange={this.changeBetValue}
                        className='form-control text-center mgt'
                        placeholder='Mức cược'
                    /> */}
                    <select
                        className='form-control mgt'
                        onChange={e => this.setState({ maxPlayer: e.target.value })}
                        defaultValue={maxPlayer}
                    >
                        {new Array(7).fill(undefined).map((item, key) =>
                            <option value={key + 2} key={key}>Tối đa {key + 2} người</option>
                        )}
                    </select>
                    <select
                        className='form-control mgt'
                        onChange={e => this.setState({ timeLimit: e.target.value })}
                        defaultValue={timeLimit}
                    >
                        <option value='5'>Thời gian trả lời: 5 giây</option>
                        <option value='10'>Thời gian trả lời: 10 giây</option>
                        <option value='15'>Thời gian trả lời: 15 giây</option>
                        <option value='20'>Thời gian trả lời: 20 giây</option>
                        <option value='30'>Thời gian trả lời: 30 giây</option>
                        <option value='150'>Thời gian trả lời: 150 giây</option>
                        <option value='300'>Thời gian trả lời: 300 giây</option>
                    </select>
                    <div className='row mgt'>
                        <div
                            className={'col-xs-6 box-primary item-center opacity-hover pointer' + (fightType == 1 && ' bg-success')}
                            onClick={() => fightType == 2 && this.setState({ fightType: 1 })}
                        >
                            <img
                                src={require('../../../assets/image/fight-solo.png')}
                                className='fight-type-img'
                            />
                            <p>Đấu tự do</p>
                        </div>
                        <div
                            className={'col-xs-6 box-primary item-center opacity-hover pointer' + (fightType == 2 && ' bg-success')}
                            onClick={() => fightType == 1 && this.setState({ fightType: 2 })}
                        >
                            <img
                                src={require('../../../assets/image/fight-team.png')}
                                className='fight-type-img'
                            />
                            <p>Đấu đội</p>
                        </div>
                    </div>
                    <div className='mgt' id='notificationCreateRoomFight' />
                    <div className='item-center'>
                        <Button
                            className='bg-success mgt'
                            onClick={this.createRoom}
                            loading={isPress}
                        >
                            Xác nhận
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { categoryQuestion } = state.getCategory;
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { categoryQuestion, dataUser, countMission };
}

export default connect(mapStateToProps, { getCategory, showModalBuyVip })(withRouter(Lobby));