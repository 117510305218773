import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import { MESSAGE, URI_PATH, PROP, TICKET } from '../../utils/constants';
import {formatDecimal, getDecimalCount, replaceCost} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import HeaderLottie from '../../components/HeaderLottie';
import giftoffer from '../../assets/lottie/giftoffer.json';
import {Button, Modal} from 'antd';
import {updateCountMission} from '../../actions/menu';
import {getUserItemApi} from '../../api/home';
import {sendApi, getListNewfeedOfferApi} from '../../api/gift/offer';
import SearchUser from '../../components/SearchUser';
import ListGiftOffer from '../../components/gift/ListGiftOffer';
import Icon from 'react-fontawesome';
import {appendListNewfeed, clearNewfeedData} from '../../actions/newfeed';
import InfiniteScroll from 'react-infinite-scroller';
import Comment from '../../components/newfeed/comment';
import ListComponent from '../../components/newfeed/item';
import empty from '../../assets/lottie/empty.json';
import BarSelect from '../../components/frame/BarSelect';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';
const ButtonGroup = Button.Group;

class GiftOffer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            listItemFood: [],
            listItemAsset: [],
            loadingItemFood: true,
            loadingItemAsset: true,
            selectItem: '',
            selectItemOffer: [],
            selectPhone: [],
            inputMoney: '',
            selectMoney: 3,
            message: '',
            isPress: false,
            showModal: false,
            selectType: 'gift',
            isLoadingHistory: true
        }
        this.listFoodSelect = [];
        this.listAssetSelect = [];
        this.pageId = 1;
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.state.selectItem) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    getUserItem = (selectItem) =>{
        let{loadingItemFood, loadingItemAsset} = this.state;
        if(
            (selectItem == 0 && loadingItemFood) ||
            (selectItem == 1  && loadingItemAsset)
        ){
            getUserItemApi(selectItem).then(res =>{
                if(res.code == 1){
                    if(selectItem == 0){
                        this.setState({
                            listItemFood: res.listItem,
                            loadingItemFood: false,
                            selectItem: selectItem,
                        })
                    }
                    else{
                        this.setState({
                            listItemAsset: res.listItem,
                            loadingItemAsset: false,
                            selectItem: selectItem
                        })
                    }
                }
                else showNotification(res.message, res.code)
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0)
            })
        }
        else this.setState({selectItem})
    }

    renderListItem = () =>{
        let {selectItem, listItemFood, listItemAsset, loadingItemFood, loadingItemAsset} = this.state;
        if(
            selectItem == 0 && loadingItemFood == true ||
            selectItem == 1 && loadingItemAsset == true
        ) return (<Loading small />);
        if(selectItem == 0){
            return(
                <div className="row mgt">
                    {listItemFood.map((item, key) =>
                        <div
                            className={'col-md-3 col-sm-4 col-xs-6 item-block item-center pointer ' + (this.listFoodSelect.includes(item.FoodId) && 'bg-success')}
                            key={key}
                            onClick={() => this.pickItem(item)}
                        >
                            <img className='item-img mgb' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`}/>
                            <p>{item.FoodName}</p>
                        </div>
                    )}
                </div>
            )
        }
        else if(selectItem == 1){
            return(
                <div className="row mgt">
                    {listItemAsset.map((item, key) =>
                        <div
                            className={'col-md-3 col-sm-4 col-xs-6 item-block item-center pointer ' + (this.listAssetSelect.includes(item.AssetId) && 'bg-success')}
                            key={key}
                            onClick={() => this.pickItem(item)}
                        >
                            <img className='item-img mgb' alt={item.AssetName} src={`${URI_PATH}${item.AssetImage}`}/>
                            <p>{item.AssetName}</p>
                        </div>
                    )}
                </div>
            )
        }
    }

    chooseItem = () =>{
        this.getUserItem(0);
        this.setState({showModal: true});
    }

    deleteSearch = e =>{
        if(e.key == 'Backspace' && this.state.selectPhone.length > 0){
            this.refs.searchUser.getInstance().clear();
            this.setState({selectPhone: []})
        }
    }

    pickItem = (item) =>{
        let {selectItemOffer} = this.state;
        if(item.AssetId){
            if(this.listAssetSelect.includes(item.AssetId)){ //remove
                this.listAssetSelect = this.listAssetSelect.filter(el => el !== item.AssetId);
                this.setState({
                    selectItemOffer: selectItemOffer.filter(el => {
                        return el.AssetId !== item.AssetId;
                    })
                })
            }
            else { //add
                if(this.checkPickItem()){
                    this.listAssetSelect.push(item.AssetId);
                    this.setState({
                        selectItemOffer: [...selectItemOffer, item],
                    });
                }
            }
        }
        else{
            if(this.listFoodSelect.includes(item.FoodId)){ //remove
                this.listFoodSelect = this.listFoodSelect.filter(el => el !== item.FoodId);
                this.setState({
                    selectItemOffer: selectItemOffer.filter(el => {
                        return el.FoodId !== item.FoodId;
                    })
                });
            }
            else{ //add
                if(this.checkPickItem()){
                    this.listFoodSelect.push(item.FoodId);
                    this.setState({
                        selectItemOffer: [...selectItemOffer, item]
                    });
                }
            }
        }
    }

    checkPickItem = () =>{
        if((this.listAssetSelect.length + this.listFoodSelect.length) < 4){
            return true;
        }
        else {
            showNotification('Bạn chỉ thể chọn tối đa 4 món', 0);
            return false
        }
    }

    changeMoneyType = e =>{
        this.setState({inputMoney: '', selectMoney: e.target.value});
    }

    changeInputMoney = e =>{
        let money = e.target.value;
        if(this.state.selectMoney == 1) this.setState({inputMoney: money.replace(/,|\./g, '')});
        else this.setState({inputMoney: formatDecimal(money?.replace('.', ''))});
    }

    sendGift = () =>{
        if(!this.state.isPress){
            let idNotification = '#notificationGiftOffer';
            let {inputMoney, selectPhone, selectMoney, message} = this.state;
            if(selectPhone.length == 0) return showNotification('Vui lòng chọn số điện thoại cần chuyển', -1, idNotification);
            if(message == '' && message.length < 150) return showNotification('Lời nhắn không được để trống và nhỏ hơn 150 kí tự', -1, idNotification);
            if(selectMoney == 1){
                if(inputMoney == '' || inputMoney <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', -1, idNotification);
                if(getDecimalCount(inputMoney) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            }
            else{
                inputMoney = replaceCost(inputMoney, false);
                if(inputMoney == '' || inputMoney < 1) return showNotification('Số '+ (selectMoney == 2 ? '$[z]' : 'đ[z]') +' phải lớn hơn 0 và không được để trống', -1, idNotification);
            }
            //
            let userId = selectPhone[0].userId;
            this.setState({isPress: true});
            sendApi(userId, message, selectMoney, inputMoney, this.listAssetSelect, this.listFoodSelect).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code, idNotification);
                    this.props.updateCountMission('OfferCount');
                    if(selectMoney == 1){
                        showNotificationBonus(inputMoney, -1, 1);
                        showNotificationBonus(res.costSend, -1, 2);
                        this.props.changePoint([-inputMoney, -res.costSend]);
                    }
                    else if(selectMoney == 2){
                        showNotificationBonus(inputMoney + res.costSend, -1, 2);
                        this.props.changePoint([null, -(inputMoney + res.costSend)]);
                    }
                    else{
                        showNotificationBonus(inputMoney, -1, 3);
                        showNotificationBonus(res.costSend, -1, 2);
                        this.props.changePoint([null, -res.costSend, -inputMoney]);
                    }
                    this.listFoodSelect = [];
                    this.listAssetSelect = [];
                    this.setState({
                        isPress: false,
                        inputMoney: '',
                        message: '',
                        selectItemOffer: []
                    });
                }
                else if (res.code == -99) {
                    this.setState({showModal: false})
                    this.props.showModalBuyVip();
                }
                else{
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            })
        }
    }

    getGiftHistory = () =>{
        getListNewfeedOfferApi(this.pageId, this.props.dataUser.UserId).then(res =>{
            if(res.code == 1){
                this.setState({
                    isLoadingHistory: false,
                    endList: res.listResult.length < 10
                })
                let listPost = this.pageId == 1 ? res.listResult : [...this.props.listNewfeed, ...res.listResult]
                this.props.appendListNewfeed(listPost);
            }
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    selectTypeGift = (selectType) =>{
        if(selectType != this.state.selectType){
            this.setState({selectType})
            if(selectType == 'history'){
                this.pageId = 1;
                this.getGiftHistory();
            }
        }
    }

    loadMore = () =>{
        this.pageId += 1;
        this.getGiftHistory();
    }


    buttonGroupClassMain = (selectItem) => {
        if (selectItem == this.state.selectType) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    componentWillUnmount(){
        this.props.clearNewfeedData();
    }

    render(){
        let {showModal, selectItemOffer, message, selectMoney, inputMoney, isPress, selectType, isLoadingHistory, endList} = this.state;
        let {listNewfeed} = this.props;
        return(
            <div>
                <HeaderLottie source={giftoffer}/>
                <div className="row item-center">
                    <ButtonGroup className='item-center flex mgb' style={{ width: '50%' }}>
                        <Button className={this.buttonGroupClassMain('gift')} onClick={() => this.selectTypeGift('gift')}>Tặng quà</Button>
                        <Button className={this.buttonGroupClassMain('history')} onClick={() => this.selectTypeGift('history')}>Lịch sử</Button>
                    </ButtonGroup>
                </div>
                {selectType == 'gift' ?
                    <>
                        <ListGiftOffer />
                        <div className='flex item-center'>
                            <p className='bold title-success mgb'>
                                {this.props.countMission.OfferCount > 0 ?
                                    'Tặng quà 1 người bạn sẽ vượt qua thử thách'
                                :
                                    'Bạn đã hoàn thành thử thách tặng quà ngày hôm nay'
                                }
                            </p>
                        </div>
                        <div
                            className='box-gift-spin mgt pointer'
                            onClick={this.chooseItem}
                        >
                            {selectItemOffer.length > 0 ?
                                <div className='row item-center'>
                                    {selectItemOffer.map((item, key) =>(
                                        <div key={key} className='col-md-3 col-sm-3 col-xs-6 item-center'>
                                            <img className='item-img mgt-3' alt='Quà tặng' src={`${URI_PATH}${item.FoodImage ? item.FoodImage : item.AssetImage}`}/>
                                            <p>1</p>
                                            <p>{item.FoodName ? item.FoodName : item.AssetName}</p>
                                        </div>
                                    ))}
                                </div>
                            :
                                <p className='mgt-7 text-large text-center silver'>Chọn món quà muốn tặng</p>
                            }
                        </div>
                        <div className='row item-center'>
                            <div className='col-md-5 col-sm-8 col-xs-12'>
                                <SearchUser
                                    ref='searchUser'
                                    onChange={selectPhone => this.setState({selectPhone})}
                                    onKeyDown={this.deleteSearch}
                                    className='mgt'
                                    placeholder='Họ tên / Số điện thoại'
                                />
                                <input
                                    className='form-control text-center mgt'
                                    placeholder='Lời nhắn gửi'
                                    value={message}
                                    onChange={e => this.setState({message: e.target.value})}
                                />
                                <select
                                    className='form-control mgt'
                                    defaultValue={selectMoney}
                                    onChange={this.changeMoneyType}
                                >
                                    <option value='1'>Tặng Gold[z]</option>
                                    <option value='2'>Tặng $[z]</option>
                                    <option value='3'>Tặng đ[z]</option>
                                </select>
                                <input
                                    className='form-control text-center mgt'
                                    placeholder='Nhập số tiền'
                                    value={inputMoney}
                                    onChange={this.changeInputMoney}
                                />
                            </div>
                        </div>
                        <div className='text-center mgt mgb-6'>
                            <div className='mgt' id='notificationGiftOffer'></div>
                            <Button
                                className='bg-success'
                                onClick={this.sendGift}
                                loading={isPress}
                            >
                                Tặng quà
                            </Button>
                            <p className='mgt'>Phí vận chuyển: 5.000 $[z]</p>
                        </div>
                        <Modal
                            {...PROP.MODAL}
                            visible={showModal}
                            onCancel={() => this.setState({showModal: false})}
                            centered
                        >
                            <ButtonGroup className='item-center mgt-2' style={{display: 'flex', width: '100%'}}>
                                <Button className={this.buttonGroupClass(0)} onClick={() => this.getUserItem(0)}>Nhà bếp</Button>
                                <Button className={this.buttonGroupClass(1)} onClick={() => this.getUserItem(1)}>Nhà kho</Button>
                            </ButtonGroup>
                            {this.renderListItem()}
                        </Modal>
                    </>
                :
                    <>
                        {isLoadingHistory ?
                            <Loading />
                        :
                            <>
                                {listNewfeed.length == 0 ?
                                    <div className='item-center'>
                                        <HeaderLottie source={empty} disableLoop />
                                        <p className='text-large text-center mgt'>Không có lịch sử tặng quà gần đây</p>
                                    </div>
                                :
                                    <>
                                        <InfiniteScroll
                                            loadMore={this.loadMore}
                                            hasMore={!endList}
                                            loader={<Loading key={0} small/>}
                                            threshold={20}
                                        >
                                            {listNewfeed.map((item, key) =>
                                                <div className='box-primary mgb' key={key}>
                                                    <ListComponent.GiftOffer data={item} />
                                                    <Comment data={item} />
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    const { listNewfeed } = state.getListNewfeed;
    return { dataUser, countMission, listNewfeed };
}

export default connect(mapStateToProps, {changePoint, updateCountMission, appendListNewfeed, clearNewfeedData, showModalBuyVip})(GiftOffer);