import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import { showNotification } from '../../utils/notification';
import { validatePhoneNumber, validateEmail, isTouch } from '../../utils/common';
import { checkExistApi, addUserQueueApi, verifyEmailApi, verifyPhoneApi, doneRegisterApi, sendEmailAuthenApi, getInfoUserAffApi } from '../../api/authentication/register';
import { login } from '../../api/authentication';
import { MESSAGE, KEY } from '../../utils/constants';
import Icon from 'react-fontawesome';
import firebase from '../../utils/firebase';
import Cookies from 'js-cookie';
import ModalForgotPass from '../../components/lobby/ModalForgotPass';
import queryString from 'query-string';
import Logo from '../../components/lobby/Logo';
import { EventBusName, onPushEventBus } from '../../services/event-bus';
import Axios from 'axios';
import moment from 'moment';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
const axios = require('axios');
const ButtonGroup = Button.Group;

class Authencation extends Component {
    constructor(props) {
        super(props);
        let userAff = queryString.parse(this.props.location.search).affiliate;
        this.userAff = userAff > 0 ? userAff : ''; // check user affiliate url
        this.state = {
            selectType: this.userAff > 0 ? 1 : 0,
            phoneNumber: '',
            userPass: '',
            secretCode: '',
            dayBirth: 7,
            monthBirth: 6,
            yearBirth: 1998,
            nameReg: '',
            phoneReg: '',
            emailReg: '',
            passReg: '',
            rePassReg: '',
            userqueueId: '',
            isVerifyPhone: false,
            isVerifyEmail: false,
            tokenEmail: '',
            tokenPhone: '',
            confirmResult: null,
            step: 1,
            isPressRegister: false,
            showModal: false,
            userAffFullName: ''
        }
        this.recaptchaVerifier = '';
        this.isShowCaptcha = false;
        this.isVerifyCaptcha = false;
    }

    buttonGroupClass = (selectType) => {
        if (selectType === this.state.selectType) return 'button-group-authen bg-success';
        return 'button-group-authen';
    }

    changeTypeAuthen = (type) => {
        if (type !== this.state.selectType) this.setState({ selectType: type })
    }

    renderLogin = () => (
        <div className="row mgt item-center" style={{ flexDirection: 'column' }}>
            <input
                className='form-control input-authen'
                placeholder='Số điện thoại'
                value={this.state.phoneNumber}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
                onKeyDown={e => e.key === 'Enter' && this.loginAction()}
                ref='inputLogin'
            />
            <input
                className='form-control input-authen'
                placeholder='Mật khẩu'
                type='password'
                value={this.state.userPass}
                onChange={e => this.setState({ userPass: e.target.value })}
                onKeyDown={e => e.key === 'Enter' && this.loginAction()}
            />
            <input
                className='form-control input-authen'
                placeholder='Mã bảo mật Authenticator (nếu bật)'
                value={this.state.secretCode}
                onChange={e => this.setState({ secretCode: e.target.value })}
                onKeyDown={e => e.key === 'Enter' && this.loginAction()}
            />
            <Button
                className='input-authen bg-success'
                onClick={this.loginAction}
                loading={this.state.isPress}
            >
                Đăng nhập
            </Button>
            <div id='notificationLogin' />
            <p
                className='text-center pointer mgt-2'
                onClick={() => this.setState({ showModal: true })}
            >
                <u>Quên mật khẩu?</u>
            </p>
            <p
                className='text-center pointer mgt'
                onClick={() => this.props.history.push('/lobby/privacy')}
            >
                Điều khoản sử dụng
            </p>
            <ModalForgotPass
                showModal={this.state.showModal}
                onHideModal={() => this.setState({ showModal: false })}
            />
        </div>
    )

    loginAction = () => {
        if (!this.state.isPress) {
            let { phoneNumber, userPass, secretCode } = this.state;
            if (phoneNumber == '' || userPass == '') showNotification('Số điện thoại / mật khẩu không được để trống!', -1, '#notificationLogin');
            else {
                this.setState({ isPress: true });
                login(phoneNumber, userPass, secretCode).then(res => {
                    if (res.code == 1) {
                        Cookies.set(KEY.TOKEN, res.token, { expires: 365 });
                        Axios.defaults.headers.common['Auth'] = res.token;
                        showNotification('Đăng nhập thành công', 1, '#notificationLogin');
                        setTimeout(() =>
                            onPushEventBus(EventBusName.LOGIN_SUCCESS)
                        , 2000);
                    }
                    else {
                        setTimeout(() => {
                            showNotification(res.message, -1, '#notificationLogin');
                            this.setState({ isPress: false });
                        }, 1000);
                    }
                }).catch(e => {
                    showNotification(MESSAGE.ERROR, -1, '#notificationLogin');
                    this.setState({ isPress: false });
                });
            }
        }
    }

    renderRegister = () => {
        switch (this.state.step) {
            case 1:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <input
                            className='form-control input-authen'
                            placeholder='Họ và tên của bạn'
                            onChange={e => this.setState({ nameReg: e.target.value })}
                            value={this.state.nameReg}
                        />
                        {this.state.userAffFullName &&
                            <div>
                                <p>Nguời giới thiệu:</p>
                                <input
                                    className='form-control input-authen'
                                    placeholder='Họ và tên của bạn'
                                    disabled
                                    value={this.state.userAffFullName}
                                />
                            </div>
                        }
                        <p>Ngày sinh:</p>
                        <div className='row' style={{ flexDirection: 'row' }}>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control input-authen'
                                    style={{ width: '99%' }}
                                    onChange={e => this.setState({ dayBirth: e.target.value })}
                                    defaultValue={this.state.dayBirth}
                                >
                                    {new Array(31).fill(undefined).map((item, key) => (
                                        <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control input-authen'
                                    style={{ width: '99%' }}
                                    onChange={e => this.setState({ monthBirth: e.target.value })}
                                    defaultValue={this.state.monthBirth}
                                >
                                    {new Array(12).fill(undefined).map((item, key) => (
                                        <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-xs-4'>
                                <select
                                    className='form-control input-authen'
                                    style={{ width: '99%' }}
                                    onChange={e => this.setState({ yearBirth: e.target.value })}
                                    defaultValue={this.state.yearBirth}
                                >
                                    {new Array(80).fill(undefined).map((_, key) => {
                                        const yearRegister = moment().year() - 6 - key;
                                        return(
                                            <option value={yearRegister} key={yearRegister}>Năm {yearRegister}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <Button className='input-authen' type="primary" onClick={this.nextStep}>Tiếp theo</Button>
                        <div id='notificationRegStep1'></div>
                    </div>
                )
            case 2:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <input
                            className='form-control input-authen'
                            placeholder='Số điện thoại (dùng để đăng nhập)'
                            value={this.state.phoneReg}
                            onChange={e => this.setState({ phoneReg: e.target.value })}
                        />
                        <input
                            className='form-control input-authen'
                            placeholder='Địa chỉ email (dùng để lấy lại mật khẩu)'
                            value={this.state.emailReg}
                            onChange={e => this.setState({ emailReg: e.target.value })}
                        />
                        <Button
                            className='input-authen'
                            type="primary"
                            onClick={this.nextStep}
                            loading={this.state.isPressRegister}
                        >Tiếp theo</Button>
                        <p className='text-center back-reg mgt' onClick={this.actionPrev}>
                            <Icon name='arrow-left' style={{ marginRight: '1vh' }}></Icon>Quay lại
                        </p>
                        <div id='notificationRegStep2'></div>
                    </div>
                )
            case 3:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <input
                            className='form-control input-authen'
                            placeholder='Mật khẩu đăng nhập'
                            type='password'
                            value={this.state.passReg}
                            onChange={e => this.setState({ passReg: e.target.value })}
                        />
                        <input
                            className='form-control input-authen'
                            placeholder='Nhập lại mật khẩu'
                            type='password'
                            value={this.state.rePassReg}
                            onChange={e => this.setState({ rePassReg: e.target.value })}
                        />
                        <Button
                            className='input-authen'
                            type="primary"
                            onClick={this.nextStep}
                            loading={this.state.isPressRegister}
                        >Hoàn tất</Button>
                        <p className='text-center back-reg mgt' onClick={this.actionPrev}>
                            <Icon name='arrow-left' style={{ marginRight: '1vh' }}></Icon>Quay lại
                            </p>
                        <div id='notificationRegStep3'></div>
                    </div>
                )
            case 4:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <h2 className='text-center title-zcity mgt'>Xác minh chính chủ</h2>
                        {/* <div id="recaptcha-container" className='item-center' style={{display: 'flex'}}></div>
                            <div className='row item-center'>
                                <h3 className='text-center title-authen-reg'>{this.state.phoneReg}</h3>
                                <Button
                                    className='btn-authen-reg'
                                    type="primary" style={{marginLeft: '2vh'}}
                                    onClick={() => this.verify(1)}
                                >
                                    {this.state.isVerifyPhone === true ? <Icon size='2x' name='check' /> : 'Xác minh'}
                                </Button>
                            </div> */}
                        <div className='row item-center'>
                            <h3 className='text-center title-authen-reg'>{this.state.emailReg}</h3>
                            <Button
                                className='btn-authen-reg'
                                type="primary"
                                style={{ marginLeft: '2vh' }}
                                onClick={() => this.verify(2)}
                            >
                                {this.state.isVerifyEmail === true ? <Icon size='2x' name='check' /> : 'Xác minh'}
                            </Button>
                        </div>
                        <Button
                            className='input-authen mgt'
                            type="primary"
                            onClick={this.doneRegister}
                            loading={this.state.isPressRegister}
                        >
                            Hoàn tất
                            </Button>
                        <div id='notificationRegStep4'></div>
                    </div>
                )
            case 5:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <h2 className='text-center title-zcity mgt'>Mã xác thực đã được gửi về email của bạn!</h2>
                        <input
                            className='form-control input-authen'
                            placeholder='Mã số xác thực email'
                            value={this.state.tokenEmail}
                            onChange={e => this.setState({ tokenEmail: e.target.value })}
                        />
                        <Button
                            className='input-authen mgt'
                            type="primary"
                            loading={this.state.isPressRegister}
                            onClick={this.verifyEmail}
                        >
                            Xác minh
                            </Button>
                        <p className='text-center back-reg mgt' onClick={this.actionPrev}>
                            <Icon name='arrow-left' style={{ marginRight: '1vh' }}></Icon>Quay lại
                            </p>
                        <div id='notificationRegStep5'></div>
                    </div>
                )
            case 6:
                return (
                    <div className="row mgt" style={{ flexDirection: 'column' }}>
                        <h3 className='text-center title-zcity mgt'>Mã xác thực đã được gửi về số điện thoại {this.state.phoneReg}</h3>
                        <input
                            className='form-control input-authen'
                            placeholder='Mã số xác thực'
                            value={this.state.tokenPhone}
                            onChange={e => this.setState({ tokenPhone: e.target.value })}
                        />
                        <Button
                            className='input-authen mgt'
                            type="primary"
                            loading={this.state.isPressRegister}
                            onClick={this.verifyPhone}
                        >
                            Xác minh
                            </Button>
                        <p className='text-center back-reg mgt' onClick={this.actionPrev}>
                            <Icon name='arrow-left' style={{ marginRight: '1vh' }}></Icon>Quay lại
                            </p>
                        <div id='notificationRegStep6'></div>
                    </div>
                )
        }
    }

    nextStep = () => {
        let step = this.state.step;
        let { passReg, rePassReg, nameReg, emailReg, phoneReg, dayBirth, monthBirth, yearBirth } = this.state;
        switch (step) {
            case 1:
                nameReg = nameReg.trim();
                if (nameReg == '') return showNotification('Vui lòng nhập họ và tên', 0, '#notificationRegStep1');
                if (nameReg.length < 5) return showNotification('Họ và tên phải tối thiểu 6 kí tự', 0, '#notificationRegStep1');
                this.actionNext();
                break;
            case 2:
                phoneReg = phoneReg.trim();
                emailReg = emailReg.trim();
                if (!validatePhoneNumber(phoneReg)) return showNotification('Số điện thoại không đúng', 0, '#notificationRegStep2');
                if (!validateEmail(emailReg)) return showNotification('Địa chỉ email không đúng', 0, '#notificationRegStep2');
                if (this.state.isPressRegister === false) {
                    this.setState({ isPressRegister: true });
                    checkExistApi(phoneReg, emailReg).then(res => {
                        this.setState({ isPressRegister: false });
                        if (res.code == 1) this.actionNext();
                        else showNotification(res.message, res.code, '#notificationRegStep2');
                    }).catch(e => {
                        this.setState({ isPressRegister: false });
                        showNotification(MESSAGE.ERROR, -1, '#notificationRegStep2');
                    })
                }
                break;
            case 3:
                if (passReg < 6) return showNotification('Mật khẩu phải tối thiểu 6 kí tự', 0, '#notificationRegStep3');
                if (passReg != rePassReg) return showNotification('Mật khẩu không trùng', 0, '#notificationRegStep3');
                let birthDay = yearBirth + '-' + monthBirth + '-' + dayBirth;
                if (this.state.isPressRegister === false) {
                    addUserQueueApi(nameReg, phoneReg, emailReg, birthDay, passReg).then(res => {
                        if (res.code == 1) {
                            this.setState({
                                phoneReg: res.phoneNumber,
                                emailReg: res.email,
                                userqueueId: res.userqueueId,
                                isPressRegister: false
                            });
                            this.actionNext();
                        }
                        else {
                            this.setState({ isPressRegister: false });
                            showNotification(res.message, res.code, '#notificationRegStep3');
                        }
                    }).catch(e => {
                        this.setState({ isPressRegister: false });
                        showNotification(MESSAGE.ERROR, -1, '#notificationRegStep3');
                    })
                }
                break;
        }
    }

    actionNext = () => {
        this.setState({ step: this.state.step + 1 });
    }

    actionPrev = () => {
        if (this.state.isVerifyPhone === false) {
            this.isShowCaptcha = false;
            this.isVerifyCaptcha = false;
        }
        if (this.state.step > 1) {
            this.setState({ step: this.state.step - 1 });
        }
    }

    verify = (type) => {
        switch (type) {
            case 1:
                if (this.state.isPressRegister === false && this.state.isVerifyPhone === false) {
                    if (this.isVerifyCaptcha === true) {
                        var appVerifier = this.recaptchaVerifier;
                        firebase.auth().signInWithPhoneNumber(`+84${this.state.phoneReg.slice(1, 11)}`, appVerifier).then(confirmResult => {
                            this.setState({ confirmResult });
                            this.setState({ step: this.state.step + 2 });
                        }).catch(e => {
                            showNotification(MESSAGE.ERROR, -1, '#notificationRegStep4');
                        });
                    }
                    else showNotification('Vui lòng xác thực Captcha', -1, '#notificationRegStep4');
                }
                break;
            case 2:
                if (this.state.isPressRegister === false && this.state.isVerifyEmail === false) {
                    this.setState({ isPressRegister: true });
                    sendEmailAuthenApi(this.state.userqueueId).then(res => {
                        if (res.code == 1) this.actionNext();
                        else showNotification(res.message, res.code);
                        this.setState({ isPressRegister: false });
                    }).catch(e => {
                        showNotification(MESSAGE.ERROR, -1);
                        this.setState({ isPressRegister: false });
                    })
                }
                break;
        }
    }

    verifyEmail = () => {
        let tokenEmail = this.state.tokenEmail;
        if (tokenEmail <= 0 && tokenEmail == '') return showNotification('Vui lòng điền mã xác thực Email của bạn', -1, '#notificationRegStep5');
        if (this.state.isPressRegister === false) {
            this.setState({ isPressRegister: true });
            verifyEmailApi(this.state.tokenEmail, this.state.userqueueId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isVerifyEmail: true,
                        isPressRegister: false
                    });
                    this.actionPrev();
                }
                else {
                    showNotification(res.message, res.code, '#notificationRegStep5');
                    this.setState({ isPressRegister: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, '#notificationRegStep5');
                this.setState({ isPressRegister: false });
            })
        }
    }

    verifyPhone = () => {
        let { tokenPhone, confirmResult } = this.state;
        if (tokenPhone <= 0 && tokenPhone == '') return showNotification('Vui lòng điền mã xác thực gửi về số điện thoại của bạn', -1, '#notificationRegStep6');
        if (this.state.isPressRegister === false) {
            confirmResult.confirm(tokenPhone).then(res => {
                verifyPhoneApi(this.state.phoneReg, this.state.userqueueId).then(res => {
                    if (res.code == 1) {
                        this.setState({ isVerifyPhone: true, isPressRegister: false });
                        this.setState({ step: this.state.step - 2 });
                    }
                    else {
                        showNotification(res.message, res.code, '#notificationRegStep6');
                        this.setState({ isPressRegister: false });
                    }
                }).catch(e => {
                    showNotification('Có lỗi xảy ra trong quá trình thực hiện', -1);
                    this.setState({ isPressRegister: false });
                });
            })
                .catch(e => {
                    showNotification('Mã xác thực không đúng. Vui lòng thử lại', -1, '#notificationRegStep6');
                    this.setState({ isPressRegister: false })
                });
        }
    }

    doneRegister = () => {
        if (this.state.isVerifyEmail === false) return showNotification('Vui lòng hoàn tất các bước xác minh', -1, '#notificationRegStep4');
        if (this.state.isPressRegister === false) {
            this.setState({ isPressRegister: true });
            const userInvite = Cookies.get(KEY.AFF_INVITE);
            doneRegisterApi(this.state.userqueueId, this.userAff, userInvite).then(res => {
                if (res.code == 1) {
                    Cookies.set(KEY.TOKEN, res.token, { expires: 365 });
                    Axios.defaults.headers.common['Auth'] = res.token;
                    setTimeout(() =>
                        onPushEventBus(EventBusName.LOGIN_SUCCESS)
                    , 2000);
                }
                showNotification(res.message, res.code);
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1);
                this.setState({ isPressRegister: false });
            })
        }
    }

    getInfoUserAff = () => {
        getInfoUserAffApi(this.userAff).then(res => {
            if (res.code == 1) this.setState({ userAffFullName: res.fullName });
            else this.userAff = '';
        }).catch(e => this.userAff = '');
    }

    componentDidMount() {
        !this.userAff && !isTouch() && this.refs.inputLogin.focus();
        if (this.userAff > 0) this.getInfoUserAff();
        const {id} = this.props.match.params;
        if(+id > 0){
            Cookies.set(KEY.AFF_INVITE, id, { expires: 999999 });
        }
    }

    // componentDidUpdate(){
    //     if(this.state.selectType == 1 && this.state.step === 4 && this.isShowCaptcha === false){
    //         this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //             'size': 'normal',
    //             'callback': response => {
    //                 this.isVerifyCaptcha = true;
    //             },
    //             'expired-callback': response => {
    //                 this.isVerifyCaptcha = false;
    //             }
    //         });
    //         this.recaptchaVerifier.render().then(function(widgetId) {
    //             window.recaptchaWidgetId = widgetId;
    //         });
    //         this.isShowCaptcha = true
    //     }
    // }

    render() {
        return (
            <div>
                {/* <MessengerCustomerChat
                    pageId={KEY.FB_PAGE_ID.toString()}
                    appId={KEY.FB_APP_ID.toString()}
                    loggedInGreeting="Zcity xin chào! Chúng tôi có thể giúp gì cho bạn?"
                    loggedOutGreeting="Zcity xin chào! Chúng tôi có thể giúp gì cho bạn?"
                    themeColor="#555555"
                    language="vi_VN"
                    shouldShowDialog={true}
                /> */}
                {/* <Logo /> */}
                <div className='flex-column item-center mgt mgb-3'>
                    <p className='bold text-gigantic'>CHÀO MỪNG BẠN ĐẾN VỚI THÀNH PHỐ Z</p>
                    <p className='text-large mgt bold'>TỰ HỌC NGOẠI NGỮ - KHỞI NGHIỆP TOÀN CẦU</p>
                    <p className='text-large'>Bắt đầu hành trình ra thế giới</p>
                    <img className='mgt-2' src={require('../../assets/image/logo-z.png')} style={{width: '20vh'}} />
                </div>
                <div className="row mgt">
                    <ButtonGroup className='item-center' style={{ display: 'flex', width: '100%' }}>
                        <Button
                            className={this.buttonGroupClass(0)}
                            onClick={() => this.changeTypeAuthen(0)}
                        >
                            Đăng nhập
                            </Button>
                        <Button
                            className={this.buttonGroupClass(1)}
                            onClick={() => this.changeTypeAuthen(1)}
                        >
                            Đăng ký Miễn Phí
                            </Button>
                    </ButtonGroup>
                </div>
                {this.state.selectType === 0 ? this.renderLogin() : this.renderRegister()}
            </div>
        )
    }
}

export default withRouter(Authencation);