import React, {Component} from 'react';
import {connect} from 'react-redux';
import HeaderLottie from '../../components/HeaderLottie';
import member from '../../assets/lottie/member.json';
import {getListMemberApi, expelMemberApi, getListKickApi, voteKickApi, getListClaimApi, actionClaimApi} from '../../api/club/member';
import {showNotification} from '../../utils/notification';
import {MESSAGE, PROP} from '../../utils/constants';
import Loading from '../../components/Loading';
import {getAvatarFb} from '../../utils/common';
import {Button, Modal} from 'antd';
import {withRouter} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import Countdown, { zeroPad } from 'react-countdown-now';
import empty from '../../assets/lottie/empty.json';
import TooltipUser from '../../components/TooltipUser';

class ClubMember extends Component{
    constructor(props){
        super(props);
        this.state={
            listMember: [],
            isLoading: true,
            isPress: false,
            userExpelInfo: {},
            inputExpel: '',
            listKick: [],
            listClaim: [],
            endListClaim: false
        }
        this.pageClaim = 1;
    }

    componentDidMount(){
        this.getListMember();
    }

    getListMember = () =>{
        getListMemberApi().then(res =>{
            if(res.code == 1) this.setState({
                isLoading: false,
                listMember: res.listMember
            })
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    showModalExpel = userInfo =>{
        this.setState({
            showModal: true,
            modalType: 1,
            userExpelInfo: userInfo
        });
    }

    expelMember = () =>{
        let {isPress, userExpelInfo, inputExpel} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationExpelMember';
            expelMemberApi(userExpelInfo.UserId, inputExpel).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code);
                    this.setState({
                        showModal: false,
                        inputExpel: '',
                        isPress: false
                    });
                }
                else {
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    showModalVoteKick = () =>{
        this.setState({
            showModal: true,
            modalType: 2
        });
        getListKickApi().then(res =>{
            if(res.code == 1) this.setState({listKick: res.listKick});
            else showNotification(res.message, res.code);
        }).catch(e => showNotification(MESSAGE.ERROR, 0))
    }

    voteKick = (userId, voteStatus) =>{
        let {isPress, listKick} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationVoteKick' + userId;
            voteKickApi(userId, voteStatus).then(res =>{
                showNotification(res.message, res.code, idNotification);
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        listKick: listKick.map(el => {
                            if(el.UserId == userId) return Object.assign({}, el, {
                                ClubkickvoteId: 1,
                                Agree: voteStatus == 2 ? parseInt(el.Agree) + 1 : el.Agree,
                                Disagree: voteStatus == 1 ? parseInt(el.Disagree) + 1 : el.Disagree
                            })
                            return el;
                        })
                    })
                }
                else this.setState({isPress: false});
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    getListClaim = () =>{
        getListClaimApi(this.pageClaim).then(res =>{
            if(res.code == 1){
                this.setState({
                    listClaim: this.pageClaim == 1 ? res.listClaim : [...this.state.listClaim, ...res.listClaim],
                    endListClaim: res.listClaim.length == 0,
                })
            }
            else{
                this.setState({endListClaim: true})
                showNotification(res.message, res.code);
            }
        }).catch(e =>{
            this.setState({endListClaim: true});
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    showModalClaim = () =>{
        this.setState({
            showModal: true,
            modalType: 3
        });
        this.pageClaim = 1;
        this.getListClaim();
    }

    loadMoreListClaim = () =>{
        this.pageClaim += 1;
        this.getListClaim();
    }

    actionClaim = (userId, action) =>{
        let {isPress, listClaim} = this.state;
        if(!isPress){
            this.setState({isPress: true});
            let idNotification = '#notificationClaimClub' + userId;
            actionClaimApi(userId, action).then(res =>{
                if(res.code == 1){
                    showNotification(res.message, res.code);
                    this.setState({
                        isPress: false,
                        listClaim: listClaim.filter(el => el.UserId != userId)
                    });
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPress: false});
                }
            }).catch(e =>{
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    renderModal = () => {
        let {modalType, userExpelInfo, inputExpel, listKick, listClaim, endListClaim, isPress} = this.state;
        let dataUser = this.props.dataUser;
        if(modalType == 1){ // kick member
            return (
                <div className='item-center'>
                    <div className='flex-row item-center'>
                        <img
                            src={require('../../assets/image/quit.png')} 
                            className='club-info-image'
                        />
                        <img
                            src={getAvatarFb(userExpelInfo.FacebookId)} 
                            key={userExpelInfo.UserId}
                            className='avatar-guest mgl-2'
                        />
                    </div>
                    <p className='text-huge danger bold mgt-3 mgb-2'>Bạn muốn mở bỏ phiếu khai trừ thành viên {userExpelInfo.FullName} ra khỏi Club?</p>
                    <textarea
                        value={inputExpel}
                        onChange={e => this.setState({inputExpel: e.target.value})}
                        className='club-info-input-edit'
                        placeholder='Nhập lý do ...'
                    />
                    <div className='mgt' id='notificationExpelMember' />
                    <Button
                        className='bg-danger mgt'
                        loading={isPress}
                        onClick={this.expelMember}
                    >
                        Bỏ phiếu
                    </Button>
                </div>
            )
        }
        else if(modalType == 2){
            return(
                <div className='item-center'>
                    <p className='text-large mgb-2'>Danh sách bỏ phiếu khai trừ</p>
                    {listKick.length > 0 ?
                        <div>
                            {listKick.map((item, key) =>
                                <div key={key}>
                                    {item.UserId != dataUser.UserId &&
                                        <div className='flex-column club-list-container'>
                                            <div className='flex-row align-center'>
                                                <img
                                                    src={getAvatarFb(item.FacebookId)} 
                                                    className='avatar-guest'
                                                />
                                                <div className='flex-column'>
                                                    <p className='mgl-2 text-left'>{item.FullName}</p>
                                                    <p className='mgl-2 text-left'>Lý do: {item.Comment}</p>
                                                    <p className='mgl-2 text-left'>Tán thành: {item.Agree}</p>
                                                    <p className='mgl-2 text-left'>Không tán thành: {item.Disagree}</p>
                                                    <p className='mgl-2 text-left'>
                                                        Thời gian biểu quyết:
                                                        <Countdown 
                                                            date={item.TimeQueue.replace(/-/g, '/')}
                                                            renderer={({ days, hours, minutes, seconds}) =>(<span> {days} ngày {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <div id={'notificationVoteKick' + item.UserId} />
                                            {dataUser.ClubRoleId != 1 && !item.ClubkickvoteId &&
                                                <div className='mgt flex-row item-center'>
                                                    <Button
                                                        className='bg-success mg'
                                                        onClick={() => this.voteKick(item.UserId, 2)}
                                                    >
                                                        Tán thành
                                                    </Button>
                                                    <Button
                                                        className='bg-danger mg'
                                                        onClick={() => this.voteKick(item.UserId, 1)}
                                                    >
                                                        Không tán thành
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    :
                        <div>
                            <HeaderLottie source={empty} />
                            <p className='text-large mgt'>Không có phiếu khai trừ</p>
                        </div>
                    }
                </div>
            )
        }
        else if(modalType == 3){
            return(
                <div className='item-center'>
                    <p className='text-large mgb-2'>Danh sách xin vào Club</p>
                    {listClaim.length > 0 ?
                        <InfiniteScroll
                            loadMore={this.loadMoreListClaim}
                            hasMore={!endListClaim}
                            loader={<Loading key={0} small/>}
                            useWindow={false}
                            threshold={10}
                        >
                            {listClaim.map((item, key) =>
                                <div className='flex-column club-list-container' key={key}>
                                    <div className='flex-row align-center'>
                                        <img
                                            src={getAvatarFb(item.FacebookId)} 
                                            className='avatar-guest'
                                        />
                                        <div className='flex-column'>
                                            <p className='mgl-2 text-left'>{item.FullName}</p>
                                            <p className='mgl-2 text-left'>Năm sinh: {item.BirthDay}</p>
                                            <p className='mgl-2 text-left'>Thành tựu: {item.Achievement}</p>
                                            <p className='mgl-2 text-left'>Điểm mạnh: {item.Strengh}</p>
                                        </div>
                                    </div>
                                    <div id={'notificationClaimClub' + item.UserId} />
                                    <div className='mgt flex-row item-center'>
                                        <Button
                                            className='bg-success mg'
                                            onClick={() => this.actionClaim(item.UserId, 1)}
                                        >
                                            Đồng ý
                                        </Button>
                                        <Button
                                            className='bg-danger mg'
                                            onClick={() => this.actionClaim(item.UserId, 2)}
                                        >
                                            Từ chối
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    :
                        <div>
                            <HeaderLottie source={empty} />
                            <p className='text-large mgt'>Không có thành viên xin vào Club</p>
                        </div>
                    }
                </div>
            )
        }
    }

    render(){
        let {isLoading, listMember, showModal} = this.state;
        let dataUser = this.props.dataUser;
        if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={member}/>
                <div className='flex-row item-center'>
                    <Button
                        className='bg-danger mgr'
                        onClick={this.showModalVoteKick}
                    >
                        Bỏ phiếu khai trừ
                    </Button>
                    {['1', '2'].includes(dataUser.ClubRoleId) && 
                        <Button
                            className='bg-primary'
                            onClick={this.showModalClaim}
                        >
                            D/s xin vào Club
                        </Button>
                    }
                </div>
                {listMember.map((item, key) =>
                    <div key={key} className='club-list-container'>
                        <div className='flex-row item-center'>
                            <div className='flex-column item-center'>
								<TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                <div className='flex-row item-center mgt'>
                                    <p className='mgb-2 text-center text-large mgl'>{item.FullName}</p>
                                    {dataUser.ClubRoleId == 1 && item.ClubRoleId != 1 &&
                                        <img
                                            src={require('../../assets/image/quit.png')} 
                                            className='kick-member-img mgl'
                                            onClick={() => this.showModalExpel(item)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-sm-6 col-xs-12 flex-column'>
                                <p className='mgb-0 text-left'>Năm sinh: {item.BirthDay}</p>
                                <p className='mgb-0 text-left'>Số điện thoại: {item.PhoneNumber}</p>
                                <p className='mgb-0 text-left'>Điểm mạnh: {item.Strengh}</p>
                                <p className='mgb-0 text-left'>Thành tựu: {item.Achievement}</p>
                                <p className='mgb-0 text-left'>Quân hàm: {item.ClubRank}</p>
                                <p className='mgb-0 text-left'>Chức vụ: {item.ClubRoleName}</p>
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12 flex-column'>
                                <p className='mgb-0 text-left'>Cống hiến: {item.ContributeClub} đ[z]</p>
                                <p className='mgb-0 text-left'>Cống hiến hôm nay: {item.ContributeClubToday} đ[z]</p>
                                <p className='mgb-0 text-left'>Bài viết: {item.AllPost}</p>
                                <p className='mgb-0 text-left'>Bài viết hôm nay: {item.LastPost}</p>
                                <p className='mgb-0 text-left'>Video: {item.AllVideo}</p>
                                <p className='mgb-0 text-left'>Video hôm nay: {item.LastVideo}</p>
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    {...PROP.MODAL}
                    width='80vh'
                    visible={showModal}
                    onCancel={() => this.setState({showModal: false})}
                >
                    {this.renderModal()}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(ClubMember));