import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import Loading from '../../components/Loading';
import { likeApi, getLikeRemainApi, getListBidApi, getLikeTimeRemainApi } from '../../api/brand/top';
import { getListOnlineApi } from '../../api/brand/online';
import { Modal } from 'antd';
import { getAvatarFb, getDataNotify } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationAsset, showNotificationProperty } from '../../utils/notification';
import { MESSAGE, PROP } from '../../utils/constants';
import like from '../../assets/lottie/like.json';
import Lottie from 'react-lottie';
import TooltipUser from '../../components/brand/TooltipUser';
import Bid from '../../components/brand/Bid';
import { socket } from '../../components/realtime/ReadSocketEvent';
import { showModalEat } from '../../actions/home/modalEat';
import Empty from '../../components/Empty';
import { updateCountMission } from '../../actions/menu';
import Checked from '../../components/frame/Checked';

class BrandTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOnline: [],
            listFollow: [],
            listBid: [],
            isLoading: true,
            isPress: false,
            showModal: false,
            userInfo: {},
            likeRemain: 0,
            likeTimeRemain: 999999999,
            userIdRemain: '',
            allBalanceBid: 0
        }
        this.intervalGetList = '';
    }

    getListOnline = () => {
        getListOnlineApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listOnline: res.listOnline,
                    listFollow: res.listFollow,
                    isLoading: false,
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    getLikeRemain = () => {
        getLikeRemainApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    likeRemain: res.likeRemain
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    renderLikeRemain = () => {
        let ele = [];
        for (let i = 0; i < this.state.likeRemain; i++) {
            ele.push(
                <p className='text-large' key={i}>❤️</p>
            )
        }
        return (
            <div className='item-center'>
                <div className='flex-row item-center'>{ele}</div>
                <p className='text-large'>🏹</p>
            </div>
        )
    }

    renderListOnline = () => {
        let { isLoading, listOnline, likeTimeRemain, listFollow } = this.state;
        if (isLoading) return <Loading />;
        return (
            <>
                <div className='flex align-center mgt'>
                    <p className='mgb-0'>Đang theo dõi</p>
                    <img src={require('../../assets/image/follow-on.png')} className='mgl' style={{width: '2.5vh'}}/>
                </div>
                {listFollow.length > 0 ?
                    <div className='row mgt user-online-box'>
                        {listFollow.map((item) =>
                            <div className='col-md-3 col-sm-4 col-xs-6 user-online-block' key={item.UserId}>
                                <div style={{ position: 'relative' }}>
                                    <TooltipUser
                                        avatarClass='avatar-guest-large'
                                        userInfo={item}
                                        likeTimeRemain={likeTimeRemain}
                                        onLikeTimeRemain={this.getLikeTimeRemain}
                                        onLike={this.like}
                                    />
                                </div>
                                <p className='mgt'>{item.FullName}<Checked data={item} /></p>
                            </div>
                        )}
                    </div>
                    :
                    <Empty title='Danh sách theo dõi trống' />
                }
                <p className='mgb-0 mgt'>Online</p>
                {listOnline.length > 0 ?
                    <div className='row mgt user-online-box'>
                        {listOnline.map((item) =>
                            <div className='col-md-3 col-sm-4 col-xs-6 user-online-block' key={item.UserId}>
                                <div style={{ position: 'relative' }}>
                                    <TooltipUser
                                        avatarClass='avatar-guest-large'
                                        userInfo={item}
                                        likeTimeRemain={likeTimeRemain}
                                        onLikeTimeRemain={this.getLikeTimeRemain}
                                        onLike={this.like}
                                    />
                                </div>
                                <p className='mgt'>{item.FullName}<Checked data={item} /></p>
                            </div>
                        )}
                    </div>
                    :
                    <Empty title='Không có thành viên nào Online' />
                }
            </>
        )
    }

    getLikeTimeRemain = (userId) => {
        if (this.state.userIdRemain != userId) {
            getLikeTimeRemainApi(userId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        userIdRemain: userId,
                        likeTimeRemain: res.likeTimeRemain
                    });
                }
                else showNotification(MESSAGE.ERROR, 0);
            }).catch(e => showNotification(MESSAGE.ERROR, 0))
        }
    }

    like = userInfo => {
        let { isPress, listOnline, listBid, likeRemain, listFollow } = this.state;
        if (!isPress) {
            let idNotification = '#notificationLike' + userInfo.UserId;
            this.setState({ isPress: true });
            likeApi(userInfo.UserId).then(res => {
                if (res.code == 1) {
                    userInfo.BirthDay = res.birthDay;
                    this.setState({
                        isPress: false,
                        userInfo: userInfo,
                        showModal: true,
                        likeRemain: likeRemain - 1,
                        listOnline: listOnline.map(el => {
                            if (el.UserId == userInfo.UserId) {
                                return {
                                    ...el,
                                    IsLiked: 2
                                }
                            }
                            return el;
                        }),
                        listBid: listBid.map(el => {
                            if (el.UserId == userInfo.UserId) {
                                return {
                                    ...el,
                                    IsLiked: 2
                                }
                            }
                            return el;
                        }),
                        listFollow: listFollow.map(el => {
                            if (el.UserId == userInfo.UserId) {
                                return {
                                    ...el,
                                    IsLiked: 2
                                }
                            }
                            return el;
                        }),
                    });
                    let bonus = res.bonus;
                    this.props.changePoint([null, null, bonus.BalanceZ, bonus.AchievementPr]);
                    this.props.updateCountMission('LikeCount');
                    showNotificationBonus(bonus.BalanceZ, 1, 3);
                    showNotificationBonus(bonus.AchievementPr, 1, 4);
                    showNotificationProperty(res.property);
                    showNotificationAsset(res.assetInfo);
                    //socket
                    let dataUser = this.props.dataUser;
                    let datas = getDataNotify(userInfo.UserId, 0, 0, 0, bonus.AchievementPa, bonus.LikeUser, res.propertyPa);
                    datas.message = {
                        fullName: dataUser.FullName,
                        facebookId: dataUser.FacebookId,
                        message: 'yêu thích thương hiệu của bạn',
                        isNotify: 2
                    };
                    socket.emit('send_notify', datas);
                    //
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                console.log(e)
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    getListBid = () => {
        getListBidApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listBid: res.listBid,
                    allBalanceBid: res.allBalanceBid
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch((e) => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    componentDidMount() {
        this.getLikeRemain();
        this.getListBid();
        this.getListOnline();
        this.intervalGetList = setInterval(() => {
            this.getListOnline();
        }, 20000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalGetList);
    }

    render() {
        let { listBid, showModal, userInfo, likeRemain, likeTimeRemain, allBalanceBid } = this.state;
        return (
            <div>
                <div className='flex-column item-center mgb'>
                    <p className='bold title-success'>
                        {likeRemain > 0 ?
                            `Yêu thích ${likeRemain} người bạn sẽ vượt qua thử thách`
                            :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                {likeRemain > 0 && this.renderLikeRemain()}
                <Bid
                    listBid={listBid}
                    likeTimeRemain={likeTimeRemain}
                    onLikeTimeRemain={this.getLikeTimeRemain}
                    onLike={this.like}
                    enableBid
                    allBalanceBid={allBalanceBid}
                />
                {this.renderListOnline()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    <div className='item-center'>
                        <img src={getAvatarFb(userInfo.FacebookId)} className='avatar-guest-huge' />
                        <p className='text-large mgt'>{userInfo.FullName}</p>
                        <p className='text-large mgt'>{userInfo.BirthDay}</p>
                        <Lottie
                            options={{ loop: true, autoplay: true, animationData: like }}
                            width='30vh'
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, showModalEat, updateCountMission })(BrandTop);