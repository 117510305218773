import React, { Component } from 'react';
import {LANGUAGE_NAME, MESSAGE} from '../../utils/constants';
import {connect} from 'react-redux';
import {getQuestionEventApi, saveAnswerApi, getListUserQuestionApi} from '../../api/question/event';
import { changePoint } from '../../actions/home/';
import {updateCountMission} from '../../actions/menu';
import {showNotification, showNotificationBonus, showNotificationAsset} from '../../utils/notification';
import Loading from '../../components/Loading';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import Countdown, { zeroPad } from 'react-countdown-now';
import InfiniteScroll from 'react-infinite-scroller';
import {showModalEat} from '../../actions/home/modalEat';
import { speakEng } from '../../utils/common';
import { Subscription } from "rxjs";
import EventBus, { EventBusName } from '../../services/event-bus';

class QuestionEvent extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            loadingFirstList: true,
            questionList: [],
            numberCorrect: 0,
            textTime: '--:--',
            selectAnswerKey: '',
            resultAnswer: false,
            isAnswer: false,
            listUserQuestion: [],
            endList: false
        };
        this.timeDiff = 99999;
        this.numberQuestion = 0;
        this.countDown = 99999;
        this.correctAnswer = '';
        this.resultColor = '';
        this.refQuestion = {};
        this.questionInterval = '';
        this.pageList = 1;
        this.subScription = new Subscription();
    }

    onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res) => {
            if (res.type === EventBusName.CHANGE_LANGUAGE) {
                clearInterval(this.questionInterval);
                this.getQuestionEvent();
                this.setState({selectAnswerKey: ''})
            }
        });
        this.subScription.add(sub);
    };

    getQuestionEvent = () =>{
        getQuestionEventApi().then(res =>{
            let {questionList, numberCorrect, timeRemainQuestion, numberQuestion, timeDiff, countDown} = res.data;
            this.numberQuestion = numberQuestion;
            this.timeDiff = timeDiff;
            this.countDown = countDown;
            this.setState({
                isLoading: false,
                questionList: questionList,
                numberCorrect: numberCorrect,
                timeRemainQuestion: timeRemainQuestion
            });
            this.questionInterval = setInterval(() =>{
                if(this.countDown > 0) this.countDown -= 1;
                else{
                    this.timeDiff -= 1;
                    if(this.timeDiff < 10) this.setState({textTime: '00:0'+this.timeDiff});
                    else this.setState({textTime: '00:'+this.timeDiff});
                    if(this.timeDiff <= 0){
                        this.timeDiff = 15;
                        this.numberQuestion += 1;
                        if(this.numberQuestion > 20){
                            this.setState({timeRemainQuestion: new Date().getTime() + 6900 * 1000});
                            this.timeDiff = 6900;
                            this.countDown = 6900;
                        }
                        this.correctAnswer = '';
                        this.setState({
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isAnswer: false,
                            textTime: '00:15'
                        });
                    }
                }
            }, 1000);
            getListUserQuestionApi().then(res =>{
                this.setState({
                    listUserQuestion: res.questionList,
                    loadingFirstList: false
                });
            })
        }).catch(e => showNotification(MESSAGE.ERROR, -1))
    }

    getVideoUrl = () =>{
        const dataUser = this.props.dataUser;
        let url = "https://www.youtube.com/embed/AbtmtAFdbWo?start=1641";
        if(dataUser.Language == 2){
            url = "https://www.youtube.com/embed/x9pixMubs8A"
        }
        else if(dataUser.Language == 3){
            url = "https://www.youtube.com/embed/2nLSy6BD4QA"
        }
        else if(dataUser.Language == 4){
            url = "https://www.youtube.com/embed/sLUg6LGlNek"
        }
        else if(dataUser.Language == 5){
            url = "https://www.youtube.com/embed/x8Awui4Xt38"
        }
        else if(dataUser.Language == 6){
            url = "https://www.youtube.com/embed/LTHW6D2pRGs"
        }
        else if(dataUser.Language == 7){
            url = "https://www.youtube.com/embed/VVPJHitk5wk"
        }
        else if(dataUser.Language == 8){
            url = "https://www.youtube.com/embed/TYnArG4ip3g"
        }
        else if(dataUser.Language == 9){
            url = "https://www.youtube.com/embed/ESYIT25klaw"
        }
        return url;
    }

    renderEvent = () =>{
        let {timeRemainQuestion, listUserQuestion, endList} = this.state;
        return(
            <div>
                <div className='flex item-center'>
                    <p className='bold title-success mgb'>
                        {this.props.countMission.GiftEventCount > 0 ?
                            `Trả lời đúng ${this.props.countMission.GiftEventCount} câu hỏi sự kiện bạn sẽ vượt qua thử thách`
                        :
                            'Bạn đã hoàn thành thử thách ngày hôm nay'
                        }
                    </p>
                </div>
                <div className='flex-column item-center'>
                    <p className='title-event-question mgb'>
                        Sự kiện trắc nghiệm sẽ bắt đầu sau                                 
                        <Countdown 
                            date={timeRemainQuestion * 1000}
                            renderer={({ days, hours, minutes, seconds}) =>(<span> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                            onComplete={this.getQuestionEvent}
                        />
                    </p>
                    <iframe
                        src={this.getVideoUrl()}
                        frameborder="0"
                        allow="encrypted-media"
                        allowFullScreen="allowFullScreen"
                        style={{ width: '100%', height: '50vh', borderRadius: '2vh' }}
                    />
                    <p className='text-center'><i>Hướng dẫn đánh vần {LANGUAGE_NAME[this.props.dataUser.Language]} dễ dàng bằng bảng phiên âm</i></p>
                    <p className='mgt-2'>Lịch sử trả lời câu hỏi</p>
                </div>
                <InfiniteScroll
                    loadMore={this.loadMoreList}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {listUserQuestion.map((item, key) =>(
                        <div className='container-list-question' key={key}>
                            <div className='flex-row'>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Mã câu hỏi</p>
                                    <p className='text-center'>{item.QuestionId}</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Thứ tự câu</p>
                                    <p className='text-center'>{item.QuestionNum}</p>
                                </div>
                                <div style={{flex: 1}}>
                                    <p className='text-center'>Kết quả</p>
                                    <p className='text-center'>{item.IsCorrect == 1 ? 'Đúng' : 'Sai'}</p>
                                </div>
                            </div>
                            <div className='flex-column item-center mgt-2'>
                                <p className={'bonus-question-event ' + (item.IsCorrect == 1 ? 'bg-success' : 'bg-danger')}>+ {item.BonusAchievement} thành tựu, {item.BonusLanguagePoint} ngoại ngữ</p>
                                <p className='text-time'>{item.AnswearTime}</p>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        )
    }

    saveAnswer = (answer, keySelect, questionId, languageId) =>{
        if(!this.state.isAnswer){
            this.setState({selectAnswerKey: keySelect, isAnswer: true});
            saveAnswerApi(questionId, this.numberQuestion, languageId, answer).then(res =>{
                this.setState({resultMessage: 'Cùng chờ xem đáp án ...', resultAnswer: 2});
                let timeResult = this.timeDiff * 1000 - 2000;
                if(res.code == 1){
                    setTimeout(() =>{
                        this.correctAnswer = res.answer;
                        setTimeout(() =>{
                            speakEng(res.answer)
                        }, 0)
                        this.setState({
                            resultAnswer: 1,
                            numberCorrect: this.state.numberCorrect + 1,
                            listUserQuestion: [
                                {
                                    QuestionId: res.questionId,
                                    AnswearTime: 'Vừa xong',
                                    BonusAchievement: res.bonus.Achievement,
                                    BonusLanguagePoint: languageId == 1 ? 1 : 0,
                                    IsCorrect: 1,
                                    QuestionNum: this.numberQuestion
                                },
                                ...this.state.listUserQuestion
                            ],
                        });
                        //assets bonus
						showNotificationAsset(res.assetInfo);
                        //
                        showNotificationBonus(res.bonus.BalanceZ, 1, 3);
                        showNotificationBonus(res.bonus.Achievement, 1, 4);
                        this.props.changePoint([null, null, res.bonus.BalanceZ, res.bonus.Achievement]);
                        this.props.updateCountMission('GiftEventCount');
                    }, timeResult);
                }
                else if(res.code == 0){
                    setTimeout(() =>{
                        this.correctAnswer = res.answer;
                        setTimeout(() =>{
                            speakEng(res.answer)
                        }, 0)
                        this.setState({
                            resultAnswer: 0,
                            listUserQuestion: [
                                {
                                    QuestionId: res.questionId,
                                    AnswearTime: 'Vừa xong',
                                    BonusAchievement: 0,
                                    BonusLanguagePoint: 0,
                                    IsCorrect: 0,
                                    QuestionNum: this.numberQuestion
                                },
                                ...this.state.listUserQuestion
                            ],
                        });
                    }, timeResult);
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, '#notificationQuestionEvent');
                    this.setState({resultAnswer: false, isAnswer: false, selectAnswerKey: ''});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, -1, '#notificationQuestionEvent');
            })
        }
    }

    loadMoreList = () => {
        let {endList, loadingFirstList} = this.state;
        if(!endList && !loadingFirstList){
            this.pageList += 1;
            getListUserQuestionApi(this.pageList).then(res =>{
                if(res.questionList.length > 0){
                    this.setState({
                        listUserQuestion: [...this.state.listUserQuestion, ...res.questionList],
                    });
                }
                else this.setState({endList: true})
            }).catch(error => showNotification(MESSAGE.ERROR, -1));
        }
    }

    componentDidMount(){
        this.getQuestionEvent();
        this.onRegisterEventBus();
    }

    componentWillUnmount(){
        clearInterval(this.questionInterval);
        if (this.subScription) this.subScription.unsubscribe();
    }

    render(){
        if(this.state.isLoading) return (<Loading />);
        let {questionList, selectAnswerKey, resultAnswer, textTime} = this.state;
        return(
            <div>
                <div className='item-center'>
                    <img
                        src={require('../../assets/image/q&a.gif')}
                        style={{width: '23vh', resizeMode: 'contain'}}
                    />
                </div>
                {this.countDown > 0 ?
                    this.renderEvent() 
                : 
                    <div className='container-answer-question-event'>
                        {questionList.map((item, index) =>(
                            <div key={index}>
                                {index + 1 == this.numberQuestion && (
                                    <div className='animated fadeIn fast'>
                                        <p className='title-question'>Câu hỏi {this.numberQuestion}: {item.QuestionTitle}</p>
                                        <div className='count-down-question-container'>
                                            <p className={'count-down-question ' + (this.timeDiff <= 10 ? 'danger' : 'success')}>{textTime}</p>
                                        </div>
                                        {item.answers.map((value, key) =>{
                                            return (
                                                <div
                                                    key={key}
                                                    onClick={() => this.saveAnswer(value, key, item.QuestionId, item.LanguageId)}
                                                >
                                                    <TextAnswer
                                                        answer={value}
                                                        keySelect={key}
                                                        selectAnswerKey={selectAnswerKey}
                                                        correctAnswer={this.correctAnswer}
                                                    />
                                                </div>
                                            )
                                        })}
                                        {resultAnswer !== false && (
                                            <ResultMessage resultAnswer={resultAnswer} />
                                        )}
                                        <div id='notificationQuestionEvent'></div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission } = state.getCountMission;
    return { dataUser, countMission };
}

export default connect(mapStateToProps, {changePoint, updateCountMission, showModalEat})(QuestionEvent);