import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MESSAGE, TICKET, PROP, BALANCE_TYPE, URI_PATH, LIST_BET_TYPE_3, LIST_BET_TYPE_2, LIST_BET_TYPE_1 } from '../../../utils/constants';
import { formatDecimal, replaceCost, getDecimalCount } from '../../../utils/common';
import { showNotification, showNotificationBonus, showNotificationFightMessage, showNotificationProperty } from '../../../utils/notification';
import Loading from '../../../components/Loading';
import { Button, Modal, Checkbox, Tooltip } from 'antd';
import { TitleSuccess, EmptyLottie } from '../../../components/frame';
import { getRoomInfoApi, leaveRoomApi, editRoomApi, readyApi, changeTeamApi, kickUserApi, getListInviteApi, sendInviteNotificationApi, toggleLookApi, getListGiftFightApi, sendGiftFightApi } from '../../../api/question/fight/room';
import { getCategory } from '../../../actions/category';
import TooltipUser from '../../../components/TooltipUser';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome';
import { ButtonActionFight, ReadyTitle } from '../../../components/question/fight';
import { socket } from '../../../components/realtime/ReadSocketEvent';
import { changePoint } from '../../../actions/home';
import SearchUser from '../../../components/SearchUser';
import { getQuestionLevelSelectApi } from '../../../api/question/fight/fight';
import ChatFight from '../../../components/question/fight/ChatFight';
import { showModalBuyVip } from '../../../actions/home/modalBuyVip';

class Room extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            showModal: false,
            modalType: 1,
            roomInfo: {},
            listPlayer: [],
            isLoading: true,
            isSendChat: false,
            isLoadingListOnline: true,
            listInvite: [],
            listUserSelectInvite: [],
            listQuestionLevelSelect: [],
            isEnableSpeak: true,
            listLook: [],
            userSelect: {},
            listGift: [],
            giftSelect: {}
        };
        this.onUpdateChat = ''
    }

    getRoomInfo = () => {
        let redirectPath = '/';
        this.roomId = queryString.parse(this.props.location.search).id;
        if (this.roomId) {
            getRoomInfoApi(this.roomId).then(res => {
                if (res.code == 1) {
                    let { roomInfo, listPlayer, listChat, listLook } = res;
                    this.setState({
                        roomInfo,
                        listPlayer,
                        listLook,
                        isLoading: false
                    });
                    socket.emit('join_fight_room', {
                        roomId: this.roomId,
                        userId: this.props.dataUser.UserId,
                        listPlayer,
                        listLook
                    });
                }
                else {
                    showNotification(res.message, res.code);
                    this.props.history.push(redirectPath);
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.props.history.push(redirectPath);
            })
        }
        else this.props.history.push(redirectPath);
    }

    leaveRoom = () => {
        if (!this.state.isPress) {
            let idNotification = '#notificationQuitRoomFight';
            let roomId = this.state.roomInfo.QuestionFightRoomId;
            this.setState({ isPress: true });
            leaveRoomApi(roomId).then(res => {
                if (res.code == 1) {
                    socket.emit('leave_fight_room', {
                        roomId,
                        listPlayer: res.listPlayer,
                        listLook: res.listLook
                    });
                    showNotification(res.message, res.code);
                    this.props.history.push('/question-fight');
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    getQuestionLevelSelect = () => {
        getQuestionLevelSelectApi().then(res => {
            if (res.code == 1) {
                this.setState({ listQuestionLevelSelect: res.listSelect });
            }
        })
    }

    showModalEditRoom = () => {
        let { roomInfo, listQuestionLevelSelect } = this.state;
        this.setState({
            showModal: true,
            modalType: 1,
            roomName: roomInfo.RoomName,
            betTypeSelect: roomInfo.BetType,
            questionLevelSelect: roomInfo.QuestionLevelId,
            betValue: roomInfo.BetType == 1 ? roomInfo.BetValue : formatDecimal(parseInt(roomInfo.BetValue)),
            maxPlayer: roomInfo.MaxPlayer,
            timeLimit: roomInfo.TimeLimit,
            fightType: roomInfo.FightType,
        });
        if (listQuestionLevelSelect.length == 0) this.getQuestionLevelSelect();
    }

    changeBetType = e => {
        let listBet = LIST_BET_TYPE_3;
        const betTypeSelect = e.target.value;
        if (betTypeSelect == 2) {
            listBet = LIST_BET_TYPE_2;
        }
        else if (betTypeSelect == 1) {
            listBet = LIST_BET_TYPE_1;
        }
        this.setState({
            betTypeSelect,
            betValue: listBet[0].value
        });
    }

    // changeBetValue = e => {
    //     let betValue = e.target.value;
    //     if(this.state.betTypeSelect == 1) this.setState({betValue: betValue?.replace(/,|\./g, '')});
    //     else this.setState({betValue: formatDecimal(betValue?.replace('.', ''))});
    // }

    editRoom = () => {
        if (!this.state.isPress) {
            let idNotification = '#notificationEditRoomFight';
            let { roomName, questionLevelSelect, betTypeSelect, betValue, maxPlayer, timeLimit, fightType, listQuestionLevelSelect } = this.state;
            if (betTypeSelect == 1) {
                if (betValue < 0) return showNotification('Số Gold[z] không được để trống', -1, idNotification);
                if (getDecimalCount(betValue) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', -1, idNotification);
            }
            else {
                betValue = replaceCost(betValue, false);
                if (betValue < 0) return showNotification('Số $[z] / đ[z] không được để trống', -1, idNotification);
            }
            this.setState({ isPress: true });
            let roomId = this.state.roomInfo.QuestionFightRoomId;
            editRoomApi(roomId, roomName, questionLevelSelect, betTypeSelect, betValue, maxPlayer, timeLimit, fightType).then(res => {
                if (res.code == 1) {
                    let betName = (betTypeSelect == 1 ? betValue : formatDecimal(betValue)) + ' ' + BALANCE_TYPE[betTypeSelect];
                    let questionLevelTitle;
                    listQuestionLevelSelect.map(item => {
                        if (questionLevelSelect == item.QuestionLevelId) questionLevelTitle = item.Title;
                    });
                    questionLevelTitle = questionLevelTitle || 'Khởi nghiệp hỗn hợp';
                    let roomInfo = {
                        ...this.state.roomInfo,
                        RoomName: roomName,
                        BetType: betTypeSelect,
                        QuestionLevelId: questionLevelSelect,
                        QuestionLevelTitle: questionLevelTitle,
                        BetValue: betValue,
                        BetName: betName,
                        MaxPlayer: maxPlayer,
                        TimeLimit: timeLimit,
                        FightType: fightType
                    }
                    this.setState({
                        isPress: false,
                        showModal: false,
                        roomInfo
                    });
                    socket.emit('edit_fight_room', {
                        roomInfo,
                        roomId
                    })
                    showNotification(res.message, res.code);
                }
                else if (res.code == -99) {
                    this.props.showModalBuyVip();
                    this.setState({
                        isPress: false,
                        showModal: false
                    })
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ isPress: false });
            })
        }
    }

    ready = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            readyApi().then(res => {
                if (res.code == 1) {
                    if (res.type == 1) { // chủ phòng bắt đầu
                        socket.emit('start_fight_room', this.state.roomInfo);
                    }
                    else { // người chơi sẵn sàng
                        let userId = this.props.dataUser.UserId;
                        let ready = res.ready;
                        this.setState({
                            isPress: false,
                            listPlayer: this.state.listPlayer.map(el => {
                                if (el.UserId == this.props.dataUser.UserId) return Object.assign({}, el, {
                                    Ready: ready
                                })
                                return el;
                            })
                        })
                        socket.emit('user_ready_fight_room', {
                            userId,
                            ready,
                            roomId: this.state.roomInfo.QuestionFightRoomId
                        })
                    }
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    changeTeam = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            changeTeamApi().then(res => {
                if (res.code == 1) {
                    let userId = this.props.dataUser.UserId;
                    let team = res.team;
                    this.setState({
                        isPress: false,
                        listPlayer: this.state.listPlayer.map(el => {
                            if (el.UserId == userId) return Object.assign({}, el, {
                                Ready: 0,
                                Team: team
                            })
                            return el;
                        })
                    })
                    socket.emit('change_team_fight_room', {
                        userId,
                        team,
                        roomId: this.state.roomInfo.QuestionFightRoomId
                    })
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    showModalInvite = () => {
        this.setState({
            showModal: true,
            modalType: 3
        });
        getListInviteApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listInvite: res.listInvite,
                    isLoadingListOnline: false
                })
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    pickUserInvite = userId => {
        let listUserSelectInvite = this.state.listUserSelectInvite;
        if (listUserSelectInvite.includes(userId))
            listUserSelectInvite = listUserSelectInvite.filter(e => e != userId)
        else
            listUserSelectInvite.push(userId);
        this.setState({ listUserSelectInvite });
    }

    invite = () => {
        let idNotification = '#notificationInviteFight';
        let listUserSelectInvite = this.state.listUserSelectInvite;
        if (listUserSelectInvite.length > 0) {
            let { FullName, FacebookId } = this.props.dataUser;
            sendInviteNotificationApi(listUserSelectInvite);
            socket.emit('invite_user_fight', {
                listUserSelectInvite,
                roomInfo: {
                    ...this.state.roomInfo,
                    FullName,
                    FacebookId
                },
            });
            this.setState({
                showModal: false,
                listUserSelectInvite: []
            });
            showNotification('Mời thành công', 1);
        }
        else showNotification('Vui lòng chọn ít nhất 1 người để mời', 0, idNotification)
    }

    showModalKickUser = userSelect => {
        this.setState({
            showModal: true,
            modalType: 4,
            userSelect
        })
    }

    searchUser = userSelect => {
        if (userSelect[0]) {
            let userId = userSelect[0].userId;
            this.refs.searchUser.getInstance().clear();
            this.setState({ listUserSelectInvite: [userId] }, this.invite);
        }
    }

    toggleLook = (type) => {
        if (!this.state.isPress) {
            toggleLookApi(type).then(res => {
                if (res.code == 1) {
                    let { listPlayer, listLook } = res;
                    this.setState({
                        listPlayer,
                        listLook,
                    });
                    socket.emit('toggle_look_fight_room', {
                        roomId: this.roomId,
                        userId: this.props.dataUser.UserId,
                        listPlayer,
                        listLook
                    });
                }
                else if (res.code == -99) {
                    this.props.showModalBuyVip();
                    this.setState({
                        isPress: false,
                        showModal: false
                    })
                }
                else {
                    showNotification(res.message, res.code)
                    this.setState({ isPress: false })
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    renderBetValue = () => {
        const { betTypeSelect, betValue } = this.state;
        if (betTypeSelect == 3) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_3.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        if (betTypeSelect == 2) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_2.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        if (betTypeSelect == 1) {
            return (
                <select
                    className='form-control mgt'
                    onChange={e => this.setState({ betValue: e.target.value })}
                    value={betValue}
                >
                    {LIST_BET_TYPE_1.map(item =>
                        <option value={item.value} key={item.value}>{item.label}</option>
                    )}
                </select>
            )
        }
        return null
    }

    onChangeQuestionLevel = e => {
        const value = e.target.value
        this.setState({
            questionLevelSelect: value,
        })
        if (this.state.questionLevelSelect == 100 && value != 100) {
            this.changeBetType({ target: { value: 3 } })
        }
        else if (value == 100) {
            this.setState({ betValue: 0 })
        }
    }

    renderModal = () => {
        let {
            modalType,
            isPress,
            roomName,
            roomInfo,
            questionLevelSelect,
            betTypeSelect,
            betValue,
            maxPlayer,
            timeLimit,
            fightType,
            isLoadingListOnline,
            listInvite,
            listUserSelectInvite,
            userSelect,
            listQuestionLevelSelect,
            giftSelect,
            listGift
        } = this.state;
        let { dataUser } = this.props
        switch (modalType) {
            case 1: // chủ phòng edit room
                return (
                    <>
                        {listQuestionLevelSelect.length == 0 ?
                            <Loading />
                            :
                            <>
                                <div className='item-center mgb'>
                                    <img
                                        src={require('../../../assets/image/config.png')}
                                        className='fight-room-img-modal'
                                    />
                                </div>
                                <input
                                    className='form-control mgt text-center'
                                    placeholder='Nhập tên phòng'
                                    value={roomName}
                                    onChange={e => this.setState({ roomName: e.target.value })}
                                />
                                {questionLevelSelect != 100 &&
                                    <>
                                        <select
                                            className='form-control mgt'
                                            onChange={this.onChangeQuestionLevel}
                                            defaultValue={questionLevelSelect}
                                        >
                                            {listQuestionLevelSelect.map((item, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        {key == 1 &&
                                                            <option
                                                                value='0'
                                                            >
                                                                Khởi nghiệp hỗn hợp
                                                            </option>
                                                        }
                                                        <option
                                                            value={item.QuestionLevelId}
                                                        >
                                                            {item.Title}
                                                        </option>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </select>
                                        <select
                                            className='form-control mgt'
                                            onChange={this.changeBetType}
                                            defaultValue={betTypeSelect}
                                        >
                                            <option value='1'>Cược Gold[z]</option>
                                            <option value='2'>Cược $[z]</option>
                                            <option value='3'>Cược đ[z]</option>
                                        </select>
                                        {this.renderBetValue()}
                                    </>
                                }
                                {/* <input
                                    value={betValue}
                                    onChange={this.changeBetValue}
                                    className='form-control text-center mgt'
                                    placeholder='Mức cược'
                                /> */}
                                <select
                                    className='form-control mgt'
                                    onChange={e => this.setState({ maxPlayer: e.target.value })}
                                    defaultValue={maxPlayer}
                                >
                                    {new Array(7).fill(undefined).map((_item, key) =>
                                        <option value={key + 2} key={key}>Tối đa {key + 2} người</option>
                                    )}
                                </select>
                                <select
                                    className='form-control mgt'
                                    onChange={e => this.setState({ timeLimit: e.target.value })}
                                    defaultValue={timeLimit}
                                >
                                    <option value='5'>Thời gian trả lời: 5 giây</option>
                                    <option value='10'>Thời gian trả lời: 10 giây</option>
                                    <option value='15'>Thời gian trả lời: 15 giây</option>
                                    <option value='20'>Thời gian trả lời: 20 giây</option>
                                    <option value='30'>Thời gian trả lời: 30 giây</option>
                                    <option value='150'>Thời gian trả lời: 150 giây</option>
                                    <option value='300'>Thời gian trả lời: 300 giây</option>
                                </select>
                                <div className='row mgt'>
                                    <div
                                        className={'col-xs-6 box-primary item-center opacity-hover pointer' + (fightType == 1 && ' bg-success')}
                                        onClick={() => fightType == 2 && this.setState({ fightType: 1 })}
                                    >
                                        <img
                                            src={require('../../../assets/image/fight-solo.png')}
                                            className='fight-type-img'
                                        />
                                        <p>Đấu tự do</p>
                                    </div>
                                    <div
                                        className={'col-xs-6 box-primary item-center opacity-hover pointer' + (fightType == 2 && ' bg-success')}
                                        onClick={() => fightType == 1 && this.setState({ fightType: 2 })}
                                    >
                                        <img
                                            src={require('../../../assets/image/fight-team.png')}
                                            className='fight-type-img'
                                        />
                                        <p>Đấu đội</p>
                                    </div>
                                </div>
                                <div className='mgt' id='notificationEditRoomFight' />
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgt'
                                        onClick={this.editRoom}
                                        loading={isPress}
                                    >
                                        Xác nhận
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                )
            case 2: // rời room
                return (
                    <div className='item-center'>
                        <img
                            src={require('../../../assets/image/quit.png')}
                            className='fight-room-img-modal'
                        />
                        <p className='text-large mgt'>Xác nhận rời khỏi phòng và trở về sảnh?</p>
                        <div id='notificationQuitRoomFight' />
                        <Button
                            className='bg-danger mgt-4'
                            onClick={this.leaveRoom}
                            loading={isPress}
                        >
                            Rời khỏi phòng
                        </Button>
                    </div>
                )
            case 3: // mời bạn bè
                if (isLoadingListOnline) return <Loading />
                return (
                    <div className='mgt-2'>
                        <TitleSuccess>Mời bạn bè</TitleSuccess>
                        <SearchUser
                            ref='searchUser'
                            onChange={this.searchUser}
                            placeholder='Nhập họ tên hoặc số điện thoại để mời 🔎'
                            className='mgt'
                        />
                        <div className='mgt-2'>
                            <TitleSuccess>Quanh đây</TitleSuccess>
                        </div>
                        {listInvite.length > 0 ?
                            <>
                                {listInvite.map((item, key) =>
                                    <div
                                        className='box-primary flex align-center'
                                        key={key}
                                        onClick={() => this.pickUserInvite(item.UserId)}
                                    >
                                        <div className='flex-row flex-1'>
                                            <TooltipUser userInfo={item} />
                                            <div className='mgl-2'>
                                                <p>{item.FullName}</p>
                                                <p>{roomInfo.BetType == 1 ? item.TicketCount : formatDecimal(roomInfo.BetType == 2 ? item.Balance : item.BalanceZ)} {BALANCE_TYPE[roomInfo.BetType]}</p>
                                            </div>
                                        </div>
                                        <Checkbox
                                            checked={listUserSelectInvite.includes(item.UserId)}
                                        />
                                    </div>
                                )}
                                <div className='item-center mgt-2'>
                                    <div id='notificationInviteFight' />
                                    <Button
                                        className='bg-success mgt'
                                        onClick={this.invite}
                                    >
                                        Mời
                                    </Button>
                                </div>
                            </>
                            :
                            <EmptyLottie title='Không có thành viên nào đang hoạt động' />
                        }
                    </div>
                )
            case 4: // kick user
                return (
                    <div className='mgt-2 item-center'>
                        <TooltipUser userInfo={userSelect} />
                        <p className='text-large mgt-2'>Yêu cầu {userSelect.FullName} ra khỏi phòng?</p>
                        <div className='item-center mgt-2'>
                            <div id='notificationKickUserFight' />
                            <Button
                                className='bg-danger mgt'
                                onClick={this.kickUser}
                                loading={isPress}
                            >
                                Đồng ý
                            </Button>
                        </div>
                    </div>
                )
            case 5: // tang qua
                return (
                    <div className='mgt-2 item-center'>
                        <div className='flex item-center'>
                            <TooltipUser userInfo={dataUser} />
                            <Icon
                                name='gift'
                                className='success mgl-2 mgr-2'
                                style={{ fontSize: '5vh' }}
                            />
                            <TooltipUser userInfo={userSelect} />
                        </div>
                        <div className="row mgt">
                            {listGift.map((item, key) =>
                                <Tooltip {...PROP.TOOLTIP_HOME} mouseEnterDelay={0.5} key={key} title={
                                    <div>
                                        <Button
                                            className='popover-item bg-success'
                                            onClick={this.sendGift}
                                            loading={isPress}
                                        >
                                            Tặng
                                        </Button>
                                    </div>
                                }>
                                    <div
                                        className={`col-md-2 col-sm-3 col-xs-4 item-center box-item-gift-fight ${giftSelect.QuestionFightGiftId == item.QuestionFightGiftId ? 'bg-success' : ""}`}
                                        key={key}
                                        onClick={() => this.setState({ giftSelect: item })}
                                        onMouseEnter={() => this.setState({ giftSelect: item })}
                                    >
                                        <img
                                            className='mgb'
                                            src={`${URI_PATH}${this.getAsset(item).image}`}
                                            style={{ width: '7vh' }}
                                        />
                                        <p>{this.getAsset(item).name}</p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
        }
    }

    sendGift = () => {
        if (!this.state.isPress) {
            let { userSelect, giftSelect } = this.state;
            this.setState({ isPress: true })
            sendGiftFightApi(giftSelect.QuestionFightGiftId, userSelect.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        isPress: false,
                        showModal: false
                    });
                    let roomId = queryString.parse(this.props.location.search).id;
                    let dataUser = this.props.dataUser;
                    let dataChat = {
                        QuestionFightRoomId: roomId,
                        UserId: dataUser.UserId,
                        FullName: dataUser.FullName,
                        FacebookId: dataUser.FacebookId,
                        Message: res.name,
                        CrDateTime: 'Vừa xong'
                    }
                    this.onUpdateChat(dataChat)
                    showNotificationFightMessage(dataChat);
                    if (giftSelect.TypeBalance == 3) {
                        showNotificationBonus(giftSelect.BalanceItem, -1, giftSelect.TypeBalance)
                    }
                    else showNotificationBonus(giftSelect.Balance, -1, giftSelect.TypeBalance)
                    // this.updateChatState(dataChat);
                    //socket
                    socket.emit('user_chat_fight', {
                        roomId,
                        dataChat
                    });
                }
                else if (res.code == -99) {
                    this.props.showModalBuyVip();
                    this.setState({ isPress: false, showModal: false });
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code)
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    getAsset = (item) => {
        if (item.TypeAsset == 1) return {
            image: item.ItemImage,
            name: `${formatDecimal(parseFloat(item.BalanceItem))} đ[z]`
        }
        if (item.TypeAsset == 2) return {
            image: item.FoodImage,
            name: `${item.TypeBalance == 1 ? item.Balance : formatDecimal(parseFloat(item.Balance))} ${item.TypeBalance == 1 ? 'Gold[z]' : '$[z]'}`
        }
        return {
            image: item.AssetImage,
            name: `${formatDecimal(parseFloat(item.Balance))} $[z]`
        }
    }

    kickUser = () => {
        if (!this.state.isPress) {
            let idNotification = '#notificationKickUserFight';
            let userSelect = this.state.userSelect;
            this.setState({ isPress: true })
            kickUserApi(userSelect.UserId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        showModal: false,
                        userSelect: {},
                        isPress: false
                    });
                    showNotification(`Yêu cầu ${userSelect.FullName} rời khỏi phòng thành công`, res.code);
                    socket.emit('kick_user_fight_room', {
                        roomId: this.state.roomInfo.QuestionFightRoomId,
                        listPlayer: res.listPlayer,
                        userId: userSelect.UserId
                    });
                }
                else {
                    this.setState({ isPress: false });
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e => {
                this.setState({ isPress: false });
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    updateListPlayerSocket = (listPlayer, listLook) => {
        this.setState({ listPlayer, listLook })
    }

    changeTeamSocket = data => {
        this.setState({
            listPlayer: this.state.listPlayer.map(el => {
                if (el.UserId == data.userId) return Object.assign({}, el, {
                    Ready: 0,
                    Team: data.team
                })
                return el;
            })
        })
    }

    readySocket = data => {
        this.setState({
            listPlayer: this.state.listPlayer.map(el => {
                if (el.UserId == data.userId) return Object.assign({}, el, {
                    Ready: data.ready
                })
                return el;
            })
        })
    }

    editRoomSocket = data => {
        this.setState({
            roomInfo: data.roomInfo,
            listPlayer: this.state.listPlayer.map(el => {
                return Object.assign({}, el, {
                    Ready: 0
                })
            })
        })
    }

    kickUserSocket = data => {
        if (data.userId == this.props.dataUser.UserId) {
            socket.emit('leave_fight_room', { roomId: this.state.roomInfo.QuestionFightRoomId });
            this.props.history.push('/question-fight');
            showNotification('Bạn bị mời ra khỏi phòng', 0);
        }
        else this.setState({ listPlayer: data.listPlayer })
    }

    startFightSocket = roomInfo => {
        const isLook = this.state.listLook?.some(item => item.UserId == this.props.dataUser.UserId);
        if (!isLook) {
            if (roomInfo.BetType == 1) this.props.changePoint([-roomInfo.BetValue]);
            else if (roomInfo.BetType == 2) this.props.changePoint([null, -roomInfo.BetValue]);
            else if (roomInfo.BetType == 3) this.props.changePoint([null, null, -roomInfo.BetValue]);
            if (roomInfo.BetValue > 0) showNotificationBonus(parseFloat(roomInfo.BetValue), -1, roomInfo.BetType);
        }
        this.props.history.push('/question-fight/fight');
    }

    renderAction = () => {
        let {
            roomInfo,
            listPlayer,
            isPress,
            listLook
        } = this.state;
        let { dataUser } = this.props;
        const fightUserData = listPlayer.filter(item => item.UserId == dataUser.UserId)[0] || {};
        const isCaptain = fightUserData ? fightUserData.Role == 1 : false;
        const isLook = listLook?.some(item => item.UserId == dataUser.UserId);
        if (isLook) {
            return (
                <>
                    <ButtonActionFight
                        title='Mời bạn bè'
                        isActive={true}
                        onClick={this.showModalInvite}
                    />
                    <ButtonActionFight
                        title='Vào thi đấu'
                        isActive={true}
                        loading={isPress}
                        onClick={() => this.toggleLook(1)}
                    />
                    <ButtonActionFight
                        title='Rời phòng'
                        isActive={true}
                        onClick={() => this.setState({ showModal: true, modalType: 2 })}
                    />
                </>
            )
        }
        return (
            <>
                <ButtonActionFight
                    title='Mời bạn bè'
                    isActive={true}
                    onClick={this.showModalInvite}
                />
                {!isCaptain &&
                    <ButtonActionFight
                        title='Làm khán giả'
                        isActive={true}
                        onClick={() => this.toggleLook(2)}
                        loading={isPress}
                    />
                }
                {roomInfo.FightType == 2 &&
                    <ButtonActionFight
                        title='Đổi bên'
                        isActive={true}
                        onClick={this.changeTeam}
                        loading={isPress}
                    />
                }
                {isCaptain &&
                    <ButtonActionFight
                        title='Thiết lập phòng'
                        isActive={true}
                        onClick={this.showModalEditRoom}
                    />
                }
                <ButtonActionFight
                    title={isCaptain ? 'Bắt đầu' : fightUserData.Ready == 0 ? 'Sẵn sàng' : 'Huỷ sẵn sàng'}
                    isActive={fightUserData.Ready == 0}
                    loading={isPress}
                    onClick={this.ready}
                />
                <ButtonActionFight
                    title='Rời phòng'
                    isActive={true}
                    onClick={() => this.setState({ showModal: true, modalType: 2 })}
                />
            </>
        )
    }

    getListGiftFight = () => {
        getListGiftFightApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    listGift: res.listGift
                })
            }
        })
    }

    componentDidMount() {
        this.getRoomInfo();
        this.getListGiftFight();
        //read socket
        socket.on('update_list_player_fight_room', this.updateListPlayerSocket);
        socket.on('change_team_fight_room', this.changeTeamSocket);
        socket.on('user_ready_fight_room', this.readySocket);
        socket.on('edit_fight_room', this.editRoomSocket);
        socket.on('kick_user_fight_room', this.kickUserSocket);
        socket.on('start_fight_room', this.startFightSocket);
        //
    }

    componentWillUnmount() {
        //remove socket event
        socket.removeListener('update_list_player_fight_room', this.updateListPlayerSocket);
        socket.removeListener('change_team_fight_room', this.changeTeamSocket);
        socket.removeListener('user_ready_fight_room', this.readySocket);
        socket.removeListener('edit_fight_room', this.editRoomSocket);
        socket.removeListener('kick_user_fight_room', this.kickUserSocket);
        socket.removeListener('start_fight_room', this.startFightSocket);
        //
    }

    render() {
        let {
            roomInfo,
            listPlayer,
            showModal,
            isLoading,
            isEnableSpeak,
            listLook,
            modalType
        } = this.state;
        let { dataUser } = this.props;
        //
        let fightUserData = listPlayer.filter(item => item.UserId == dataUser.UserId)[0] || {};
        if (isLoading) return <Loading />;
        let isCaptain = fightUserData ? fightUserData.Role == 1 : false;
        let listPlayerTeam1;
        let listPlayerTeam2;
        if (roomInfo.FightType == 2) {
            listPlayerTeam1 = listPlayer.filter(item => item.Team == 1)
            listPlayerTeam2 = listPlayer.filter(item => item.Team == 2)
        }
        //
        return (
            <>
                {listLook?.length > 0 &&
                    <div className='looker'>
                        <div className='looker-container'>
                            {/* <div className='looker-list flex item-center'>
                                <img
                                    src={isEnableSpeak ? require('../../../assets/image/speaker.png') : require('../../../assets/image/speaker-off.png')}
                                    className='avatar-guest-small'
                                    onClick={() => this.setState({ isEnableSpeak: !isEnableSpeak })}
                                />
                            </div> */}
                            {listLook.map((item, key) =>
                                <div className='looker-list' key={key}>
                                    <TooltipUser userInfo={item} />
                                </div>
                            )}
                        </div>
                    </div>
                }
                {roomInfo.FightType == 1 ? // đấu đơn
                    <div className='box-primary row'>
                        {listPlayer.map((item, key) =>
                            <div className='col-md-3 col-sm-6 col-xs-6 item-center flex-column' key={key}>
                                <div className='relative'>
                                    <TooltipUser userInfo={item} />
                                    {isCaptain && item.UserId != dataUser.UserId && // chủ phòng đc kick user
                                        <img
                                            src={require('../../../assets/image/delete.png')}
                                            className='kick-user-img-fight'
                                            onClick={() => this.showModalKickUser(item)}
                                        />
                                    }
                                    {dataUser.UserId != item.UserId &&
                                        <Icon
                                            name='gift'
                                            className='icon-gift-fight'
                                            onClick={() => this.setState({
                                                showModal: true,
                                                userSelect: item,
                                                modalType: 5
                                            })}
                                        />
                                    }
                                </div>
                                <p className='mgt'>{item.FullName}</p>
                                <p>{roomInfo.BetType == 1 ? item.TicketCount : formatDecimal(roomInfo.BetType == 2 ? item.Balance : item.BalanceZ)} {BALANCE_TYPE[roomInfo.BetType]}</p>
                                {item.Role != 1 ? //check Chủ phòng
                                    <ReadyTitle
                                        title={item.Ready == 0 ? 'Chưa sẵn sàng' : 'Sẵn sàng'}
                                        isActive={item.Ready > 0}
                                    />
                                    :
                                    <div style={{ height: '4.5vh' }} />
                                }
                            </div>
                        )}
                    </div>
                    :
                    <div className='box-primary'>
                        {listPlayerTeam1.length > 0 &&
                            <div className='box-primary row'>
                                {listPlayerTeam1.map((item, key) =>
                                    <div className='col-md-3 col-sm-6 col-xs-6 item-center flex-column' key={key}>
                                        <div className='relative'>
                                            <TooltipUser userInfo={item} />
                                            {isCaptain && item.UserId != dataUser.UserId && // chủ phòng đc kick user
                                                <img
                                                    src={require('../../../assets/image/delete.png')}
                                                    className='kick-user-img-fight'
                                                    onClick={() => this.showModalKickUser(item)}
                                                />
                                            }
                                            {dataUser.UserId != item.UserId &&
                                                <Icon
                                                    name='gift'
                                                    className='icon-gift-fight'
                                                    onClick={() => this.setState({
                                                        showModal: true,
                                                        userSelect: item,
                                                        modalType: 5
                                                    })}
                                                />
                                            }
                                        </div>
                                        <p className='mgt'>{item.FullName}</p>
                                        <p>{roomInfo.BetType == 1 ? item.TicketCount : formatDecimal(roomInfo.BetType == 2 ? item.Balance : item.BalanceZ)} {BALANCE_TYPE[roomInfo.BetType]}</p>
                                        {item.Role != 1 && //check Chủ phòng
                                            <ReadyTitle
                                                title={item.Ready == 0 ? 'Chưa sẵn sàng' : 'Sẵn sàng'}
                                                isActive={item.Ready > 0}
                                            />
                                        }
                                    </div>
                                )}
                            </div>
                        }
                        {listPlayerTeam2.length > 0 &&
                            <div className='box-primary bg-warning row mgt'>
                                {listPlayerTeam2.map((item, key) =>
                                    <div className='col-md-3 col-sm-6 col-xs-6 item-center flex-column' key={key}>
                                        <div className='relative'>
                                            <TooltipUser userInfo={item} />
                                            {isCaptain && item.UserId != dataUser.UserId && // chủ phòng đc kick user
                                                <img
                                                    src={require('../../../assets/image/delete.png')}
                                                    className='kick-user-img-fight'
                                                    onClick={() => this.showModalKickUser(item)}
                                                />
                                            }
                                            {dataUser.UserId != item.UserId &&
                                                <Icon
                                                    name='gift'
                                                    className='icon-gift-fight'
                                                    onClick={() => this.setState({
                                                        showModal: true,
                                                        userSelect: item,
                                                        modalType: 5
                                                    })}
                                                />
                                            }
                                        </div>
                                        <p className='mgt'>{item.FullName}</p>
                                        <p>{roomInfo.BetType == 1 ? item.TicketCount : formatDecimal(roomInfo.BetType == 2 ? item.Balance : item.BalanceZ)} {BALANCE_TYPE[roomInfo.BetType]}</p>
                                        {item.Role != 1 && //check Chủ phòng
                                            <ReadyTitle
                                                title={item.Ready == 0 ? 'Chưa sẵn sàng' : 'Sẵn sàng'}
                                                isActive={item.Ready > 0}
                                            />
                                        }
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                }
                <div className='box-primary'>
                    <div className='flex-row item-center'>
                        <div style={{ marginRight: '2vh' }}>
                            <img
                                src={roomInfo.FightType == 1 ? require('../../../assets/image/fight-solo.png') : require('../../../assets/image/fight-team.png')}
                                className='room-fight-image'
                            />
                            <p className='mgt text-left'>{roomInfo.FightType == 1 ? 'Đấu tự do' : 'Đấu đội'}</p>
                        </div>
                        <div>
                            <p className='text-large text-left'>{roomInfo.RoomName}</p>
                            <p className='text-left'>Đấu: {roomInfo.QuestionLevelTitle || 'Khởi nghiệp hỗn hợp'}</p>
                            <p className='text-left'>Cược: {roomInfo.BetName}</p>
                            <p className='text-left'>Thời gian trả lời: {roomInfo.TimeLimit} giây</p>
                            <p className='text-left'>Tối đa: {roomInfo.MaxPlayer} người</p>
                        </div>
                    </div>
                    <div className='row item-center'>
                        {this.renderAction()}
                    </div>
                </div>
                <ChatFight roomId={this.roomId} onUpdateChat={e => this.onUpdateChat = e} />
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false, userSelect: {}, giftSelect: {} })}
                    centered
                    width={modalType == 5 ? '140vh' : '70vh'}
                >
                    {this.renderModal()}
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { categoryQuestion } = state.getCategory;
    return { dataUser, categoryQuestion };
}

export default connect(mapStateToProps, { getCategory, changePoint, showModalBuyVip })(withRouter(Room));