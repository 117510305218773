import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home/';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import { Button, Modal, Progress } from 'antd';
import { getUserPresent } from '../../actions/home';
import { updateCountMission } from '../../actions/menu';
import { checkanswerApi, getInfoMiniGameApi, playMiniGameApi, registerMiniGameApi } from '../../api/minigame';
import moment from 'moment';
import { TitleSuccess } from '../../components/frame';
import Lottie from 'react-lottie';
import TextAnswer from '../question/TextAnswer';
import ResultMessage from '../question/ResultMessage';
import Loading from '../Loading';
import gift from '../../assets/lottie/gift.json';
import space from '../../assets/lottie/space.json';
import win from '../../assets/lottie/win.json';
import hammer from '../../assets/lottie/hammer.json';
import { speakEng } from '../../utils/common';
import { getListRankApi } from '../../api/minigame/rank';
import TooltipUser from '../TooltipUser';
import ClubDeputy from '../club/ClubDeputy';
import ClubVipLevel from '../club/ClubVipLevel';
import { showModalEat } from '../../actions/home/modalEat';
import firework from '../../assets/lottie/firework2.json';
import { zeroPad } from 'react-countdown-now';

class MiniGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            miniGame: null,
            showModalRule: false,
            showModalRegSuccess: false,
            showModalQuestion: false,
            showModalWaiting: false,
            showModalPlaySuccess: false,
            showModalRank: false,
            showModalMissing: false,
            questionList: [],
            numberCorrect: 0,
            textTime: '--:--',
            selectAnswerKey: '',
            resultAnswer: false,
            isAnswer: false,
            listRank: [],
            isLoadingRank: true,
            qoute:{},
            miniGameRankSelect: '',
            countRankCurrent: ''
        }
        this.timeDiff = 99999;
        this.numberQuestion = 0;
        this.countDown = 99999;
        this.correctAnswer = '';
        this.questionInterval = '';
    }

    renderMiniGame = () => {
        const { miniGame, isPress } = this.state
        if (!miniGame) {
            return null;
        }
        return (
            <>
                <div className='flex-column item-center'>
                    <div className='container-list-gift flex-column' style={{ padding: '2vh', width: '100%' }}>
                    <p className='bold text-title-gift'>{miniGame.Title}</p>
                    <div className='flex-row row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 item-center flex-column'>
                            {
                                moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(moment(miniGame.EndDate, 'YYYY-MM-DD')) ||
                                miniGame.MiniGameRegisterId > 0
                                ?
                                <Button
                                    className='bg-success mgb button-info-mini-game'
                                    onClick={() => {
                                        this.setState({ showModalRank: true });
                                        this.getListRank();
                                    }}
                                >Bảng Xếp Hạng</Button>
                                :
                                <Button
                                    className='bg-success mgb button-info-mini-game'
                                    onClick={this.registerMiniGame}
                                    loading={isPress}
                                >
                                    Đăng ký chơi
                                </Button>
                            }
                            <Button
                                className='bg-warning mgt button-info-mini-game'
                                onClick={() => this.setState({ showModalRule: true })}
                                style={{fontSize: '2vh'}}
                            >
                                Thể lệ & Phần thưởng
                            </Button>
                        </div>
                        <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center mgt'>
                            <Progress
                                strokeColor={{ '0%': '#dd4b39', '100%': '#87d068' }}
                                type="dashboard"
                                percent={miniGame.TotalDayPlayed / miniGame.TotalDay * 100}
                                format={() => <p>{miniGame.TotalDayPlayed}/{miniGame.TotalDay}</p>}
                            />
                            <p className='text-large'>Minigame lần {miniGame.Round}</p>
                            <p className='text-large'>6:00 - 6:15</p>
                        </div>
                        <div className='col-md-4 col-sm-12 col-xs-12 item-center flex-column'>
                            {this.renderButtonPlay()}
                        </div>
                    </div>
                    <div className='row item-center mgt'>
                        {Array(5).fill(undefined).map((_, key) =>{
                            const day =  moment(miniGame.StartDate).add(key, 'days').format('YYYY-MM-DD');
                            const isPass = miniGame.ListPlayed.find(i => moment(i.CrDateTime, 'YYYY-MM-DD').isSame(day))
                            let cssButton = isPass ? 'bg-success' : 'bg-danger';
                            if(
                                !isPass && 
                                (
                                    moment(moment().format('YYYY-MM-DD')).isBefore(moment(miniGame.StartDate).add(key, 'days').format('YYYY-MM-DD')) ||
                                    (moment(moment().format('YYYY-MM-DD')).isSame(moment(miniGame.StartDate).add(key, 'days').format('YYYY-MM-DD')) && moment().isSameOrBefore(moment(moment().format('YYYY-MM-DD 06:15:00'))))
                                )
                            ){
                                cssButton = 'bg-primary'
                            }
                            return(
                                <div className='col-md-2 col-sm-2 col-xs-4 flex item-center' key={key}>
                                    <div
                                        className={`button-status-minigame ${cssButton}`}
                                    >
                                        <p className='mgb-0'>T{key + 2}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    </div>
                </div>
                {this.renderModalRule()}
                {this.renderModalRegSuccess()}
                {this.renderModalQuestion()}
                {this.renderModalWait()}
                {this.renderPlaySuccess()}
                {this.renderModalRank()}
                {this.renderModalMissing()}
            </>
        )
    }

    renderButtonPlay = () => {
        const { miniGame } = this.state;
        if (miniGame.IsEnd) {
            return (
                <>
                    <Lottie options={{ loop: false, autoplay: true, animationData: hammer }}
                        height='22vh'
                        width='22vh'
                    />
                    <p className='text-large text-center mgt'>Minigame lần {miniGame.Round} đã kết thúc</p>
                    <p className='text-large text-center mgt'>Hãy vào Bảng Xếp Hạng để xem kết quả</p>
                </>
            )
        }
        // if (miniGame.IsMissing) {
        //     return (
                // <img
                //     className='minigame-fail-img'
                //     src={require('../../assets/image/minigame-fail.png')}
                // />
        //     )
        // }
        if (miniGame.IsPlayed > 0) {
            return (
                <>
                    <Lottie options={{ loop: true, autoplay: true, animationData: win }}
                        height='22vh'
                        width='22vh'
                    />
                    <p className='text-large text-center'>Dậy sớm thành công</p>
                </>
            )
        }
        return (
            <Button
                className={`mgb button-reg-mini-game  ${miniGame.IsPlayable ? 'bg-success shadow-pulse' : 'bg-primary'}`}
                onClick={this.playMiniGame}
            >
                Điểm danh
            </Button>
        )
    }

    renderModalWait = () => {
        const {miniGame} = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalWaiting}
                onCancel={() => this.setState({ showModalWaiting: false })}
                centered
            >
                <Lottie options={{ loop: true, autoplay: true, animationData: space }}
                    height='30vh'
                    width='30vh'
                />
                <p className='text-large text-center'>Minigame lần {miniGame.Round} sẽ bắt đầu vào 6:00 ngày {moment(miniGame.StartDate).format('DD/MM/YYYY')}</p>
            </Modal>
        )
    }

    renderModalMissing = () => {
        // const {miniGame} = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalMissing}
                onCancel={() => this.setState({ showModalMissing: false })}
                centered
            >
                <div className='item-center'>
                    <img
                        className='minigame-fail-img'
                        src={require('../../assets/image/minigame-fail.png')}
                    />
                </div>
                <p className='text-large text-center mgt-3'>Ngủ quên rồi</p>
            </Modal>
        )
    }

    renderPlaySuccess = () => {
        const {qoute, countRankCurrent} = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalPlaySuccess}
                onCancel={() => this.setState({ showModalPlaySuccess: false })}
                width='750px'
                centered
            >
                <Lottie options={{ loop: true, autoplay: true, animationData: win }}
                    height='30vh'
                    width='30vh'
                />
                <p className='mgt qoute-gift'>{qoute?.QouteContent}</p>
                <p className='mgt qoute-gift mgt text-right'>{qoute?.Author}</p>
                <p className='mgt-4 text-center bold text-large'>Xin chúc mừng! Thứ hạng của bạn hôm nay là {zeroPad(countRankCurrent)}.</p>
                <p className='mgt-2 text-center bold text-large'>Ngày cuối cùng, ai nhanh nhất sẽ là người chiến thắng!</p>
            </Modal>
        )
    }

    renderModalRule = () => {
        const { showModalRule, miniGame } = this.state
        return (
            <Modal
                {...PROP.MODAL}
                visible={showModalRule}
                onCancel={() => this.setState({ showModalRule: false })}
                centered
                width='700px'
            >
                <TitleSuccess>THỂ LỆ MINI GAME</TitleSuccess>
                <p className='mgt-4 text-large'>BƯỚC 1: Bấm “Đăng ký chơi” để hệ thống đưa vào danh sách tính điểm, xếp hạng.</p>
                <p className='mgt-4 text-large'>BƯỚC 2: Người chơi điểm danh dậy sớm {miniGame.TotalDay} ngày liên tục sẽ có cơ hội chiến thắng.</p>
                <p className='mgt-2 mgl-2 text-large'>• Thời gian điểm danh trong 15 phút: 6:00 - 6:15</p>
                <p className='mgt-2 mgl-2 text-large'>• Trả lời đúng 4 câu hỏi NGOẠI NGỮ để điểm danh thành công.</p>
                <p className='mgt-2 mgl-2 text-large'>• Người chơi trả lời sai có thể trả lời lại trong phạm vi 15 phút.</p>
                <p className='mgt-4 text-large'>BƯỚC 3: Người chiến thắng là:</p>
                <p className='mgt-2 mgl-2 text-large'>• Điểm danh thành công {miniGame.TotalDay}/{miniGame.TotalDay} ngày liên tục.</p>
                <p className='mgt-2 mgl-2 text-large'>• TOP điểm danh sớm nhất trong ngày cuối cùng của vòng chơi.</p>
                <p className='mgt-2 mgl-2 text-large'>• Bảng xếp hạng sẽ là căn cứ trao thưởng.</p>
                <p className='mgt-4 text-gigantic bold'>Phần thưởng hàng tuần</p>
                <p className='mgt-2 text-large'>• TOP 1: 200.000 Đ</p>
                <p className='mgt-2 text-large'>• TOP 2: 150.000 Đ</p>
                <p className='mgt-2 text-large'>• TOP 3: 100.000 Đ</p>
                <p className='mgt-2 text-large'>• TOP 4: 50.000 Đ</p>
                <p className='mgt-2 text-large'>Giải thưởng được tài trợ bởi thầy Nguyễn Minh Ngọc ™</p>
            </Modal>
        )
    }

    renderModalRegSuccess = () => {
        const { showModalRegSuccess, miniGame } = this.state
        return (
            <Modal
                {...PROP.MODAL}
                visible={showModalRegSuccess}
                onCancel={() => this.setState({ showModalRegSuccess: false })}
                centered
            >
                <div className='item-center'>
                    <Lottie options={{ loop: true, autoplay: true, animationData: firework }}
                        height='30vh'
                        width='30vh'
                    />
                    <p className='text-large text-center mgt-2'>Chúc mừng bạn đã đăng ký thành công minigame lần {miniGame.Round}</p>
                    <p className='text-large text-center mgt-2'>Thi đấu từ {moment(miniGame.StartDate).format('DD/MM/YYYY')} - {moment(miniGame.EndDate).format('DD/MM/YYYY')}</p>
                </div>
            </Modal>
        )
    }

    playMiniGame = () => {
        const { miniGame } = this.state;
        if (moment().isBefore(moment(miniGame.StartDate))) {
            this.setState({ showModalWaiting: true })
        }
        else {
            playMiniGameApi().then(res => {
                if (res.code == 1) {
                    this.numberQuestion = res.numberQuestion;
                    this.timeDiff = res.timeDiff;
                    this.countDown = res.countDown;
                    this.setState({
                        isLoading: false,
                        questionList: res.questionList,
                        resultAnswer: false,
                        selectAnswerKey: '',
                        isAnswer: false,
                        showModalQuestion: true
                    });
                    this.questionInterval = setInterval(() => {
                        this.timeDiff -= 1;
                        if (this.timeDiff < 10) this.setState({ textTime: '00:0' + this.timeDiff });
                        else this.setState({ textTime: '00:' + this.timeDiff });
                        if (this.timeDiff <= 0) {
                            let { isAnswer, resultAnswer } = this.state;
                            if (isAnswer == true && resultAnswer == 1) {
                                this.timeDiff = 30;
                                this.numberQuestion += 1;
                                if (this.numberQuestion > 4) {
                                    clearInterval(this.questionInterval);
                                }
                                this.correctAnswer = '';
                                this.setState({
                                    resultAnswer: false,
                                    selectAnswerKey: '',
                                    isAnswer: false,
                                    textTime: '--:--'
                                });
                            }
                            else {
                                this.setState({
                                    resultAnswer: false,
                                    selectAnswerKey: '',
                                    isAnswer: false,
                                    showModal: false,
                                    textTime: '--:--'
                                });
                                clearInterval(this.questionInterval);
                                this.hideModalQuestion();
                            }
                        }
                    }, 1000);
                }
                else {
                    if(res.code == -2){
                        this.setState({showModalMissing: true})
                    }
                    else showNotification(res.message, res.code)
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                clearInterval(this.questionInterval);
            })
        }
    }

    registerMiniGame = () => {
        if (!this.state.isPress) {
            this.setState({isPress: true})
            registerMiniGameApi().then(res => {
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({
                        showModalRegSuccess: true,
                        miniGame: {
                            ...this.state.miniGame,
                            MiniGameRegisterId: res.MiniGameRegisterId
                        }
                    })
                }
                else showNotification(res.message, res.code);
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    getInfoMiniGame = () => {
        getInfoMiniGameApi().then(res => {
            if (res.code == 1) {
                this.setState({ miniGame: res.miniGame })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    hideModalQuestion = () => {
        this.setState({
            showModalQuestion: false,
            resultAnswer: false,
            selectAnswerKey: '',
            isAnswer: false,
            textTime: '--:--'
        });
        clearInterval(this.questionInterval);
    }

    renderContentQuestion = () => {
        let { questionList, selectAnswerKey, resultAnswer, textTime } = this.state;
        if (this.timeDiff >= 0) {
            return (
                <div>
                    <Lottie options={{ loop: false, autoplay: true, animationData: gift }}
                        height='16vh'
                        width='16vh'
                    />
                    {questionList.map((item, index) => (
                        <div key={index}>
                            {index + 1 == this.numberQuestion && (
                                <div className='animated fadeIn fast'>
                                    <p className='text-large text-center mgb'>Vượt qua 4 câu hỏi để điểm danh</p>
                                    <p className='title-question'>Câu hỏi {this.numberQuestion}: {item.QuestionTitle}</p>
                                    <div className='count-down-question-container'>
                                        <p className={'count-down-question ' + (this.timeDiff <= 10 ? 'danger' : 'success')}>{textTime}</p>
                                    </div>
                                    {item.answers.map((value, key) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => this.saveAnswer(value, key, item.QuestionId)}
                                            >
                                                <TextAnswer
                                                    answer={value}
                                                    keySelect={key}
                                                    selectAnswerKey={selectAnswerKey}
                                                    correctAnswer={this.correctAnswer}
                                                />
                                            </div>
                                        )
                                    })}
                                    {resultAnswer !== false && (
                                        <ResultMessage resultAnswer={resultAnswer} />
                                    )}
                                    <div className='notificationQuestionCheck'></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )
        }
        return (
            <Loading small />
        )
    }

    saveAnswer = (answer, keySelect, questionId) => {
        if (!this.state.isAnswer) {
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            let idNotification = '#notificationQuestionCheck';
            checkanswerApi(questionId, answer).then(res => {
                if (res.code == 1 || res.code == 0) {
                    if (res.isDone) {
                        clearInterval(this.questionInterval);
                        this.setState({
                            showModalQuestion: false,
                            showModalPlaySuccess: true,
                            miniGame: {
                                ...this.state.miniGame,
                                IsPlayed: "1",
                                TotalDayPlayed: this.state.miniGame.TotalDayPlayed + 1,
                                ListPlayed: [
                                    ...this.state.miniGame.ListPlayed,
                                    {
                                        CrDateTime: moment().format('YYYY-MM-DD HH:mm:ss')
                                    }
                                ]
                            },
                            qoute: res.dataSuccess?.Qoute,
                            countRankCurrent: res.dataSuccess?.CountRank
                        })
                    }
                    else {
                        this.timeDiff = 3;
                        this.correctAnswer = res.answer;
                        this.setState({
                            resultAnswer: res.code,
                        });
                        setTimeout(() => {
                            speakEng(res.answer)
                        }, 0)
                    }
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.correctAnswer = '';
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(error => {
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    renderModalQuestion = () => {
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalQuestion}
                onCancel={this.hideModalQuestion}
                destroyOnClose={true}
                width='750px'
                centered
            >
                {this.renderContentQuestion()}
            </Modal>
        )
    }

    renderModalRank = () => {
        const { listRank, miniGame, miniGameRankSelect } = this.state;
        const { dataUser } = this.props;
        const indexUser = listRank.findIndex(i => i.UserId == dataUser.UserId)
        
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalRank}
                onCancel={() => this.setState({ showModalRank: false })}
                width='750px'
                centered
            >
                <div className='row mgt-2 mgb-2'>
                    <div className='col-md-3 col-sm-12 col-xs-12'></div>
                    <div className='col-md-6 col-sm-12 col-xs-12 flex item-center'>
                        <TitleSuccess>Bảng xếp hạng</TitleSuccess>
                    </div>
                    <div className='col-md-3 col-sm-12 col-xs-12 flex item-center'>
                        <select
                            className='minigame-round-select'
                            onChange={e => this.getListRank(e.target.value)}
                            value={miniGameRankSelect}
                        >
                            {miniGame?.Rounds?.map(item =>
                                <option value={item.MiniGameId} key={item.MiniGameId}>Lần {item.Round}</option>
                            )}
                        </select>
                    </div>
                </div>
                {indexUser >= 0 &&
                    this.renderRankItem(listRank[indexUser], indexUser)
                }
                <div style={{height: '4vh'}} />
                {listRank.map(this.renderRankItem)}
            </Modal>
        )
    }

    renderRankItem = ((item, key) => {
        const { miniGame } = this.state;
        return (
            <div key={key} className='rank-container item-center'>
                <div className='col-rank-stt'>
                    <p style={{ fontSize: this.fontSizeRank(key + 1) }}>{key + 1}</p>
                    <div>
                        <img
                            style={{ width: '2vh', position: 'relative', bottom: '2vh' }}
                            src={require('../../assets/image/rank.png')}
                            alt='img-rank'
                        />
                    </div>
                </div>
                <div className='col-rank-avatar'>
                    <div style={{ position: 'relative' }}>
                        <TooltipUser
                            userInfo={item}
                        />
                        {item.AssetImage && (
                            <img
                                className='avatar-asset-rank'
                                alt='asset-user'
                                src={`${URI_PATH}${item.AssetImage}`}
                                style={{ marginLeft: '-5.5vh' }}
                            />
                        )}
                    </div>
                </div>
                <div className='col-rank-name'>
                    <div className='row item-center'>
                        <ClubDeputy clubRoleId={item.ClubRoleId} />
                        <p>{item.ClubName}</p>
                    </div>
                    <div className='level-rank'>
                        <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                    </div>
                    <p className='bold'>{item.FullName}</p>
                    <p>{item.ClubRank}</p>
                </div>
                <div className='col-rank-info flex-column item-center'>
                    <p className='text-progress-rank'>{item.CountPlayed}/{miniGame.TotalDay}</p>
                </div>
            </div>
        )
    })

    getListRank = (miniGameId = this.state.miniGame.MiniGameId) => {
        this.setState({miniGameRankSelect: miniGameId})
        getListRankApi(miniGameId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listRank: res.listRank,
                    isLoadingRank: false,
                })
            }
            else showNotification(MESSAGE.ERROR, -1);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    fontSizeRank = (rank) => {
        if (0 < rank && rank <= 9) return '6vh';
        else if (9 < rank && rank <= 99) return '5vh';
        else if (100 < rank && rank <= 999) return '4vh';
        else return '20';
    }

    componentDidMount() {
        this.getInfoMiniGame();
    }

    componentWillUnmount() {
        clearInterval(this.questionInterval);
    }

    render() {
        return this.renderMiniGame();
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, getUserPresent, updateCountMission, showModalEat })(MiniGame);