import React from 'react';
import { Component } from 'react';
import Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toogleUserBar } from '../../actions/userbar';
import {
    showHideChat,
    getListChatAll
} from '../../actions/chat';
import { getTimeGuideAction } from '../../actions/menu'
import { showHideSearch } from '../../actions/search'
import { updateStateNavigate } from '../../actions/menu';
import { getYoutubeTheoryApi } from '../../api/theory';
import { getYoutubeId } from '../../utils/common';

const thories = [
    {
        descTitle: 'Có 3 loại tiền giả lập trong Zcity',
        desc: 'Độ hiếm tăng dần, tiền thưởng được nhận thông qua các hoạt động chơi. Nhiều tiền mua đồ tăng thành tựu, tăng VIP.',
        isShowListButton: true,
        buttons: [
            { title: 'đ[z] - đồng z' },
            { title: '$[z] - đô z' },
            { title: 'Gold[z] - Vàng Z' },
        ],
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Có 3 thành tích được tích luỹ',
        desc: 'Điểm tăng dần và tích luỹ thông qua các hoạt động chơi. Nhiều thành tựu, nhiều thương hiệu, nhiều VIP nhận được nhiều tiền thụ động.',
        isShowListButton: true,
        buttons: [
            { title: 'Thành tựu', image: require('../../assets/image/achievement.png') },
            { title: 'Thương hiệu', image: require('../../assets/image/like.png') },
            { title: 'Cấp độ', image: require('../../assets/image/vip.png') },
        ],
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Hoạt động Siêu Trí Nhớ, Siêu Từ Vựng',
        desc: 'Trả lời đúng nhận tiền thưởng đ[z] luỹ tiến theo công thức: Số VIP x Số câu hỏi đúng cho đến khi đạt phần thưởng tối đa 300.000 đz/câu. Trả lời sai không mất tiền. BXH tính trong ngày.',
        isShowListButton: false,
        image: require('../../assets/image/menu/question-lobby.png'),
        imageDesc: 'Kiếm tiền đ[z]',
        action: '/menu/question-lobby'
    },
    {
        descTitle: 'Hoạt động Toán Khởi Nghiệp',
        desc: 'Trả lời đúng nhận tiền thưởng bằng số tiền đã cược, trả lời sai mất số tiền cược.',
        isShowListButton: false,
        image: require('../../assets/image/menu/question-train.png'),
        imageDesc: 'Kiếm tiền đ[z] hoặc $[z]',
        action: '/menu/gift-train'
    },
    {
        descTitle: 'Hoạt động Đầu tư Chứng Khoán',
        desc: 'Thời gian diễn ra 9h sáng đến 3h chiều. Khớp lệnh giá mỗi 60 giây, biên độ dao động phạm vi +-500. Cổ tức trả theo ngày. Cổ phiếu được đặt trong Tài sản/Két sắt. Cổ phiếu về giá dưới 10.000 sẽ bị huỷ niêm yết, tái phát hành cổ phiếu mới. Cổ phiếu phá sản, huỷ niêm yết được định giá còn 100 đ[z] hoặc 100 $[z] /cổ phiếu.',
        isShowListButton: false,
        image: require('../../assets/image/menu/charge-invest.png'),
        imageDesc: 'Kiếm tiền đ[z] hoặc $[z]',
        action: '/menu/charge-invest'
    },
    {
        descTitle: 'Hoạt động Quà dậy sớm',
        desc: 'Người dùng được đặt lịch mở từ 4h-8h sáng tuỳ thói quen sinh hoạt. Thời gian mở tối đa 15 phút. Trả lời đúng 4 câu hỏi tiếng anh để nhận quà. Mức thưởng tăng dần theo level mở liên tục các ngày, bỏ lỡ ngày sẽ reset lại số ngày về 0 của level đó.',
        isShowListButton: false,
        image: require('../../assets/image/menu/gift-day.png'),
        imageDesc: 'Kiếm tiền $[z]',
        action: '/menu/gift-day'
    },
    {
        descTitle: 'Hoạt động Quà phát vốn',
        desc: 'Thời gian mở quà: 6h00 - 8h00. Nhận thưởng 50.000 đ[z] ~ 100.000 đ[z].',
        isShowListButton: false,
        image: require('../../assets/image/lixi.png'),
        imageDesc: 'Kiếm tiền $[z]',
        action: '/menu/gift-day'
    },
    {
        descTitle: 'Hoạt động Quà tăng trưởng',
        desc: 'Đầu tư $[z] mua gói quà để mở hằng ngày kiếm nhiều $[z] hơn.',
        isShowListButton: false,
        image: require('../../assets/image/menu/gift-effort.png'),
        imageDesc: 'Kiếm tiền $[z]',
        action: '/menu/gift-effort'
    },
    {
        descTitle: 'Hoạt động Sự kiện ngoại ngữ',
        desc: '2 tiếng diễn ra 1 lần, ở các khung giờ chẵn + 30 phút ví dụ 8h30 10h30 12h30,... Trả lời câu hỏi tiếng anh nhận thưởng đ[z]. Trả lời sai không mất tiền. Được tích luỹ thành tích.',
        isShowListButton: false,
        image: require('../../assets/image/menu/question-event.png'),
        imageDesc: 'Kiếm tiền $[z]',
        action: '/menu/question-event'
    },
    {
        descTitle: 'Hoạt động Thả tim',
        desc: 'Diễn ra vào lúc 20h30 - 20h45. Bày tỏ yêu thích với 5 người khác để cả 2 nhận phần thưởng.',
        isShowListButton: false,
        image: require('../../assets/image/like.png'),
        imageDesc: 'Kiếm thương hiệu',
        action: '/menu/brand-online'
    },
    {
        descTitle: 'Hoạt động Quà thần bí',
        desc: 'Từ VIP 1 trở lên trải nghiệm cảm giác thử vận may từ 20:45 - 21:00 khi biến tiền đ[z] thành tiền $[z]. Được quay nhiều lần cho tới khi thành công. Tỷ lệ thành công 50%. Có thể cho nguyên liệu đồ ăn thừa vào để tăng % thành công.',
        isShowListButton: false,
        image: require('../../assets/image/menu/gift-spin.png'),
        imageDesc: 'Kiếm $[z]',
        action: '/menu/gift-spin'
    },
    {
        descTitle: 'Hoạt động Phá phong ấn',
        desc: 'Diễn ra từ 21h15 - 21h30. Cả thành phố cùng phá phong ấn. Người may mắn tấn công đầu tiên, tấn công cuối cùng hoặc lượng tấn công nhiều nhất sẽ may mắn nhận được phần thưởng là 1 Gold[z].',
        isShowListButton: false,
        image: require('../../assets/image/menu/gift-ticket.png'),
        imageDesc: 'Kiếm Gold[z]',
        action: '/menu/gift-ticket'
    },
    {
        descTitle: 'Hoạt động Đấu thầu Thị Trưởng',
        desc: '5 người trả giá cao nhất sẽ trở thành hội đồng thị trưởng ngày hôm sau, người đấu thầu may mắn ngẫu nhiên trong 5 người sẽ giành phần thưởng 1 Gold[z]. Mức bỏ thầu tối thiểu là 100.000 $[z].',
        isShowListButton: false,
        image: require('../../assets/image/menu/brand-online.png'),
        imageDesc: 'Kiếm Gold[z]',
        action: '/menu/brand-online'
    },
    {
        descTitle: 'Hoạt động Huy Chương Z',
        desc: 'Hoàn tất nhiệm vụ trong ngày sẽ đạt Huy Chương Z cùng phần thưởng 1000 thành tựu. 1 người có thể nhận không giới hạn số lượng huy chương Z.',
        isShowListButton: false,
        image: require('../../assets/image/crest.png'),
        imageDesc: 'Kiếm 1000 thành tựu',
        action: '/menu',
        actionMenuTab: 3
    },
    {
        descTitle: 'Hoạt động Mua sắm tài sản thiết yếu',
        desc: 'Tài sản được đặt trong phòng ngủ, mỗi tài sản có hạn sử dụng, mua số lượng nhiều cho 1 loại tài sản sẽ chỉ tăng thời gian hạn sử dụng, không tăng số lượng. Mua toàn bộ đầu đủ tài sản thiết yếu sẽ đem lại thành tựu thụ động mỗi ngày. Tối đa 198.95 thành tựu/ngày.',
        isShowListButton: false,
        image: require('../../assets/image/bed.png'),
        imageDesc: 'Kiếm thành tựu thụ động',
        action: '/menu/charge-market'
    },
    {
        descTitle: 'Hoạt động Mua sắm tài sản Cao cấp',
        desc: 'Tài sản được đặt trong Phòng khách, tài sản cũ đi theo thời gian. Mua tài sản cao cấp đem lại thành tựu thụ động mỗi ngày. Thành tựu thụ động đạt được nhiều nhất khi lượng tài sản ở gần tới các mốc tròn tỷ. Duy trì tài sản ở các mốc 999 triệu, 1 tỷ 999 triệu,... sẽ đem lại lợi ích cao nhất.',
        isShowListButton: false,
        image: require('../../assets/image/moto.png'),
        imageDesc: 'Kiếm thành tựu thụ động',
        action: '/menu/charge-market',
        queryAction: { selectItem: 1 }
    },
    {
        descTitle: 'Hoạt động Nấu ăn',
        desc: 'Tại nhà bếp bạn có thể nấu ra các món ăn để ăn và mở tiệc mời mọi người đến nhà mình ăn để cả 2 cùng nhận được điểm thương hiệu. Hoạt động kết hợp nguyên liệu có thể may mắn tạo ra linh thú có giá trị cao.',
        isShowListButton: false,
        image: require('../../assets/image/rau.png'),
        imageDesc: 'Nấu ăn',
        action: '/home'
    },
    {
        descTitle: 'Hoạt động Xây nhà',
        desc: 'Trong quá trình chơi bạn sẽ nhận được các nguyên liệu cất tại nhà kho. Nhà có tác dụng tăng tổng thể Thành tựu theo %.',
        isShowListButton: false,
        image: require('../../assets/image/xi-mang.png'),
        imageDesc: 'Xây nhà',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Xây nhà Đời 1',
        desc: 'Yêu cầu: 1000 gạch + 100 đá + 10 bao xi măng + 1 tỷ đ[z] + 1 triệu $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ mất tất cả.',
        isShowListButton: false,
        image: require('../../assets/image/house1.png'),
        imageDesc: 'Tăng 10% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Xây nhà Đời 2',
        desc: 'Yêu cầu: Nhà đời 1 + 2000 gạch + 200 đá + 20 bao xi măng + 2 tỷ đ[z] + 2 triệu $[z] + 5 Gold[z] + linh thú. Thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 30%. Thất bại sẽ còn lại nhà đời 1.',
        isShowListButton: false,
        image: require('../../assets/image/house2.png'),
        imageDesc: 'Tăng 20% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Xây nhà Đời 3',
        desc: 'Yêu cầu: Nhà đời 2 + 3000 gạch + 300 đá + 30 bao xi măng + 3 tỷ đ[z] + 3 triệu $[z] + 10 Gold[z] + linh thú. Thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 30%. Thất bại sẽ còn lại nhà đời 2.',
        isShowListButton: false,
        image: require('../../assets/image/house3.png'),
        imageDesc: 'Tăng 30% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Xây nhà Đời 4',
        desc: 'Yêu cầu: Yêu cầu: Nhà đời 3 + 4000 gạch + 400 đá + 40 bao xi măng + 4 tỷ đ[z] + 4 triệu $ [z] + 15 Gold[z] + linh thú. Thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 30%. Thất bại sẽ còn lại đời 3.',
        isShowListButton: false,
        image: require('../../assets/image/house4.png'),
        imageDesc: 'Tăng 40% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Xây nhà Đời 5',
        desc: 'Yêu cầu: Nhà đời 4 + 5000 gạch + 500 đá + 50 bao xi măng + 5 tỷ đ[z] + 5 triệu $[z] + 20 Gold[z] + linh thú. Thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 30%. Thất bại sẽ còn lại nhà đời 4.',
        isShowListButton: false,
        image: require('../../assets/image/house5.png'),
        imageDesc: 'Tăng 50% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc Vương Miện',
        desc: 'Trong quá trình chơi bạn sẽ nhận được các nguyên liệu cất tại nhà kho. Vương miện có tác dụng tăng tổng thể thành tựu theo %. Vương miện sắt, đồng, bạc, vàng, kim cương.',
        isShowListButton: false,
        image: require('../../assets/image/asset-raw.png'),
        imageDesc: 'Tạo Vương Miện',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc vương miện Sắt',
        desc: 'Yêu cầu: 500 mảnh sắt + 10 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ mất tất cả.',
        isShowListButton: false,
        image: require('../../assets/image/s.png'),
        imageDesc: 'Tăng 5% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đính ngọc vào vương miện',
        desc: 'Vương miện + 1 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Đính từ +1 đến +6 tỷ lệ thành công 100%; từ+7\đến +10 tỷ lệ 70%. Thêm tối đa 5 loại linh thú để tăng 1%/con, tối đa 2%/loài gia tăng tỷ lệ tối đa 80%. Thất bại về +0.',
        isShowListButton: false,
        image: require('../../assets/image/s.png'),
        imageDesc: 'Mỗi viên ngọc tăng 1% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc vương miện Đồng',
        desc: 'Yêu cầu: Vương miện Sắt + 10 + 500 mảnh sắt + 20 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại về Vương miện Sắt + 0.',
        isShowListButton: false,
        image: require('../../assets/image/d.png'),
        imageDesc: 'Tăng 20% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc vương miện Bạc',
        desc: 'Yêu cầu: Vương miện Đồng + 10 + 500 Đồng + 40 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại về Vương miện Đồng + 0.',
        isShowListButton: false,
        image: require('../../assets/image/b.png'),
        imageDesc: 'Tăng 35% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc vương miện Vàng',
        desc: 'Yêu cầu: Vương miện Bạc + 10 + 500 Vàng + 80 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại về Vương miện Bạc + 0.',
        isShowListButton: false,
        image: require('../../assets/image/v.png'),
        imageDesc: 'Tăng 50% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Đúc vương miện Kim Cương',
        desc: 'Yêu cầu: Vương miện Vàng + 10 + 500 Kim cương + 160 ngọc nguyên tố + 1 khuôn + 1,000,000 đ[z] + 100.000 $[z] + 1 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại về Vương miện Vàng + 0.',
        isShowListButton: false,
        image: require('../../assets/image/kc.png'),
        imageDesc: 'Tăng 65% thành tựu',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Hoạt động Quân hàm',
        desc: 'Tham gia vào các Câu lạc bộ, ủng hộ quỹ thăng hạng Club để được tăng quân hàm.',
        isShowListButton: false,
        image: require('../../assets/image/army.png'),
        imageDesc: 'Kiếm đ[z] thụ động',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Hoạt động Vòng tròn học tập',
        desc: 'Kết giao giao lưu nhóm với nhau trong vòng liên kết mối quan hệ để tạo ra nguồn thu nhập $ [z] và thành tựu thụ động từ hoạt động của chính những thành viên đội nhóm của mình. Nhận thụ động tối đa lên tới 157 người.',
        isShowListButton: false,
        image: require('../../assets/image/menu/charge-affiliate.png'),
        imageDesc: 'Kiếm đ[z] thụ động',
        action: '/menu/charge-affiliate'
    },
    {
        descTitle: 'Hoạt động ghép nhóm thành tựu',
        desc: 'Ghép nhóm với những người có VIP cao sẽ giúp nhau tăng phần thưởng thành tựu trong các hoạt động chơi.',
        isShowListButton: false,
        image: require('../../assets/image/menu/brand-group.png'),
        imageDesc: 'Tăng thành tựu khi hoạt động',
        action: '/menu/brand-group'
    },
    {
        descTitle: 'Hoạt động Tặng quà',
        desc: 'Trong những dịp đặc biệt, có thể gửi tặng quà cho những người chơi khác.',
        isShowListButton: false,
        image: require('../../assets/image/menu/gift-offer.png'),
        imageDesc: 'Gửi tặng quà công khai',
        action: '/menu/gift-offer'
    },
    {
        descTitle: 'Hoạt động rao bán đồ trên chợ',
        desc: 'Người chơi có thể thuê cửa hàng và rao bán những đồ mình có trên chợ cho những người khác.',
        isShowListButton: false,
        image: require('../../assets/image/menu/charge-sell.png'),
        imageDesc: 'Trao đổi hàng hoá',
        action: '/menu/charge-sell'
    },
    {
        descTitle: 'Nhận tiền thụ động mỗi ngày từ thành tựu/ VIP/thương hiệu/Quân hàm',
        desc: 'Nhận thành tựu/ VIP/thương hiệu/Quân hàm mỗi ngày',
        isShowListButton: false,
        image: require('../../assets/image/all-property.png'),
        imageDesc: 'Nhận lương mỗi ngày',
        action: '/menu/vestige',
        actionMenuTab: 4

    },
    {
        descTitle: 'Hoạt động đấu trường',
        desc: 'Tạo phòng thi đấu nhận thưởng theo cược của phòng đấu, tổ chức giải thi đấu tự do giữa các cá nhân, hoặc thi đấu theo các đội, có hoạt động tặng quà từ khán giả xem.',
        isShowListButton: false,
        image: require('../../assets/image/sword-vs.png'),
        imageDesc: 'So tài',
        action: '/question-fight',
        actionMenuTab: 1
    },
    {
        descTitle: 'Hoạt động xếp hạng phú hộ',
        desc: 'Xếp hạng những người có nhà, có vương miện, có phòng khách giàu nhất, có Gold[z]',
        isShowListButton: false,
        image: require('../../assets/image/menu/brand-top.png'),
        imageDesc: 'Bảng xếp hạng',
        action: '/menu/brand-top'
    },
    {
        descTitle: 'Hoạt động chuyển nhượng',
        desc: 'Người chơi có thể chuyển nhượng các loại hình tiền tệ cho nhau.',
        isShowListButton: false,
        image: require('../../assets/image/menu/charge.png'),
        imageDesc: 'Chuyển nhượng',
        action: '/menu/charge'
    },
    {
        descTitle: 'Hoạt động Tiệm kim hoàn',
        desc: 'Người chơi có thể mua nguyên liệu còn thiếu khi cần gấp nếu như không mua được của người khác.',
        isShowListButton: false,
        image: require('../../assets/image/menu/charge-asset-market.png'),
        imageDesc: 'Tiệm Kim hoàn',
        action: '/menu/charge-asset-market'
    },
    {
        descTitle: 'Hoạt động Phát lộc Club',
        desc: 'Khi người chơi trong cùng Club mở quà thần bí thành công sẽ phát lộc cho các thành viên trong Club.',
        isShowListButton: false,
        image: require('../../assets/image/menu/club-fortune.png'),
        imageDesc: 'Kiếm đ[z] hoặc $[z]',
        action: '/menu/club-fortune'
    },
    {
        descTitle: 'Hoạt động Giao lưu',
        desc: 'Từ 20:00- 21:00 Người chơi có thể mời ăn những thành viên trong Club để cùng được hưởng thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/menu/club-feast.png'),
        imageDesc: 'Mời ăn cá nhân',
        action: '/menu/club-feast'
    },
    {
        descTitle: 'Hoạt động Đăng trạng thái',
        desc: 'Người chơi đăng các trạng thái dưới dạng ngắn trả lời câu hỏi: Hôm nay bạn học được điều gì?',
        isShowListButton: false,
        image: require('../../assets/image/status.png'),
        imageDesc: 'Đăng trạng thái',
        action: '/new-feed'
    },
    {
        descTitle: 'Hoạt động Chat toàn thành phố',
        desc: 'Người chơi có thể đăng tin rao bán trên kênh chat toàn thành phố với chi phí bằng VIP x 1000 $[z].',
        isShowListButton: false,
        image: require('../../assets/image/chat.png'),
        imageDesc: 'Đăng tin rao bán',
        otherAction: true,
        action: 'chat'
    },
    {
        descTitle: 'Truy cập menu nhanh',
        desc: 'Người chơi có thể truy cập nhanh vào menu thông qua tính năng tìm kiếm, trượt màn hình sang phải đối với app.',
        isShowListButton: false,
        image: require('../../assets/image/search-icon.png'),
        imageDesc: 'Truy cập nhanh',
        otherAction: true,
        action: 'search'
    },
    {
        descTitle: 'Cấp độ VIP 1',
        desc: 'Yêu cầu: Là thành viên trong một Club và 1000 thành tựu. Thưởng thêm 1% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip1.png'),
        imageDesc: 'Tăng 1% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 2',
        desc: 'Yêu cầu: Là thành viên trong một Club và 10.000 thành tựu. Thưởng thêm 2% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip2.png'),
        imageDesc: 'Tăng 2% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 3',
        desc: 'Yêu cầu: Là thành viên trong một Club và 100.000 thành tựu. Thưởng thêm 3% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip3.png'),
        imageDesc: 'Tăng 3% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 4',
        desc: 'Yêu cầu: Là thành viên trong một Club và 200.000 thành tựu. Thưởng thêm 4% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip4.png'),
        imageDesc: 'Tăng 4% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 5',
        desc: 'Yêu cầu: Là thành viên trong một Club và 350.000 thành tựu. Thưởng thêm 5% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip5.png'),
        imageDesc: 'Tăng 5% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 6',
        desc: 'Yêu cầu: Là thành viên trong một Club và 650.000 thành tựu. Thưởng thêm 6% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip6.png'),
        imageDesc: 'Tăng 6% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 7',
        desc: 'Yêu cầu: Là thành viên trong một Club và 1.250.000 thành tựu. Thưởng thêm 7% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip7.png'),
        imageDesc: 'Tăng 7% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 8',
        desc: 'Yêu cầu: Là thành viên trong một Club và 2.400.000 thành tựu. Thưởng thêm 8% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip8.png'),
        imageDesc: 'Tăng 8% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 9',
        desc: 'Yêu cầu: Là thành viên trong một Club và 3.500.000 thành tựu. Thưởng thêm 9% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip9.png'),
        imageDesc: 'Tăng 9% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ VIP 10',
        desc: 'Yêu cầu: Là thành viên trong một Club và 10.000.000 thành tựu. Thưởng thêm 10% doanh thu Affiliate với thành viên Z VIP AFF.',
        isShowListButton: false,
        image: require('../../assets/image/vip10.png'),
        imageDesc: 'Tăng 10% doanh thu Affiliate',
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Quân hàm Binh nhì',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 0 - 5.000.000 đ[z].',
        isShowListButton: false,
        text: 'Binh nhì',
        imageDesc: 'Thu nhập VIP x 100.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Binh nhất',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 5.000.000 - 10.000.000 đ[z].',
        isShowListButton: false,
        text: 'Binh nhất',
        imageDesc: 'Thu nhập VIP x 200.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Hạ sĩ',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 10.000.000 - 25.000.000 đ[z].',
        isShowListButton: false,
        text: 'Hạ sĩ',
        imageDesc: 'Thu nhập VIP x 300.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Trung sĩ',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 25.000.000 - 52.500.000 đ[z].',
        isShowListButton: false,
        text: 'Trung sĩ',
        imageDesc: 'Thu nhập VIP x 400.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thượng sĩ',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 52.500.000 - 95.000.000 đ[z].',
        isShowListButton: false,
        text: 'Thượng sĩ',
        imageDesc: 'Thu nhập VIP x 500.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thiếu uý',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 95.000.000 - 155.000.000 đ[z].',
        isShowListButton: false,
        text: 'Thiếu uý',
        imageDesc: 'Thu nhập VIP x 600.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Trung uý',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 155.000.000 - 235.000.000 đ[z].',
        isShowListButton: false,
        text: 'Trung uý',
        imageDesc: 'Thu nhập VIP x 700.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thượng uý',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 235.000.000 - 337.500.000 đ[z].',
        isShowListButton: false,
        text: 'Thượng uý',
        imageDesc: 'Thu nhập VIP x 800.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Đại uý',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 337.500.000 - 465.000.000 đ[z].',
        isShowListButton: false,
        text: 'Đại uý',
        imageDesc: 'Thu nhập VIP x 900.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thiếu tá',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 465.000.000 - 620.000.000 đ[z].',
        isShowListButton: false,
        text: 'Thiếu tá',
        imageDesc: 'Thu nhập VIP x 1.000.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Trung tá',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 620.000.000 - 805.000.000 đ[z].',
        isShowListButton: false,
        text: 'Trung tá',
        imageDesc: 'Thu nhập VIP x 1.100.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thượng tá',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 805.000.000 - 1.022.500.000 đ[z].',
        isShowListButton: false,
        text: 'Thượng tá',
        imageDesc: 'Thu nhập VIP x 1.200.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Đại tá',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 1.022.500.000 - 1.275.000.000 đ[z].',
        isShowListButton: false,
        text: 'Đại tá',
        imageDesc: 'Thu nhập VIP x 1.300.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thiếu tướng',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 1.275.000.000 - 1.565.000.000 đ[z].',
        isShowListButton: false,
        text: 'Thiếu tướng',
        imageDesc: 'Thu nhập VIP x 1.400.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Trung tướng',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 1.565.000.000 - 1.895.000.000 đ[z].',
        isShowListButton: false,
        text: 'Trung tướng',
        imageDesc: 'Thu nhập VIP x 1.500.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Thượng tướng',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 1.895.000.000 - 2.267.500.000 đ[z].',
        isShowListButton: false,
        text: 'Thượng tướng',
        imageDesc: 'Thu nhập VIP x 1.600.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Quân hàm Đại tướng',
        desc: 'Yêu cầu: Đóng góp quỹ thành viên 2.267.500.000 - 10.000.000.000 đ[z].',
        isShowListButton: false,
        text: 'Đại tướng',
        imageDesc: 'Thu nhập VIP x 1.700.000 đ[z]/ngày.',
        action: '/menu/club-level'
    },
    {
        descTitle: 'Lệnh bài Nam Tước',
        desc: 'Yêu cầu: Vương miện kim cương + 10 + Nhà cấp 5 + 10 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ mất tất cả.',
        isShowListButton: false,
        image: require('../../assets/image/lbnt.png'),
        imageDesc: 'Tăng 100% thành tựu \n Tăng 10% Damage X4',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Lệnh bài Tử Tước',
        desc: 'Yêu cầu: Lệnh bài Nam Tước + Vương miện kim cương + 10 + Nhà cấp 5 + 50 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ về Lệnh Bài Nam Tước.',
        isShowListButton: false,
        image: require('../../assets/image/lbtt.png'),
        imageDesc: 'Tăng 200% thành tựu \n Tăng 10% Damage X6',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Lệnh bài Bá Tước',
        desc: 'Yêu cầu: Lệnh bài Tử Tước + Vương miện kim cương + 10 + Nhà cấp 5 + 50 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ về Lệnh Bài Tử Tước.',
        isShowListButton: false,
        image: require('../../assets/image/lbbt.png'),
        imageDesc: 'Tăng 300% thành tựu \n Tăng 10% Damage X8',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Lệnh bài Hầu Tước',
        desc: 'Yêu cầu: Lệnh bài Bá Tước + Vương miện kim cương + 10 + Nhà cấp 5 + 50 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ về Lệnh Bài Bá Tước.',
        isShowListButton: false,
        image: require('../../assets/image/lbht.png'),
        imageDesc: 'Tăng 400% thành tựu \n Tăng 10% Damage X10',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Lệnh bài Công Tước',
        desc: 'Yêu cầu: Lệnh bài Hầu Tước + Vương miện kim cương + 10 + Nhà cấp 5 + 50 Gold[z]. Tỷ lệ thành công 50%, thêm linh thú để tăng 1%/con, tối đa 2%/loài. Tỉ lệ tối đa: 80%. Thất bại sẽ về Lệnh Bài Hầu Tước.',
        isShowListButton: false,
        image: require('../../assets/image/lbct.png'),
        imageDesc: 'Tăng 500% thành tựu \n Tăng 10% Damage X12',
        action: '/home/upgrade'
    },
    {
        descTitle: 'Cấp độ Master 1',
        desc: 'Yêu cầu: Là thành viên trong một Club và 10,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '1',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 2',
        desc: 'Yêu cầu: Là thành viên trong một Club và 20,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '2',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 3',
        desc: 'Yêu cầu: Là thành viên trong một Club và 40,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '3',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 4',
        desc: 'Yêu cầu: Là thành viên trong một Club và 80,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '4',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 5',
        desc: 'Yêu cầu: Là thành viên trong một Club và 160,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '5',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 6',
        desc: 'Yêu cầu: Là thành viên trong một Club và 320,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '6',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 7',
        desc: 'Yêu cầu: Là thành viên trong một Club và 640,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '7',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    {
        descTitle: 'Cấp độ Master 8',
        desc: 'Yêu cầu: Là thành viên trong một Club và 1,280,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '8',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    ,
    {
        descTitle: 'Cấp độ Master 9',
        desc: 'Yêu cầu: Là thành viên trong một Club và 2,560,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '9',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
    ,
    {
        descTitle: 'Cấp độ Master 10',
        desc: 'Yêu cầu: Là thành viên trong một nhóm và 5,120,000,000 thành tựu.',
        isShowListButton: false,
        image: require('../../assets/image/master.png'),
        imageDesc: '10',
        isMaster: true,
        otherAction: true,
        action: 'user-bar'
    },
]

class Theory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            youtubes: []
        }
    }

    renderRight = (item) => {
        if (item.isShowListButton) {
            return (
                <>
                    {item.buttons.map((button, index) =>
                        <div className='theory-button' key={index}>
                            {button.image &&
                                <img
                                    src={button.image}
                                    className='theory-button-image'
                                />
                            }
                            <p className='black text-large'>{button.title}</p>
                        </div>
                    )}
                </>
            )
        }
        if (item.isMaster) {
            return (
                <div className='item-center relative'>
                    <img
                        className='theory-master-image'
                        src={item.image}
                    />
                    <p className='black text-theory-master' style={{ whiteSpace: 'pre-line' }}>{item.imageDesc}</p>
                </div>
            )
        }
        if (item.image) {
            return (
                <div className='item-center'>
                    <img
                        className='theory-image'
                        src={item.image}
                    />
                    <p className='black text-large mgt' style={{ whiteSpace: 'pre-line' }}>{item.imageDesc}</p>
                </div>
            )
        }
        if (item.text) {
            return (
                <div className='item-center'>
                    <p className='theory-text-big'>{item.text}</p>
                    <p className='black text-large mgt-2'>{item.imageDesc}</p>
                </div>
            )
        }
        return null
    }

    handleAction = (item) => {
        if (item.otherAction) {
            switch (item.action) {
                case 'user-bar':
                    this.props.toogleUserBar()
                    break;
                case 'chat':
                    this.props.getListChatAll();
                    this.props.showHideChat(true)
                    break;
                case 'search':
                    this.props.getTimeGuideAction();
                    this.props.showHideSearch(true)
                    break;
            }
            return;
        }
        if (item.actionMenuTab) {
            this.props.updateStateNavigate({ menuTab: item.actionMenuTab })
        }
        this.props.history.push(item.action, item.queryAction)
    }

    getYoutubeTheory = () => {
        getYoutubeTheoryApi().then(res => {
            if (res.code == 1) {
                this.setState({ youtubes: res.data })
            }
        })
    }

    componentDidMount() {
        this.getYoutubeTheory();
    }

    render() {
        const {youtubes} = this.state;
        return (
            <>
                <div className='theory-title-container bg-primary'>
                    <p className='theory-title color-white'>Hướng Dẫn</p>
                </div>
                {youtubes.map((item, key) =>
                    <div className='flex item-center mgt'>
                        <iframe
                            src={"https://www.youtube.com/embed/" + getYoutubeId(item)}
                            frameborder="0"
                            allow="encrypted-media"
                            allowFullScreen="allowFullScreen"
                            style={{ width: '100%', height: '50vh', borderRadius: '2vh' }}
                            key={key}
                        />
                    </div>
                )}

                <div className='theory-title-container mgt'>
                    <p className='black text-gigantic text-center bold'>Tổng quan hoạt động cộng đồng</p>
                    <p className='black mgt bold'>🌅 6h sáng: Thức dậy</p>
                    <p className='black mgt'>• Khởi động ngày mới bằng minigame vui vẻ.</p>
                    <p className='black mgt'>• Mở quà buổi sáng để bắt đầu ngày mới may mắn.</p>
                    <p className='black mgt'>• Sau đó, ai đi học thì đi học, ai đi làm thì đi làm.</p>

                    <p className='black mgt bold'>🌞 11h - 12h trưa: Nghỉ trưa</p>
                    <p className='black mgt'>• Mở quà buổi trưa.</p>
                    <p className='black mgt'>• Giải trí bằng hoạt động giải cứu linh thú.</p>

                    <p className='black mgt bold'>🌙 8h30 - 9h30 tối: Ăn cơm xong</p>
                    <p className='black mgt'>• Mở quà buổi tối và hoạt động giao lưu tập thể.</p>
                    <p className='black mgt'>• Đêm nằm nghe Podcast kinh doanh.</p>
                </div>
                {thories.map((item, key) =>
                    <div className='thoery-item-container row' key={key}>
                        <div className='col-md-6 col-sm-12 col-xs-12 theory-desc-container'>
                            <p className='text-gigantic black bold'>{key + 1}. {item.descTitle}</p>
                            <p className='black mgt'>{item.desc}</p>
                        </div>
                        <div className='col-md-6 col-sm-12 col-xs-12 flex theory-button-container'>
                            <div className='flex-column flex-1 justify-center'>
                                {this.renderRight(item)}
                            </div>
                            <div className='theory-button-next-container'>
                                <Icon
                                    name='arrow-circle-right'
                                    className='theory-button-next pointer'
                                    onClick={() => this.handleAction(item)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { toogleUserBar, showHideChat, getListChatAll, getTimeGuideAction, showHideSearch, updateStateNavigate })(withRouter(Theory));