import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import HomeScreen from '../screens/home';
import MenuScreen from '../screens/menu';
import NewFeedScreen from '../screens/newfeed';
import QuestionLobbyScreen from '../screens/questionLobby';
import QuestionEnglishScreen from '../screens/questionEnglish';
import QuestionBossScreen from '../screens/questionBoss';
import AffiliateScreen from '../screens/affiliate';
import TemplateLogined from '../components/layout/logined';
import Audio from '../screens/audio';
import Study from '../screens/study';
import { connect } from 'react-redux'
import TemplateAdmin from '../components/layout/admin';
import Admin from '../screens/admin';

const Private = () => {
    let { path } = useRouteMatch();
    return (
        <TemplateLogined>
            <Switch>
                <Route path={`${path}menu`}>
                    <Switch>
                        <Route path={`${path}menu/theory`}>
                            <MenuScreen.Theory />
                        </Route>
                        <Route path={`${path}menu/post`}>
                            <MenuScreen.Post />
                        </Route>
                        <Route path={`${path}menu/post-mine`}>
                            <MenuScreen.PostMine />
                        </Route>
                        <Route path={`${path}menu/post-publish`}>
                            <MenuScreen.PostPublish />
                        </Route>
                        <Route path={`${path}menu/post-vote`}>
                            <MenuScreen.PostVote />
                        </Route>
                        <Route path={`${path}menu/post-bonus`}>
                            <MenuScreen.PostBonus />
                        </Route>
                        <Route path={`${path}menu/post-search`}>
                            <MenuScreen.PostSearch />
                        </Route>
                        <Route path={`${path}menu/video`}>
                            <MenuScreen.Video />
                        </Route>
                        <Route path={`${path}menu/video-mine`}>
                            <MenuScreen.VideoMine />
                        </Route>
                        <Route path={`${path}menu/video-publish`}>
                            <MenuScreen.VideoPublish />
                        </Route>
                        <Route path={`${path}menu/video-vote`}>
                            <MenuScreen.VideoVote />
                        </Route>
                        <Route path={`${path}menu/video-bonus`}>
                            <MenuScreen.VideoBonus />
                        </Route>
                        <Route path={`${path}menu/video-search`}>
                            <MenuScreen.VideoSearch />
                        </Route>
                        <Route path={`${path}menu/guide`}>
                            <MenuScreen.Guide />
                        </Route>
                        <Route path={`${path}menu/vestige`}>
                            <MenuScreen.Vestige />
                        </Route>
                        <Route path={`${path}menu/target`}>
                            <MenuScreen.TargetUser />
                        </Route>
                        <Route path={`${path}menu/capacity`}>
                            <MenuScreen.Capacity />
                        </Route>
                        <Route path={`${path}menu/medal`}>
                            <MenuScreen.Medal />
                        </Route>
                        <Route path={`${path}menu/book-review-publish`}>
                            <MenuScreen.BookReviewPublish />
                        </Route>
                        <Route path={`${path}menu/ask-question-publish`}>
                            <MenuScreen.AskQuestionPublish />
                        </Route>
                        <Route path={`${path}menu/ask-question-vote`}>
                            <MenuScreen.AskQuestionVote />
                        </Route>
                        <Route path={`${path}menu/ask-question`}>
                            <MenuScreen.AskQuestion />
                        </Route>
                        <Route path={`${path}menu/ask-question-search`}>
                            <MenuScreen.AskQuestionSearch />
                        </Route>
                        <Route path={`${path}menu/ask-question-bonus`}>
                            <MenuScreen.AskQuestionBonus />
                        </Route>
                        <Route path={`${path}menu/ask-question-mine`}>
                            <MenuScreen.AskQuestionMine />
                        </Route>
                        <Route path={`${path}menu/brand-top`}>
                            <MenuScreen.BrandTop />
                        </Route>
                        <Route path={`${path}menu/brand-online`}>
                            <MenuScreen.BrandOnline />
                        </Route>
                        <Route path={`${path}menu/brand-find`}>
                            <MenuScreen.BrandFind />
                        </Route>
                        <Route path={`${path}menu/brand-group`}>
                            <MenuScreen.BrandGroup />
                        </Route>
                        <Route path={`${path}menu/gift-offer`}>
                            <MenuScreen.GiftOffer />
                        </Route>
                        <Route path={`${path}menu/charge-invest`}>
                            <MenuScreen.ChargeInvest />
                        </Route>
                        <Route path={`${path}menu/charge-market`}>
                            <MenuScreen.ChargeMarket />
                        </Route>
                        <Route path={`${path}menu/charge-food`}>
                            <MenuScreen.ChargeFood />
                        </Route>
                        <Route path={`${path}menu/charge-asset`}>
                            <MenuScreen.ChargeAsset />
                        </Route>
                        <Route path={`${path}menu/charge-blackmarket`}>
                            <MenuScreen.ChargeBlackMarket />
                        </Route>
                        <Route path={`${path}menu/charge`}>
                            <MenuScreen.Charge />
                        </Route>
                        <Route path={`${path}menu/charge-sell`}>
                            <MenuScreen.ChargeSell />
                        </Route>
                        <Route path={`${path}menu/charge-asset-market`}>
                            <MenuScreen.ChargeAssetMarket />
                        </Route>
                        <Route path={`${path}menu/charge-affiliate`}>
                            <MenuScreen.ChargeAffiliate />
                        </Route>
                        <Route path={`${path}menu/question-event`}>
                            <MenuScreen.QuestionEvent />
                        </Route>
                        <Route path={`${path}menu/question-vote`}>
                            <MenuScreen.QuestionVote />
                        </Route>
                        <Route path={`${path}menu/question-own`}>
                            <MenuScreen.QuestionOwn />
                        </Route>
                        <Route path={`${path}menu/question-list`}>
                            <MenuScreen.QuestionList />
                        </Route>
                        <Route path={`${path}menu/gift-day`}>
                            <MenuScreen.GiftDay />
                        </Route>
                        <Route path={`${path}menu/gift-event`}>
                            <MenuScreen.QuestionEvent />
                        </Route>
                        <Route path={`${path}menu/gift-train`}>
                            <MenuScreen.QuestionTrain />
                        </Route>
                        <Route path={`${path}menu/gift-spin`}>
                            <MenuScreen.GiftSpin />
                        </Route>
                        <Route path={`${path}menu/gift-effort`}>
                            <MenuScreen.GiftEffort />
                        </Route>
                        <Route path={`${path}menu/course-list`}>
                            <MenuScreen.CourseList />
                        </Route>
                        <Route path={`${path}menu/course-own`}>
                            <MenuScreen.CourseOwn />
                        </Route>
                        <Route path={`${path}menu/course-create`}>
                            <MenuScreen.CourseCreate />
                        </Route>
                        <Route path={`${path}menu/course-promotion`}>
                            <MenuScreen.CoursePromotion />
                        </Route>
                        <Route path={`${path}menu/course-manage`}>
                            <MenuScreen.CourseManage />
                        </Route>
                        <Route path={`${path}menu/setting-profile`}>
                            <MenuScreen.SettingProfile />
                        </Route>
                        <Route path={`${path}menu/setting-secure`}>
                            <MenuScreen.SettingSecure />
                        </Route>
                        <Route path={`${path}menu/setting-history`}>
                            <MenuScreen.SettingHistory />
                        </Route>
                        <Route path={`${path}menu/config-wallpaper`}>
                            <MenuScreen.ConfigWallpaper />
                        </Route>
                        <Route path={`${path}menu/config-download`}>
                            <MenuScreen.ConfigDownload />
                        </Route>
                        <Route path={`${path}menu/club-info`}>
                            <MenuScreen.ClubInfo />
                        </Route>
                        <Route path={`${path}menu/club-create`}>
                            <MenuScreen.ClubCreate />
                        </Route>
                        <Route path={`${path}menu/club-list`}>
                            <MenuScreen.ClubList />
                        </Route>
                        <Route path={`${path}menu/club-member`}>
                            <MenuScreen.ClubMember />
                        </Route>
                        <Route path={`${path}menu/club-fortune`}>
                            <MenuScreen.ClubFortune />
                        </Route>
                        <Route path={`${path}menu/club-level`}>
                            <MenuScreen.ClubLevel />
                        </Route>
                        <Route path={`${path}menu/club-feast`}>
                            <MenuScreen.ClubFeast />
                        </Route>
                        <Route path={`${path}menu/gift-ticket`}>
                            <MenuScreen.GiftTicket />
                        </Route>
                        <Route path={`${path}menu/question-fight`}>
                            <Switch>
                                <Route path={`${path}menu/question-fight/fight`}>
                                    <MenuScreen.QuestionFight.Fight />
                                </Route>
                                <Route path={`${path}menu/question-fight/room`}>
                                    <MenuScreen.QuestionFight.Room />
                                </Route>
                                <Route path={path}>
                                    <MenuScreen.QuestionFight.Lobby />
                                </Route>
                            </Switch>
                        </Route>
                        <Route path={`${path}menu/question-lobby`}>
                            <QuestionLobbyScreen.Main />
                        </Route>
                        <Route path={`${path}menu/question-english`}>
                            <QuestionEnglishScreen.Main />
                        </Route>
                        <Route path={`${path}menu/question-boss`}>
                            <QuestionBossScreen.Main />
                        </Route>
                        <Route path={path}>
                            <MenuScreen.Main />
                        </Route>
                    </Switch>
                </Route>
                <Route path={`${path}blank`}>
                    <NewFeedScreen.Blank />
                </Route>
                <Route path={`${path}question-fight`}>
                    <Switch>
                        <Route path={`${path}question-fight/fight`}>
                            <MenuScreen.QuestionFight.Fight />
                        </Route>
                        <Route path={`${path}question-fight/room`}>
                            <MenuScreen.QuestionFight.Room />
                        </Route>
                        <Route path={path}>
                            <MenuScreen.QuestionFight.Lobby />
                        </Route>
                    </Switch>
                </Route>
                <Route path={`${path}home`}>
                    <Switch>
                        <Route path={`${path}home/guest`}>
                            <HomeScreen.Guest />
                        </Route>
                        <Route path={`${path}home/upgrade`}>
                            <HomeScreen.Upgrade />
                        </Route>
                        <Route path={path}>
                            <HomeScreen.Property />
                        </Route>
                    </Switch>
                </Route>
                <Route path={`${path}new-feed`}>
                    <NewFeedScreen.Main />
                </Route>
                <Route path={`${path}question-lobby`}>
                    <QuestionLobbyScreen.Main />
                </Route>
                <Route path={`${path}study`}>
                    <Switch>
                        <Route path={`${path}study/study-summary`}>
                            <Study.StudySummary />
                        </Route>
                        <Route path={`${path}study/study-learn`}>
                            <Study.StudyLearn />
                        </Route>
                        <Route path={`${path}study/study-subject`}>
                            <Study.StudySubject />
                        </Route>
                        <Route path={`${path}study/study-class`}>
                            <Study.StudyClass />
                        </Route>
                        <Route path={path}>
                            <Study.Main />
                        </Route>
                    </Switch>
                </Route>
                <Route path={`${path}affiliate`}>
                    <AffiliateScreen.Main />
                </Route>
                <Route path={`${path}podcast`}>
                    <Audio.Main />
                </Route>
                <Route path={path} exact>
                    <MenuScreen.Theory />
                </Route>
                <Route render={() => <Redirect to={`${path}`}/>}/>
            </Switch>
        </TemplateLogined>
    )
}

const AdminRoot = () => {
    let { path } = useRouteMatch();
    return (
        <TemplateAdmin>
            <Switch>
                <Route path={`${path}user`}>
                    <Admin.User />
                </Route>
                <Route path={`${path}sub-category`}>
                    <Admin.SubCategory />
                </Route>
                <Route path={`${path}admin-account`}>
                    <Admin.Admin />
                </Route>
                <Route path={`${path}log`}>
                    <Admin.Log />
                </Route>
                <Route path={`${path}subject`}>
                    <Admin.Subject />
                </Route>
                <Route path={`${path}support`}>
                    <Admin.Support />
                </Route>
                <Route path={`${path}ticket`}>
                    <Admin.Ticket />
                </Route>
                <Route path={`${path}z-crazy`}>
                    <Admin.ZCrazy />
                </Route>
                <Route path={`${path}z-vip`}>
                    <Admin.Zvip />
                </Route>
                <Route path={`${path}audio`}>
                    <Admin.Audio />
                </Route>
                <Route path={path}>
                    <Admin.Main />
                </Route>
            </Switch>
        </TemplateAdmin>
    )
}

const Main = (props) => {
    if (props.dataUser.RoleId == 1) return <AdminRoot />
    return <Private />
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Main)