import React, { Component } from 'react';
import { getUserData, updateDataUser } from '../../actions/home';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { MESSAGE } from '../../utils/constants';
import { formatDecimal } from '../../utils/common';
import TooltipUser from '../../components/TooltipUser';
import { getListInviteApi, getListLogApi } from '../../api/aff';
import { showNotification } from '../../utils/notification';
import ButtonStatus from '../../components/affiliate/ButtonStatus';
import UserInfoAff from '../../components/affiliate/UserInfoAff';
import moment from 'moment';
const ButtonGroup = Button.Group;

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTab: 1,
            isPress: false,
            logs: [],
            invites: {},
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    getListLog = () => {
        getListLogApi().then(res => {
            this.setState({
                logs: res.data
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListInvite = () => {
        getListInviteApi().then(res => {
            this.setState({
                invites: res.data
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    renderButtonStatus = (item) => {
        if (item.Status == 1 || item.LogTypeId == 2 || item.LogTypeId == 3) {
            return (
                <div className='button-money-aff bg-success border-white' style={{ width: '20vh' }}>
                    <p className='text-large bold mgb-0'>Thành công</p>
                </div>
            )
        }
        else if (item.Status == 2) {
            return (
                <div className='button-money-aff bg-danger border-white' style={{ width: '20vh' }}>
                    <p className='text-large bold mgb-0'>Thất bại</p>
                </div>
            )
        }
        return (
            <div className='button-money-aff bg-warning border-white' style={{ width: '20vh' }}>
                <p className='text-large bold mgb-0'>Đang xử lý</p>
            </div>
        )
    }

    renderList = () => {
        const { dataUser } = this.props;
        const { logs, selectTab, invites } = this.state;
        if (selectTab == 1) {
            return (
                <>
                    {Object.keys(invites).length ?
                        <>
                            {Object.keys(invites).map((date) =>
                                <React.Fragment key={date}>
                                    <p className='bold text-large mgt'>{moment(date).format('DD/MM/YYYY')}</p>
                                    <div className='row mgt user-online-box'>
                                        {invites[date].map((item, key) =>
                                            <div className='col-md-3 col-sm-4 col-xs-6 user-online-block' key={key}>
                                                <div style={{ position: 'relative' }}>
                                                    <TooltipUser
                                                        avatarClass='avatar-guest-large'
                                                        userInfo={item}
                                                    />
                                                </div>
                                                <p className='mgt'>{item.FullName}</p>
                                                <p className='mgt'>+ {formatDecimal(item.TargetUserEarn)}đ</p>
                                                <p className='mgt'>+ {item.UpgradeType == 2 ? formatDecimal(500000 / 100 * ((item.IsZip == 2 ? 45 : 10) + +dataUser.UserVip)) : '0'}đ/tháng</p>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </>
                        : null}
                </>
            )
        }
        return (
            <>
                {logs.map((item) =>
                    <div className='box-affiliate mgb row' key={item.ZvipTransactionLogId}>
                        <div className='flex col-md-9 col-sm-6 col-xs-12 align-center'>
                            <TooltipUser userInfo={dataUser} avatarClass='avatar-guest-large' />
                            <div className='mgl-2'>
                                <p>{item.Comment}</p>
                                <p>Số dư: {formatDecimal(item.Balance)} đ</p>
                                <p className='text-time'>{item.CrDateTime}</p>
                            </div>
                        </div>
                        <div className='flex col-md-3 col-sm-6 col-xs-12 align-center flex-end'>
                            <ButtonStatus item={item} />
                        </div>
                    </div>
                )}
            </>
        )
    }

    componentDidMount() {
        this.getListInvite();
    }

    render() {
        return (
            <>
                <UserInfoAff />
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 }, this.getListInvite)} className={this.buttonGroupClass(1)}>Affiliate ({Object.values(this.state.invites).flat().length ?? 0})</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 }, this.getListLog)} className={this.buttonGroupClass(2)}>Lịch sử</Button>
                </ButtonGroup>
                {this.renderList()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { getUserData, updateDataUser })(Main);