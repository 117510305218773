import React from 'react';
import { Progress } from 'antd';

export default ({ dataUser }) => {
    const calcMaster = (userVip) => {
        if (+userVip >= 10) {
            return +userVip - 9;
        }
        return Number(userVip)
    }

    const vipImg = +dataUser.UserVip >= 10 ? require('../../assets/image/master.png') : require('../../assets/image/vip.png')
    return (
        <div className='bar-progress-vip mgt'>
            <div className='flex-row item-center'>
                <p className='text-center'>{dataUser.Achievement} / {dataUser.NextVipAchievement}</p>
                <img className='img-info-rank mgl' src={require('../../assets/image/achievement.png')} alt='achievement-level' />
            </div>
            <Progress
                className='vip-level-progress'
                strokeColor={{ '0%': '#96D896', '100%': '#0bb70b' }}
                percent={dataUser.Achievement / dataUser.NextVipAchievement * 100}
                status="active"
                format={() => null}
            />
            <div className='flex-row mgt'>
                <div className='flex flex-1 align-center' style={{ justifyContent: 'flex-start' }}>
                    <img className='img-info-rank' src={vipImg} alt='vip-level' />
                    <p className='mgl mgb-0'>{calcMaster(dataUser.UserVip)}</p>
                </div>
                <div className='flex flex-1 item-center'>
                    <p className='mgb-0 text-center'>{(dataUser.Achievement / dataUser.NextVipAchievement * 100).toFixed(2)}%</p>
                </div>
                <div className='flex flex-1 align-center' style={{ justifyContent: 'flex-end' }}>
                    <img className='img-info-rank' src={vipImg} alt='vip-level' />
                    <p className='mgl mgb-0'>{calcMaster(dataUser.UserVip) + 1}</p>
                </div>
            </div>
        </div>
    )
}