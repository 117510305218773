const INITAL_STATE = {
    isLoadingGuide: true,
    listGuide: [
        {
            image: require('../../assets/image/progress-gift-day.png'),
            title: 'Quà dậy sớm',
            time: 'giftDay',
            link: '/menu/gift-day',
            bonus: 'Tiền thưởng $[z]',
            range: '6h - 6h15',
            mission: 'GiftDayCount'
        },
        {
            image: require('../../assets/image/menu/question-lobby.png'),
            title: 'Siêu trí nhớ',
            link: '/menu/question-lobby',
            bonus: 'Tiền thưởng đ[z]',
            time: 'questionLobby',
            range: '24/7',
            questionLobby: true
        },
        {
            image: require('../../assets/image/menu/question-english.png'),
            title: 'Siêu từ vựng',
            link: '/menu/question-english',
            bonus: 'Tiền thưởng đ[z]',
            time: 'questionEnglish',
            range: '24/7',
            questionEnglish: true
        },
        {
            image: require('../../assets/image/lixi.png'),
            title: 'Quà phát vốn',
            link: '/menu/gift-day',
            bonus: 'Tiền thưởng đ[z]',
            time: 'giftStart',
            range: '6h - 8h',
            mission: 'GiftDayCount'
        },
        {
            image: require('../../assets/image/menu/gift-effort.png'),
            title: 'Quà tăng trưởng',
            link: '/menu/gift-effort',
            bonus: 'Tiền thưởng $[z]',
            time: 'giftEffort',
            range: '6h - 7h | 11h - 12h | 21h - 21h30',
            mission: 'GiftEffortCount'
        },
        {
            image: require('../../assets/image/menu/question-event.png'),
            title: 'Sự kiện ngoại ngữ',
            time: 'giftEvent',
            link: '/menu/gift-event',
            bonus: 'Thành tựu & Tiền thưởng đ[z]',
            range: '0h30 | 2h30 | 4h30 | ...',
            mission: 'GiftEventCount'
        },
        {
            image: require('../../assets/image/menu/question-train.png'),
            title: 'Toán khởi nghiệp',
            link: '/menu/gift-train',
            bonus: 'Tiền thưởng đ[z] & $[z]',
            time: 'giftTrain',
            range: '24/7',
            mission: 'GiftTrainCount'
        },
        {
            image: require('../../assets/image/menu/charge-invest.png'),
            title: 'Thị trường chứng khoán',
            time: 'chargeInvest',
            link: '/menu/charge-invest',
            bonus: 'Tiền thưởng đ[z] & $[z]',
            range: '9h - 15h',
            mission: 'BuyInvestCount'
        },
        {
            image: require('../../assets/image/menu/gift-spin.png'),
            title: 'Quà thần bí',
            link: '/menu/gift-spin',
            bonus: 'Tiền thưởng $[z]',
            time: 'giftSpin',
            range: '20h45 - 21h',
            mission: 'GiftSpinCount'
        },
        // {
        //     image: require('../../assets/image/menu/question-fight.png'),
        //     title: ' Đấu trường',
        //     link: '/menu/question-fight',
        //     bonus: 'Tiền cược',
        //     time: 'questionFight',
        //     range: '24/7'
        // },
        {
            image: require('../../assets/image/menu/gift-ticket.png'),
            title: 'Phá phong ấn',
            time: 'giftTicket',
            link: '/menu/gift-ticket',
            bonus: 'Tiền thưởng Gold[z]',
            range: '21h15 - 21h30',
            mission: 'GiftTicketCount'
        },
        {
            image: require('../../assets/image/like.png'),
            title: 'Thả tym',
            link: '/menu/brand-online',
            bonus: 'Thành tựu & Tiền thưởng đ[z]',
            time: 'likeUser',
            range: '20h30 - 20h45',
            mission: 'LikeCount'
        },
        // {
        //     image: require('../../assets/image/mall.png'),
        //     title: 'Shopping',
        //     link: '/menu/charge-market',
        //     bonus: 'Thành tựu',
        //     time: 'shopping',
        //     range: '24/7'
        // },
        // {
        //     image: require('../../assets/image/menu/charge-blackmarket.png'),
        //     title: 'Chợ Gold[z]',
        //     link: '/menu/charge-blackmarket',
        //     bonus: 'Tiền trao đổi',
        //     time: 'questionLobby',
        //     range: '24/7'
        // },
        {
            image: require('../../assets/image/knight.png'),
            title: 'Giải cứu linh thú',
            link: '/menu/question-boss',
            bonus: 'Linh thú',
            time: 'questionBoss',
            range: '24/7',
            mission: 'QuestionBossCount'
        },
    ]
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'STORE_TIME_GUIDE':
            return {
                ...state,
                isLoadingGuide: false,
                listGuide: state.listGuide.map(item => ({
                    ...item,
                    timeQueue: action.timeQueue[item.time]
                })).sort((a, b) => a.timeQueue - b.timeQueue)
            };
        default:
            return state;
    }
};