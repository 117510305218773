import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint} from '../../actions/home/';
import {getAssetHintApi, upgradeAssetApi, getItemUpgradeApi} from '../../api/home/upgrade';
import { MESSAGE, URI_PATH} from '../../utils/constants';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import caution from '../../assets/lottie/caution.json';
import play from '../../assets/lottie/play.json';
import machine from '../../assets/lottie/machine.json';
import HeaderLottie from '../../components/HeaderLottie';
import Lottie from 'react-lottie';

class Upgrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItemUpgrade: [],
            listItemUpgrade: [],
            loadingItemAsset: true,
            loadingHint: false,
            isCorrectHint: false,
            dataHint: {},
            assetTarget: 0,
            isUpgradeClick: false,
            isSuccess: false,
            isDropItem: false,
            assetInfo: {},
            isResult: false
        }
        this.listAssetSelect = [];
        this.listFoodSelect = [];
    }

    getItemUpgrade = () =>{
        getItemUpgradeApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    listItemUpgrade: res.listUpgrade,
                    loadingItemAsset: false,
                })
            }
            else showNotification(res.message, res.code)
        }).catch(e =>{
            showNotification(MESSAGE.ERROR, 0)
        })
    }

    actionItemUpgrade = (item, action) =>{
        if(!this.state.isUpgradeClick){
            let {selectItemUpgrade, listItemUpgrade, isResult} = this.state;
            if(isResult){ // check remove item upgrade result
                this.setState({
                    isUpgradeClick: false,
                    isSuccess: false,
                    isDropItem: false,
                    assetInfo: {},
                    isResult: false
                })
            }
            if(action == 1){ // pick
                if(item.AssetId){
                    this.listAssetSelect.push(item.AssetId);
                    this.setState({
                        selectItemUpgrade: [...selectItemUpgrade, item],
                        listItemUpgrade: listItemUpgrade.filter(el => {
                            return el.AssetId !== item.AssetId;
                        }),
                        loadingHint: true
                    });
                }
                else{
                    this.listFoodSelect.push(item.FoodId);
                    this.setState({
                        selectItemUpgrade: [...selectItemUpgrade, item],
                        listItemUpgrade: listItemUpgrade.filter(el => {
                            return el.FoodId !== item.FoodId;
                        }),
                        loadingHint: true
                    });
                }
            }
            else{ // remove
                if(item.AssetId){
                    this.listAssetSelect = this.listAssetSelect.filter(el => {
                        return el !== item.AssetId;
                    })
                    this.setState({
                        listItemUpgrade: [item, ...listItemUpgrade],
                        selectItemUpgrade: selectItemUpgrade.filter(el => {
                            return el.AssetId !== item.AssetId;
                        }),
                        loadingHint: true
                    });
                }
                else{
                    this.listFoodSelect = this.listFoodSelect.filter(el => {
                        return el !== item.FoodId;
                    })
                    this.setState({
                        listItemUpgrade: [item, ...listItemUpgrade],
                        selectItemUpgrade: selectItemUpgrade.filter(el => {
                            return el.FoodId !== item.FoodId;
                        }),
                        loadingHint: true
                    });
                }
            }
            getAssetHintApi(this.listAssetSelect, this.listFoodSelect).then(res =>{
                this.setState({
                    isCorrectHint: res.code == 1,
                    dataHint: res.code == 1 ? res.data : {},
                    assetTarget: res.assetTarget,
                    loadingHint: false
                })
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0)
            })
        }
    }

    upgrade = () =>{
        if(!this.state.isUpgradeClick){
            this.setState({isUpgradeClick: true});
            upgradeAssetApi(this.state.assetTarget, this.listAssetSelect, this.listFoodSelect).then(res =>{
                if(res.code == 1){
                    showNotificationBonus(res.ticket, -1, 1);
                    showNotificationBonus(res.balance, -1, 2);
                    showNotificationBonus(res.balanceZ, -1, 3);
                    this.props.changePoint([-res.ticket, -res.balance, -res.balanceZ]);
                    this.listAssetSelect = [];
                    this.listFoodSelect = [];
                    new Audio(require('../../assets/audio/robot.mp3')).play();
                    setTimeout(() => {
                        this.setState({
                            selectItemUpgrade: [],
                            isSuccess: res.isSuccess,
                            isDropItem: res.isDropItem,
                            assetInfo: res.assetInfo,
                            isUpgradeClick: false,
                            isResult: true
                        });
                        this.getItemUpgrade();
                    }, 5600);
                }
                else {
                    showNotification(res.message, res.code);
                    this.setState({isUpgradeClick: false});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0);
                this.setState({isUpgradeClick: false});
            })
        }
    }

    renderListItem = () =>{
        let {loadingItemAsset, listItemUpgrade} = this.state;
        if(loadingItemAsset) return (<Loading small />);
        return(
            <div>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift mgt-2'>Nguyên liệu</p>
                </div>
                <div className="row mgt-2">
                    {listItemUpgrade.map((item, key) =>
                        <div
                            className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                            key={key}
                            onClick={() => this.actionItemUpgrade(item, 1)}
                        >
                            <img className='item-img mgb' src={`${URI_PATH}${item.AssetImage ? item.AssetImage : item.FoodImage}`}/>
                            <p className='mgt'>{item.Amount}</p>
                            <p>{item.AssetName ? item.AssetName : item.FoodName}</p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderHint = () =>{
        let {isCorrectHint, dataHint, loadingHint, assetTarget, isUpgradeClick} = this.state;
        if(this.listAssetSelect.length > 0 && !isUpgradeClick){
            if(loadingHint) return(<Loading small />);
            if(isCorrectHint){
                return(
                    <div className='item-center'>
                        <img
                            className='item-img mgb'
                            alt={dataHint.AssetName}
                            src={`${URI_PATH}${dataHint.AssetImage}`}
                            style={{width: '15vh'}}
                        />
                        <p className='text-large'>{dataHint.AssetName}</p>
                        <p className='text-large mgt-2'>Tỉ lệ: {Math.round(dataHint.AssetPercent)}%</p>
                        <p className='text-large mgt-2'>Yêu cầu: {dataHint.Hint}</p>
                        {assetTarget > 0 && Math.round(dataHint.AssetPercent) > 0 &&
                            <div className='flex item-center'>
                                <div
                                    className='mgt-2 pointer'
                                    onClick={this.upgrade}
                                >
                                    <Lottie options={{loop: true, autoplay: true, animationData: play}}
                                        height='10vh'
                                        width='10vh'
                                    />
                                </div>
                            </div>
                        }
                    </div>
                )
            }
            else return(
                <div className='item-center'>
                    <Lottie options={{loop: true, autoplay: true, animationData: caution}}
                        height='22vh'
                        width='22vh'
                    />
                    <p className='text-large'>Vật phẩm nâng cấp không hợp lệ</p>
                </div>
            )
        }
        return null;
    }

    renderResult = () =>{
        let {isUpgradeClick, isResult, isSuccess, isDropItem, assetInfo} = this.state;
        if(isUpgradeClick){
            return(
                <div className='flex item-center box-result-upgrade'>
                    <img
                        src={require('../../assets/image/create.gif')}
                        style={{width: '30vh'}}
                    />
                </div>
            )
        }
        else if(isResult){
            if(isSuccess == 1){
                return(
                    <div className='flex-column item-center box-result-upgrade'>
                        <img
                            className='item-img mgb'
                            alt={assetInfo.AssetName}
                            src={`${URI_PATH}${assetInfo.AssetImage}`}
                            style={{width: '15vh'}}
                        />
                        <p className='text-large'>{assetInfo.AssetName}</p>
                        <p className='text-huge'>Nâng cấp thành công. Xin chúc mừng</p>
                    </div>
                )
            }
            else{
                if(isDropItem == 1){
                    return(
                        <div className='flex-column item-center box-result-upgrade'>
                            <img
                                className='item-img mgb'
                                alt={assetInfo.AssetName}
                                src={`${URI_PATH}${assetInfo.AssetImage}`}
                                style={{width: '15vh'}}
                            />
                            <p className='text-large'>{assetInfo.AssetName}</p>
                            <p className='text-huge'>Nâng cấp thất bại</p>
                        </div>
                    )
                }
                return(
                    <div className='flex item-center box-result-upgrade'>
                        <p className='text-huge'>Nâng cấp thất bại</p>
                    </div>
                )
            }
        }
        return null;
    }

    componentDidMount(){
        this.getItemUpgrade();
    }

    render() {
        let {loadingItemAsset, selectItemUpgrade, assetTarget, dataHint} = this.state;
        if(loadingItemAsset) return(<Loading />);
        return (
            <div>
                <HeaderLottie source={machine} size='40vh' />
                <div className={'box-gift-spin ' + (assetTarget > 0 && Math.round(dataHint.AssetPercent) > 0 && 'flash')}>
                    <div>
                        <p className='text-center text-huge'>Nâng cấp</p>
                        <div className='row mgt-2 list-item-upgrade'>
                            {selectItemUpgrade.map((item, key) =>(
                                <div
                                    key={key}
                                    className="col-md-3 col-sm-4 col-xs-6 item-block item-center pointer"
                                    onClick={() => this.actionItemUpgrade(item, 2)}
                                >
                                    <img className='item-img mgb' src={`${URI_PATH}${item.AssetImage ? item.AssetImage : item.FoodImage}`}/>
                                    <p className='mgt'>{item.Amount}</p>
                                    <p>{item.AssetName ? item.AssetName : item.FoodName}</p>
                                </div>
                            ))}
                        </div>
                        <hr width="95%" align="center" className='hr-default mgt' />
                        <div className='list-item-upgrade'>
                            {this.renderHint()}
                            {this.renderResult()}
                        </div>
                    </div>
                </div>
                {this.renderListItem()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint})(Upgrade);