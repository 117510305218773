import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getListChatPeople, showHideChat} from '../../../actions/chat';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';
import { URI_PATH } from '../../../utils/constants';

class AssetFail extends Component{
    chatUser = () =>{
        let data = this.props.data;
        this.props.getListChatPeople(data.UserId);
        this.props.showHideChat(true, false, {
            UserId: data.UserId,
            FacebookId: data.FacebookId,
            FullName: data.FullName,
        });
    }

    render(){
        let data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p>
                            <span className='bold'>{data.FullName}
                            <Checked data={data} />
                        </span> đã nỗ lực nâng cấp {data.AssetName} nhưng chưa thành công 😭. Cùng chia buồn với bạn ấy!
                        </p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                {data.AssetFail?.AssetId &&
                    <div className='item-center'>
                        <Tooltip
                            overlayClassName='black-tooltip'
                            title={
                                <div className='item-center'>
                                    <p className='bold'>{data.AssetFail?.AssetName}</p>
                                    <p>Tăng {data.AssetFail?.AssetBonus} % thành tựu</p>
                                </div>
                            }
                        >
                            <img className='newfeed-img' src={`${URI_PATH}${data.AssetFail?.AssetImage}`} />
                        </Tooltip>
                    </div>
                }
                {this.props.dataUser.UserId != data.UserId && !data.Guest &&
                    <div className='flex-column item-center'>
                        <Button
                            className='newfeed-button mgt-2'
                            onClick={this.chatUser}
                        >
                            Chia buồn
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {getListChatPeople, showHideChat})(withRouter(AssetFail));