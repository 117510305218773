import React, { Component } from 'react';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import NavBar from './NavBar';
import { updateTimeOnlineApi } from '../../../api/home';
import { getUserData } from '../../../actions/home';
import { getCategory } from '../../../actions/category/';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import ReadEventSocket from '../../realtime/ReadSocketEvent';
import WidgetChat from '../../chat/Widget';
import WidgetSearch from '../../search/Widget';
import WidgetNotification from '../../notification/Widget';
import ModalEatFood from '../../ModalEatFood';
import ScrollButton from '../../ScrollButton';
import StudyPackage from '../../study/StudyPackage';
import ButtonAff from '../../affiliate/ButtonAff';
import ButtonTheory from '../../theory/ButtonTheory';
import { showNoificationWinBoss } from '../../../utils/notification';
import { getBossTodayApi } from '../../../api/question/boss';
import ButtonNewbie from '../../newbie/ButtonNewbie';
import { addVoice } from '../../../actions/voice';
import { Modal } from 'antd';
import { PROP } from '../../../utils/constants';
import HappyBirthday from './HappyBirthday';
import ModalBuyVip from '../../ModalBuyVip';

class Logined extends Component {
    constructor(props) {
        super(props);
        this.intervalCheckOnline = '';
        this.state = {
            isShowMenuAndChat: true
        }
    }

    updateDimensions = () => {
        if (window.innerWidth > 1200 && this.state.isShowMenuAndChat) {
            this.setState({ isShowMenuAndChat: false })
        }
        else if (window.innerWidth <= 1200 && !this.state.isShowMenuAndChat) {
            this.setState({ isShowMenuAndChat: true })
        }
    }

    getBossToday = () => {
        getBossTodayApi().then(res => {
            if (res.code == 1) {
                if (res.data.IsDie == 2) {
                    showNoificationWinBoss(res.data)
                }
            }
        }).catch(e => {
        })
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.isLoading != this.props.isLoading) && !this.props.isLoading) {
            const voices = window.speechSynthesis.getVoices();
            let voice = voices.find(i => i?.name === 'Karen' || i?.name === 'Google US English')
            if(this.props.dataUser.Language == 2){
                voice = voices.find(i => i?.name === 'Kyoko' || i?.name === 'Google 日本語')
            }
            else if(this.props.dataUser.Language == 3){
                voice = voices.find(i => i?.name === 'Yuna' || i?.name === 'Google 한국의')
            }
            else if(this.props.dataUser.Language == 4){
                voice = voices.find(i => i?.name === 'Tingting' || i?.name === 'Google 普通话（中国大陆）')
            }
            else if(this.props.dataUser.Language == 5){
                voice = voices.find(i => i?.name === 'Monica' || i?.name === 'Google español de Estados Unidos')
            }
            else if(this.props.dataUser.Language == 6){
                voice = voices.find(i => i?.name === 'Anna' || i?.name === 'Google Deutsch')
            }
            else if(this.props.dataUser.Language == 7){
                voice = voices.find(i => i?.name === 'Amelie' || i?.name === 'Google français')
            }
            else if(this.props.dataUser.Language == 8){
                voice = voices.find(i => i?.name === 'Yuri' || i?.name === 'Google русский')
            }
            else if(this.props.dataUser.Language == 9){
                voice = voices.find(i => i?.name === 'Maged' || i?.name === 'Google हिन्दी')
            }
            if(voice?.name){
                this.props.addVoice(voice)
            }

            window.speechSynthesis.onvoiceschanged = () => {
                const voices = window.speechSynthesis.getVoices();
                let voice = voices.find(i => i?.name === 'Karen' || i?.name === 'Google US English')
                if(this.props.dataUser.Language == 2){
                    voice = voices.find(i => i?.name === 'Kyoko' || i?.name === 'Google 日本語')
                }
                else if(this.props.dataUser.Language == 3){
                    voice = voices.find(i => i?.name === 'Yuna' || i?.name === 'Google 한국의')
                }
                else if(this.props.dataUser.Language == 4){
                    voice = voices.find(i => i?.name === 'Tingting' || i?.name === 'Google 普通话（中国大陆）')
                }
                else if(this.props.dataUser.Language == 5){
                    voice = voices.find(i => i?.name === 'Monica' || i?.name === 'Google español de Estados Unidos')
                }
                else if(this.props.dataUser.Language == 6){
                    voice = voices.find(i => i?.name === 'Anna' || i?.name === 'Google Deutsch')
                }
                else if(this.props.dataUser.Language == 7){
                    voice = voices.find(i => i?.name === 'Amelie' || i?.name === 'Google français')
                }
                else if(this.props.dataUser.Language == 8){
                    voice = voices.find(i => i?.name === 'Yuri' || i?.name === 'Google русский')
                }
                else if(this.props.dataUser.Language == 9){
                    voice = voices.find(i => i?.name === 'Maged' || i?.name === 'Google हिन्दी')
                }
                if(voice?.name){
                    this.props.addVoice(voice)
                }
            };
        }
    }

    componentDidMount() {
        this.getBossToday();
        this.props.getUserData();
        this.updateDimensions();
        this.intervalCheckOnline = setInterval(() => { // check online 2p 1 lần request
            updateTimeOnlineApi();
        }, 120000);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        clearInterval(this.intervalCheckOnline);
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        if (this.props.isLoading) return (
            <div className='nav-center'>
                <Loading />
            </div>
        );
        return (
            <>
                <NavLeft />
                <div className='nav-center'>
                    <NavBar />
                    <div className='main-content'>
                        {this.props.children}
                        <div style={{ display: this.state.isShowMenuAndChat ? 'contents' : 'none' }}>
                            <WidgetChat />
                            <WidgetSearch />
                        </div>
                        <WidgetNotification />
                        {this.props.showModalEat && <ModalEatFood />}
                        <ModalBuyVip />
                        <ScrollButton />
                        <ButtonAff />
                        <ButtonTheory />
                    </div>
                </div>
                <NavRight />
                <ReadEventSocket />
                <StudyPackage />
                <HappyBirthday />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { isLoading, dataUser } = state.getUserData;
    const { showModalEat } = state.eatModal;
    const { showModalBuyVip } = state.buyVipModal;
    return { isLoading, showModalEat, dataUser, showModalBuyVip };
}

export default connect(mapStateToProps, { getUserData, getCategory, addVoice })(Logined);