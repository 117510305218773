import { getUserDataApi, getUserItemApi, getListFoodPartyApi, getUserPresentApi, getListResourceApi, getUserBarApi } from '../../api/home';
import { getCategoryApi } from '../../api/category';
import { getCountMissionApi } from '../../api/menu';
import Cookies from 'js-cookie';
import { KEY } from '../../utils/constants';
import Axios from 'axios';
import moment from 'moment';

export const getCountMission = () =>{
    return dispatch => {
        getCountMissionApi().then(res =>{ // menu count mission
            if(res.code == 1) dispatch({
                type: 'SUCCESS_GET_COUNT_MISSION',
                countMission: res.countMission,
                countNotify: res.countNotify,
                countQueue: res.countQueue,
                countProperty: res.countProperty,
                questionLobby: res.questionLobby,
                questionEnglish: res.questionEnglish,
                questionBoss: res.questionBoss,
                isMiniGame: res.isMiniGame,
            });
            else dispatch({ type: 'FALSE_GET_COUNT_MISSION' });
        }).catch(e => dispatch({ type: 'FALSE_GET_COUNT_MISSION' }));
    }
}

export const getUserData = () => {
    return dispatch => {
        getUserDataApi().then(res =>{
            if(res.code == 1){
                dispatch({ type: 'SUCCESS_GET_USER_DATA', dataUser: res.userData }); // get User Data
                getCountMission()(dispatch);
            }
            else if(res.code == -3){
                Cookies.remove(KEY.TOKEN);
                Axios.defaults.headers.common['Auth'] = '';
                window.location = '/';
            }
            else dispatch({ type: 'FALSE_GET_USER_DATA', dataUser: [] });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_USER_DATA', dataUser: [] }));
    };
};

export const getUserPresent = () =>{
    return dispatch => {
        getUserPresentApi().then(res => {
            dispatch({
                type: 'SUCCESS_GET_USER_PRESENT',
                isPresent: res.isPresent,
                userPresentData: res.userPresentData,
                presentData: res.presentData
            });
        })
        .catch(e => dispatch({ type: 'FALSE_GET_USER_PRESENT' }));
    }
}

export const getUserItem = (selectItem = 0, listFoodParty = false) => {
    return dispatch => {
        dispatch({ type: 'INIT_GET_USER_ITEM', selectItem: selectItem });
        if(listFoodParty){
            getUserItemApi(selectItem).then(res => {
                if(res.code == 1) {
                    dispatch({
                        type: 'SUCCESS_GET_USER_ITEM',
                        dataItem: res.listItem,
                        balanceAll: res.balanceAll,
                        lifeExpire: res.lifeExpire,
                        selectItem: selectItem
                    });
                    if(selectItem == 0){
                        dispatch({
                            type: 'UPDATE_DATA_USER',
                            property: 'LifeExpire',
                            value: moment.unix(res.lifeExpire).format('YYYY-MM-DD HH:mm:ss')
                        });
                    }
                }
                else dispatch({ type: 'FALSE_GET_USER_ITEM', selectItem: selectItem });
                getListFoodPartyApi().then(res => {
                    dispatch({ type: 'SUCCESS_GET_LIST_FOOD_PARTY', listFoodParty: res.listFoodParty });
                })
                .catch(e => dispatch({ type: 'FALSE_GET_LIST_FOOD_PARTY', selectItem: selectItem }));
            })
            .catch(e => dispatch({ type: 'FALSE_GET_USER_ITEM', selectItem: selectItem}));
        }
        else{
            getUserItemApi(selectItem).then(res => {
                if(res.code == 1) {
                    dispatch({ type: 'SUCCESS_GET_USER_ITEM',
                        dataItem: res.listItem,
                        balanceAll: res.balanceAll,
                        selectItem: selectItem,
                        lifeExpire: res.lifeExpire
                    })
                    if(selectItem == 0){
                        dispatch({
                            type: 'UPDATE_DATA_USER',
                            property: 'LifeExpire',
                            value: moment.unix(res.lifeExpire).format('YYYY-MM-DD HH:mm:ss')
                        });
                    }
                }
                else dispatch({ type: 'FALSE_GET_USER_ITEM', selectItem: selectItem });
            })
            .catch(e => dispatch({ type: 'FALSE_GET_USER_ITEM', selectItem: selectItem}));
        }
    };
};

export const changePoint = point =>{
    return dispatch => {
        dispatch({ type: 'CHANGE_POINT', point: point});
    }
}

export const getResourceCook = () =>{
    return dispatch => {
        getListResourceApi().then(res =>{
            dispatch({ type: 'SUCCESS_GET_RESOURCE_COOK', listResource: res.listResource });
        }).catch(e => dispatch({ type: 'FALSE_GET_RESOURCE_COOK' }));
    }
}

export const updatePresent = (userPresentData) =>{
    return dispatch => {
        dispatch({ type: 'UPDATE_PRESENT', userPresentData });
    }
}

export const updateDataUser = (property, value) =>{
    return dispatch => {
        dispatch({ type: 'UPDATE_DATA_USER', property: property, value: value });
    }
}

export const getUserBar = () =>{
    return dispatch => {
        getUserBarApi().then(res =>{
            dispatch({
                type: 'SUCCESS_GET_USER_BAR',
                crownUse: res.crownUse,
                houseUse: res.houseUse,
                cardUse: res.cardUse,
                listCrest: res.listCrest
            });
        }).catch(e => dispatch({ type: 'FALSE_GET_USER_BAR' }));
    }
}