import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getListMiniGameApi = () =>{
    let url = `${URI_PATH}app/admin/minigame/action/getListMiniGame`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const toggleActiveMiniGameApi = (IsActive, MiniGameId) =>{
    let url = `${URI_PATH}app/admin/minigame/action/toggleActiveMiniGame`;
    let body = {
        IsActive, MiniGameId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteMiniGameApi = (MiniGameId) =>{
    let url = `${URI_PATH}app/admin/minigame/action/deleteMiniGame`;
    let body = {
        MiniGameId
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateMiniGameApi = ({
    MiniGameId,
    Title,
    StartDate,
    EndDate,
    Round
}) =>{
    let url = `${URI_PATH}app/admin/minigame/action/updateMiniGame`;
    let body = {
        MiniGameId,
        Title,
        StartDate,
        EndDate,
        Round
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}