

import React from 'react';
const VipMaster = ({ userVip }) => {
    if (userVip >= 10) {
        return (
            <>
                <img className='img-info-rank img-master' src={require('../assets/image/master.png')} alt='vip-level' />
                <span className='info-rank'>{Number(userVip) - 9}</span>
            </>
        )
    }
    return (
        <>
            <img className='img-info-rank' src={require('../assets/image/vip.png')} alt='vip-level' />
            <span className='info-rank'>{userVip}</span>
        </>
    )
}

export default VipMaster;