import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateDataUser, getUserBar, changePoint } from '../../actions/home';
import { Button, Modal } from 'antd';
import { KEY, MESSAGE, PROP } from '../../utils/constants';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { getCrestApi } from '../../api/menu';
import ItemMenu from '../../components/menu/ItemMenu';
import { withRouter } from 'react-router';

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isPress: false
        }
    }

    brand = [
        { name: 'BXH Phú hộ', image: require('../../assets/image/menu/brand-top.png'), url: '/menu/brand-top', mission: 'LikeCount' },
        { name: 'Online', image: require('../../assets/image/menu/brand-online.png'), url: '/menu/brand-online', mission: 'LikeCount' },
        { name: 'Tặng quà', image: require('../../assets/image/menu/gift-offer.png'), url: '/menu/gift-offer', notify: 'OfferCount' },
        { name: 'Ghép nhóm thành tựu', image: require('../../assets/image/menu/brand-group.png'), url: '/menu/brand-group', mission: 'GroupCount' },
        { name: 'Vòng tròn học tập', image: require('../../assets/image/menu/charge-affiliate.png'), url: '/menu/charge-affiliate', notify: 'AffiliateCount', isCheckZcrazy: true },
        { name: 'Tìm bạn bè', image: require('../../assets/image/menu/brand-find.png'), url: '/menu/brand-find' },
    ]

    gift = [

    ]

    post = [
        { name: 'Đăng bài viết', image: require('../../assets/image/menu/publish-post.png'), url: '/menu/post-publish', mission: 'PostPublishCount' },
        // { name: 'Bài viết của tôi', image: require('../../assets/image/menu/post-mine.png'), url: '/menu/post-mine', mission: 'PostVerifyCount' },
        // { name: 'Xét duyệt', image: require('../../assets/image/menu/post-vote.png'), url: '/menu/post-vote', notify: 'PostVoteCount' },
        { name: 'Thư viện', image: require('../../assets/image/menu/post.png'), url: '/menu/post', mission: 'PostDiamondCount' },
        // { name: 'Vinh danh', image: require('../../assets/image/menu/post-bonus.png'), url: '/menu/post-bonus' },
        // { name: 'Tìm kiếm', image: require('../../assets/image/menu/brand-find.png'), url: '/menu/post-search' },
    ]

    askQuestion = [
        { name: 'Đăng câu hỏi', image: require('../../assets/image/menu/ask-question-publish.png'), url: '/menu/ask-question-publish' },
        { name: 'Câu hỏi của tôi', image: require('../../assets/image/menu/ask-question-mine.png'), url: '/menu/ask-question-mine' },
        { name: 'Xét duyệt', image: require('../../assets/image/menu/ask-question-vote.png'), url: '/menu/ask-question-vote', notify: 'AskQuestionVoteCount' },
        { name: 'Thư viện', image: require('../../assets/image/menu/ask-question.png'), url: '/menu/ask-question' },
        { name: 'Vinh danh', image: require('../../assets/image/menu/ask-question-bonus.png'), url: '/menu/ask-question-bonus' },
        { name: 'Tìm kiếm', image: require('../../assets/image/menu/brand-find.png'), url: '/menu/ask-question-search' },
    ]

    video = [
        { name: 'Đăng video', image: require('../../assets/image/menu/publish-video.png'), url: '/menu/video-publish', mission: 'VideoPublishCount' },
        // { name: 'Video của tôi', image: require('../../assets/image/menu/video-mine.png'), url: '/menu/video-mine', mission: 'VideoVerifyCount' },
        // { name: 'Xét duyệt', image: require('../../assets/image/menu/video-vote.png'), url: '/menu/video-vote', notify: 'VideoVoteCount' },
        { name: 'Thư viện', image: require('../../assets/image/menu/video.png'), url: '/menu/video', mission: 'VideoDiamondCount' },
        // { name: 'Vinh danh', image: require('../../assets/image/menu/video-bonus.png'), url: '/menu/video-bonus' },
        // { name: 'Tìm kiếm', image: require('../../assets/image/menu/brand-find.png'), url: '/menu/video-search' },
    ]

    charge = [
        { name: 'Chứng khoán', image: require('../../assets/image/menu/charge-invest.png'), url: '/menu/charge-invest', mission: 'BuyInvestCount' },
        { name: 'Tiêu dùng', image: require('../../assets/image/menu/charge-market.png'), url: '/menu/charge-market' },
        { name: 'Thuê cửa hàng', image: require('../../assets/image/menu/charge-sell.png'), url: '/menu/charge-sell' },
        { name: 'Chợ thực phẩm', image: require('../../assets/image/menu/charge-food.png'), url: '/menu/charge-food' },
        { name: 'Chợ vật liệu', image: require('../../assets/image/menu/charge-asset.png'), url: '/menu/charge-asset' },
        { name: 'Tiệm kim hoàn', image: require('../../assets/image/menu/charge-asset-market.png'), url: '/menu/charge-asset-market' },
        { name: 'Chợ Gold[z]', image: require('../../assets/image/menu/charge-blackmarket.png'), url: '/menu/charge-blackmarket', isCheckZcrazy: true },
        { name: 'Quà thần bí', image: require('../../assets/image/menu/gift-spin.png'), url: '/menu/gift-spin', mission: 'GiftSpinCount' },
        { name: 'Chuyển nhượng', image: require('../../assets/image/menu/charge.png'), url: '/menu/charge', isCheckZcrazy: true },
        // { name: 'Siêu trí nhớ', image: require('../../assets/image/menu/question-lobby.png'), url: '/menu/question-lobby' },
        { name: 'Tu luyện', image: require('../../assets/image/menu/question-train.png'), url: '/menu/gift-train', mission: 'GiftTrainCount' },
        { name: 'Đấu trường', image: require('../../assets/image/menu/question-fight.png'), url: '/question-fight', mission: 'FightCount' },
        { name: 'Phá phong ấn', image: require('../../assets/image/menu/gift-ticket.png'), url: '/menu/gift-ticket', mission: 'GiftTicketCount', isCheckZcrazy: true },
    ]

    clubMember = [
        { name: 'Đào tạo', image: require('../../assets/image/menu/club-info.png'), url: '/menu/club-info' },
        { name: 'Thành viên', image: require('../../assets/image/menu/club-member.png'), url: '/menu/club-member' },
        { name: 'Giao lưu', image: require('../../assets/image/menu/club-feast.png'), url: '/menu/club-feast', mission: 'FeastClubCount' },
        { name: 'Phát lộc', image: require('../../assets/image/menu/club-fortune.png'), url: '/menu/club-fortune', notify: 'FortuneClubCount' },
        { name: 'Thăng hạng', image: require('../../assets/image/menu/club-level.png'), url: '/menu/club-level', mission: 'ContributeClubCount' },
        { name: 'Danh sách Club', image: require('../../assets/image/menu/club-list.png'), url: '/menu/club-list' },
    ]

    club = [
        { name: 'Tạo Club mới', image: require('../../assets/image/menu/club-info.png'), url: '/menu/club-create' },
        { name: 'Danh sách Club', image: require('../../assets/image/menu/club-list.png'), url: '/menu/club-list' },
    ]

    question = [
        // { name: 'Post Question', image: require('../../assets/image/menu/question-own.png'), url: '/menu/question-own', mission: 'QuestionAddCount' },
        // { name: 'Browse Questions', image: require('../../assets/image/menu/question-vote.png'), url: '/menu/question-vote', notify: 'QuestionVoteCount' },
        { name: 'Question Bank', image: require('../../assets/image/menu/question-list.png'), url: '/menu/question-list' },
        { name: 'New day Gifts', image: require('../../assets/image/menu/gift-open.png'), url: '/menu/gift-day', mission: 'GiftDayCount' },
        { name: 'Event Gifts', image: require('../../assets/image/menu/question-event.png'), url: '/menu/gift-event', mission: 'GiftEventCount' },
        { name: 'Growth Gifts', image: require('../../assets/image/menu/gift-effort.png'), url: '/menu/gift-effort', mission: 'GiftEffortCount' },
    ]

    book = [
        { name: 'Đăng review sách', image: require('../../assets/image/menu/book-review-publish.png'), url: '/menu/book-review-publish', mission: 'ReviewBookCount' },
    ]

    course = [
        { name: 'Tạo khoá học', image: require('../../assets/image/menu/course-create.png'), url: '/menu/course-create' },
        { name: 'Tạo mã ưu đãi', image: require('../../assets/image/menu/course-promotion.png'), url: '/menu/course-promotion' },
        { name: 'Học viên', image: require('../../assets/image/menu/course-manage.png'), url: '/menu/course-manage' },
        { name: 'Các khoá học', image: require('../../assets/image/menu/course-list.png'), url: '/menu/course-list', notify: 'CourseCount' },
        { name: 'Khoá đã học', image: require('../../assets/image/menu/course-own.png'), url: '/menu/course-own' },
        // { name: 'QR checkin', image: require('../../assets/image/menu/question-fight.png') },
    ]

    setting = [
        { name: 'Thông tin', image: require('../../assets/image/menu/setting-profile.png'), url: '/menu/setting-profile' },
        { name: 'Bảo mật', image: require('../../assets/image/menu/setting-secure.png'), url: '/menu/setting-secure' },
        // { name: 'Hỗ trợ - phản hồi', image: require('../../assets/image/menu/question-fight.png') },
        { name: 'Lịch sử hoạt động', image: require('../../assets/image/menu/setting-history.png'), url: '/menu/setting-history' },
        // { name: 'Tin tức', image: require('../../assets/image/menu/question-fight.png') },
        { name: 'Hình nền', image: require('../../assets/image/menu/config-wallpaper.png'), url: '/menu/config-wallpaper' },
        { name: 'Tải Zcity', image: require('../../assets/image/menu/config-download.png') },
    ]

    listScreen = [
        // { title: 'Câu hỏi khởi nghiệp', data: this.askQuestion },
        // ... (this.props.dataUser.UserId == 2 ? [
        //     { title: 'Bài viết hay', data: this.post },
        //     { title: 'Video hay', data: this.video },
        // ] : []),
        // { title: 'Sách khởi nghiệp', data: this.book },
        { title: 'Hoạt động tương tác', data: this.brand },
        { title: 'Daily English', data: this.question },
        { title: 'Club địa phương', data: this.props.dataUser.ClubId > 0 ? this.clubMember : this.club },
        { title: 'Kỹ năng tài chính', data: this.charge },
        // { title: 'Quà cao thủ', data: this.gift },
        // { title: 'Mở lớp học', data: this.course },
        { title: 'Cài đặt', data: this.setting },
    ]

    getCrest = () => {
        if (!this.state.isPress) {
            this.setState({ isPress: true });
            getCrestApi().then(res => {
                if (res.code == 1) {
                    let achievement = res.achievement;
                    if (achievement > 0) {
                        showNotificationBonus(achievement, 1, 4);
                        this.props.changePoint([null, null, null, achievement]);
                    }
                    this.props.getUserBar();
                }
                showNotification(res.message, res.code);
                this.setState({ isPress: false });
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false });
            })
        }
    }

    renderMenu = () => {    
        return (
            <>
                {this.props.countMission.SumMission == 0 ?
                <div>
                    <p className='bold text-center' style={{marginBottom: '0.5vh', 'borderRadius': 0}}>
                        Bạn đã hoàn thành thử thách ngày hôm nay
                    </p>
                    <div className='item-center' style={{marginBottom: '0.5vh'}}>
                        <Button
                            className='bg-success shadow-pulse'
                            onClick={this.getCrest}
                            loading={this.state.isPress}
                        >
                            Nhận thưởng
                        </Button>
                    </div>
                </div>
            :
                <p className='bold text-center' style={{marginBottom: '0.5vh', 'borderRadius': 0}}>
                    Vượt qua {this.props.countMission.SumMission} thử thách để sở hữu huy chương Z và 1000 thành tựu
                </p>
            }
                {/* <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-6'>
                    <ItemMenu
                        countMission={this.props.countMission}
                        countNotify={this.props.countNotify}
                        dataMenu={{
                            name: 'Tu luyện',
                            image: require('../../assets/image/menu/question-train.png'),
                            url: '/menu/gift-train',
                            mission: 'GiftTrainCount'
                        }}
                    />
                </div>
                <div className='col-md-6 col-sm-6 col-xs-6'>
                    <ItemMenu
                        countMission={this.props.countMission}
                        countNotify={this.props.countNotify}
                        dataMenu={{
                            name: 'Đấu trường Z',
                            image: require('../../assets/image/menu/question-fight.png'),
                            url: '/menu/question-fight',
                            mission: 'FightCount'
                        }}
                    />
                </div>
            </div> */}
                {this.listScreen.map((item, key) =>
                    <div key={key}>
                        <div className='title-menu'>{item.title}</div>
                        <div className='row'>
                            {item.data.map((dataMenu, k) =>
                                <div className='col-md-2 col-sm-3 col-xs-4' key={k}>
                                    <ItemMenu
                                        countMission={this.props.countMission}
                                        countNotify={this.props.countNotify}
                                        dataMenu={dataMenu}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    {...PROP.MODAL}
                    visible={this.state.showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    width={700}
                >
                    <p className='bold title-success text-center mgt'>
                        PHẦN THƯỞNG HUY CHƯƠNG Z
                    </p>
                    <div className='bonus-crest-container'>
                        <div className='flex-row item-center'>
                            <img className='bonus-crest-img' key={1} src={require('../../assets/image/crest.png')} />
                        </div>
                        <p className='text-large text-center mgt'>Nhận được thêm 1000 thành tựu</p>
                    </div>
                    {/* <div className='bonus-crest-container'>
                    <div className='flex-row item-center'>
                        <img className='bonus-crest-img' key={1} src={require('../../assets/image/crest.png')}/>
                    </div>
                    <p className='text-huge text-center bold mgt'>Sở hữu 1 huy chương:</p>
                    <p className='text-large mgt'>Giải thưởng đợt 1 dành cho 30 người đầu tiên:</p>
                    <p className='text-large mgt'><i>- Phần thưởng 2 tập bộ sách “Bí mật tài chính người giàu” bản VIP ghi tên cá nhân hoá.</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng 1 mâm 6 người dành cho bạn và gia đình tại Nhà Hàng Tam Chúc khi đi du lịch tại Chùa Tam Chúc - Hà Nam.</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng Phòng nghỉ 2 ngày 1 đêm Khách Sạn Thành Lợi và bữa ăn tại nhà hàng S-Hải Tiến cho 2 người dành cho bạn và người thương du lịch hè tại biển Hải Tiến - Thanh Hoá.</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng sử dụng Kim Chi Ma Ma trong 1 năm (Gói 1 kg/ tuần).</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng 1 đôi thiết kế mới nhất của thương hiệu giày Goya - hàng hiệu giá bình dân 800.000 đ/đôi.</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng 1 năm uống bia tại nhà hàng Sòi 3 - 200 Đường Cây Trâm, Phường 9, Gò Vấp, Hồ Chí Minh(2 lon /ngày)</i></p>
                </div>
                <div className='bonus-crest-container'>
                    <div className='flex-row item-center'>
                        {new Array(3).fill(undefined).map((i, key) =>
                            <img className='bonus-crest-img' key={key} src={require('../../assets/image/crest.png')}/>
                        )}
                    </div>
                    <p className='text-huge text-center bold mgt'>Sở hữu 3 huy chương:</p>
                    <p className='text-large mgt'><i>- Phần thưởng 01 vé Tiêu Chuẩn môn học Kiến Thức Nền.</i></p>
                    <p className='text-large text-center mgt'>(<a href='https://kienthucnen.com' target='_blank' rel='noopener noreferrer'>kienthucnen.com</a>)</p>
                    <p className='text-large text-center mgt'>Người chiến thắng: Nguyễn Văn Công</p>
                </div>
                <div className='bonus-crest-container'>
                    <div className='flex-row item-center'>
                        {new Array(5).fill(undefined).map((i, key) =>
                            <img className='bonus-crest-img' key={key} src={require('../../assets/image/crest.png')}/>
                        )}
                    </div>
                    <p className='text-huge text-center bold mgt'>Sở hữu 5 huy chương:</p>
                    <p className='text-large mgt'><i>- Phần thưởng 1 bộ Vest doanh nhân hoặc vest cưới do tự tay nghệ nhân Nguyễn Tứ Quý may đo, người chuyên may vest cho doanh nhân, ca sĩ, nghệ sĩ, nguyên thủ các nước.</i></p>
                    <p className='text-large mgt'><i>- Phần thưởng nâng cấp vé Tiêu chuẩn lên vé VIP môn học Kiến Thức Nền.</i></p>
                    <p className='text-large text-center mgt'>(<a href='https://kienthucnen.com' target='_blank' rel='noopener noreferrer'>kienthucnen.com</a>)</p>
                </div> */}
                </Modal>
            </>
        )
    }

    render() {
        return this.renderMenu();
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { countMission, countNotify } = state.getCountMission;
    return { dataUser, countMission, countNotify };
}

export default connect(mapStateToProps, { updateDataUser, getUserBar, changePoint })(withRouter(MainMenu));