import React, { Component } from 'react';
import NavRight from '../logined/NavRight';
import NavBar from './NavBar';
import { getUserData } from '../../../actions/home';
import { getCategory } from '../../../actions/category/';
import { connect } from 'react-redux';
import Loading from '../../Loading';
import ReadEventSocket from '../../realtime/ReadSocketEvent';

class Admin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.isLoading) return (
            <div className='nav-center'>
                <Loading />
            </div>
        );
        return (
            <>
                <div className='nav-center' style={{flex: 10}}>
                    <NavBar />
                    <div className='main-content'>
                        {this.props.children}
                    </div>
                </div>
                <NavRight />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { isLoading } = state.getUserData;
    const { showModalEat } = state.eatModal;
    return { isLoading, showModalEat };
}

export default connect(mapStateToProps, { getUserData, getCategory })(Admin);