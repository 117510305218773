export const showModalBuyVip = () =>{
    return dispatch => {
        dispatch({ type: 'SHOW_MODAL_BUY_VIP'});
    }
}

export const closeModalBuyVip = () =>{
    return dispatch => {
        dispatch({ type: 'CLOSE_MODAL_BUY_VIP' });
    }
}