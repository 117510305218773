import React, { Component } from 'react';
import { Button } from 'antd';
import { priceFormat } from '../../../utils/common';
import {withRouter} from 'react-router-dom';
import TooltipUser from '../../../components/TooltipUser';
import Checked from '../../frame/Checked';
import { connect } from 'react-redux';

class Minigame extends Component{
    render(){
        const {dataUser} = this.props;
        let contentLanguage = 'QouteContent';
        let ipaLanguage = 'Ipa';
        if(dataUser.Language == 2){
            contentLanguage = 'QouteContentJa';
            ipaLanguage = 'IpaJa';
        }
        else if(dataUser.Language == 3){
            contentLanguage = 'QouteContentKo';
            ipaLanguage = 'IpaKo';
        }
        else if(dataUser.Language == 4){
            contentLanguage = 'QouteContentCn';
            ipaLanguage = 'IpaCn';
        }
        else if(dataUser.Language == 5){
            contentLanguage = 'QouteContentSp';
            ipaLanguage = 'IpaSp';
        }
        else if(dataUser.Language == 6){
            contentLanguage = 'QouteContentGe';
            ipaLanguage = 'IpaGe';
        }
        else if(dataUser.Language == 7){
            contentLanguage = 'QouteContentFr';
            ipaLanguage = 'IpaFr';
        }
        else if(dataUser.Language == 8){
            contentLanguage = 'QouteContentRu';
            ipaLanguage = 'IpaRu';
        }
        else if(dataUser.Language == 9){
            contentLanguage = 'QouteContentAr';
            ipaLanguage = 'IpaAr';
        }
        const data = this.props.data;
        return(
            <div>
                <div className='flex-row'>
                    <div>
                        <TooltipUser userInfo={data} avatarClass='avatar-guest'/>
                    </div>
                    <div className='flex-column mgl-2'>
                        <p><span className='bold'>{data.FullName}
                        <Checked data={data} />
                        </span> đã xuất sắc đạt hạng {data.Rank} [Ngày {data.CurrentDay} | Minigame lần {data.Round || '?'}]!</p>
                        <p className='silver'>{data.CrDateTime}</p>
                    </div>
                </div>
                <div className='flex-column item-center'>
                    <p className='mgt qoute-gift'>{'"' + data[contentLanguage]?.replace(/。\//g, '。/\n') + '"'}</p>
                    <p className='mgt qoute-gift'>{'"' + data.QouteTranslate + '"'}</p>
                    <p className='qoute-ipa mgt-2'>{data[ipaLanguage]}</p>
                    <p
                        className='qoute-gift mgt-3'
                        style={{paddingRight: '4vh'}}
                    >
                        Tác giả: {data.Author}
                    </p>
                    <Button
                        className='newfeed-button mgt-2'
                        onClick={() => this.props.history.push('/menu/gift-day')}
                    >
                        Minigame dậy sớm
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(withRouter(Minigame));