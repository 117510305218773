import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import HeaderLottie from '../../components/HeaderLottie';
import empty from '../../assets/lottie/empty.json';
import {getListNewfeed, getListNewfeedUser, getListNewfeedLobby, changeDataNewfeed, clearNewfeedData} from '../../actions/newfeed';
import { withRouter } from 'react-router';
import ListComponent from './item';
import Comment from './comment';

function NewfeedBlock(props){
    let dataNewfeed = props.dataNewfeed;
    switch (dataNewfeed.NewfeedType) {
        case '1': // quà điểm danh
            return <ListComponent.GiftPresent data={dataNewfeed} />
        case '2': // quà tân thủ + phát vốn
            return <ListComponent.GiftDay data={dataNewfeed} />
        case '3': // quà tăng trưởng
            return <ListComponent.GiftEffort data={dataNewfeed} />
        case '4': // tỷ phú
            return <ListComponent.GiftSpin data={dataNewfeed} />
        case '5': // xây nhà, vương miện thành công
            return <ListComponent.AssetSuccess data={dataNewfeed} />
        case '6': // xây nhà, vương miện thất bại
            return <ListComponent.AssetFail data={dataNewfeed} />
        case '7': // mở quà ng khác tặng
            return <ListComponent.GiftOffer data={dataNewfeed} />
        case '8': // thắng đấu trường
            return <ListComponent.QuestionWin data={dataNewfeed} />
        case '9': // update status
            return <ListComponent.UpdateStatus data={dataNewfeed} />
        case '10': // cổ phiếu
            return <ListComponent.Invest data={dataNewfeed} />
        case '11': // post
            return <ListComponent.Post data={dataNewfeed} />
        case '12': // video
            return <ListComponent.Video data={dataNewfeed} />
        case '13': // thêm câu hỏi
            return <ListComponent.QuestionAdd data={dataNewfeed} />
        case '14': // review sách
            return <ListComponent.BookReview data={dataNewfeed} />
        case '15': // tạo Club
            return <ListComponent.ClubCreate data={dataNewfeed} />
        case '16': // tạo câu hỏi ask-question
            return <ListComponent.AskQuestion data={dataNewfeed} />
        case '17': // huy chương Z
            return <ListComponent.Medal data={dataNewfeed} />
        case '18': // Minigame
            return <ListComponent.MiniGame data={dataNewfeed} />
        default:
            break;
    }
    return null;
}

class Main extends Component {
    loadMore = () =>{
        if(this.props.guest)
            this.props.getListNewfeedLobby(this.props.page + 1, this.props.typeNewfeed);
        else if(this.props.userId > 0)
            this.props.getListNewfeedUser(this.props.page + 1, this.props.userId);
        else
            this.props.getListNewfeed(this.props.page + 1, this.props.typeNewfeed);
    }

    render(){
        let {isLoading, endList, listNewfeed} = this.props;
        if(isLoading) return <Loading small />;
        return(
            <div>
                {listNewfeed.length == 0 ?
                    <div className='item-center'>
                        <HeaderLottie source={empty} disableLoop />
                        <p className='text-large text-center mgt'>Không có hoạt động gần đây</p>
                    </div>
                :
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={!endList}
                        loader={<Loading key={0} small/>}
                        threshold={20}
                    >
                        {listNewfeed.map((item, key) =>
                            <div className='box-primary mgb' key={key}>
                                <NewfeedBlock dataNewfeed={item} />
                                {!this.props.guest &&
                                    <Comment data={item} />
                                }
                            </div>
                        )}
                    </InfiniteScroll>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { isLoading, listNewfeed, page, typeNewfeed, endList } = state.getListNewfeed;
    return { dataUser, isLoading, listNewfeed, page, typeNewfeed, endList };
}

export default connect(mapStateToProps, {getListNewfeed, getListNewfeedUser, changeDataNewfeed, clearNewfeedData, getListNewfeedLobby})(withRouter(Main));