import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Switch, TimePicker } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import 'react-quill/dist/quill.snow.css';
import Loading from '../../components/Loading';
import { TitleSuccess } from '../../components/frame';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getContentApi, getValueInvestApi, saveContentApi, saveDiscordLinkApi, saveValueInvestApi, saveYoutubeLinkApi } from '../../api/admin/zcrazy';
import ButtonGroup from 'antd/lib/button/button-group';
import { getDiscordLinkApi } from '../../api/menu';
import { deleteMiniGameApi, getListMiniGameApi, toggleActiveMiniGameApi, updateMiniGameApi } from '../../api/admin/minigame';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { getYoutubeTheoryApi } from '../../api/theory';
import Notification from './Notification';
import { getMiniGameRandomApi, updateMiniGameRandomApi } from '../../api/admin/minigamerandom';

class ZCrazy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            editorState: EditorState.createEmpty(),
            selectTab: 1,
            youtubeLink1: '',
            youtubeLink2: '',
            inputInvestPercentUp: '',
            inputInvestPercentDown: '',
            listMiniGame: [],
            showModalUpdateMiniGame: false,
            inputTitle: '',
            inputStartDate: '',
            inputEndDate: '',
            isPress: false,
            miniGameSelect: {},
            inputRound: '',
            miniGameRandom: {},
            inputTitleMiniGameRandom: '',
            inputStartTimeMiniGameRandom: '',
            inputEndTimeMiniGameRandom: '',
            inputResetTimeMiniGameRandom: '',
            isActiveMiniGameRandom: false,
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    getContentAdmin = () => {
        getContentApi().then(res => {
            const html = res.data?.Content
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState, isLoading: false })
            }
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    saveNotif = () => {
        const content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        saveContentApi(content).then(res => {
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    saveYoutubeLink = () => {
        saveYoutubeLinkApi(this.state.youtubeLink1, this.state.youtubeLink2).then(res => {
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    saveInvestValue = () => {
        saveValueInvestApi(this.state.inputInvestPercentUp, this.state.inputInvestPercentDown).then(res => {
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    handleChangeStateMiniGame = (state, miniGame) => {
        const isActive = state ? '1' : '0';
        toggleActiveMiniGameApi(isActive, miniGame.MiniGameId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listMiniGame: this.state.listMiniGame.map(item => {
                        if (item.MiniGameId == miniGame.MiniGameId) {
                            return ({
                                ...item,
                                IsActive: res.isActive
                            })
                        }
                        return item
                    })
                })
            }
            showNotification(res.message, res.code)
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    deleteMiniGame = (miniGame) => {
        if (window.confirm(`Xác nhận xoá "${miniGame.Title}"`)) {
            deleteMiniGameApi(miniGame.MiniGameId).then(res => {
                if (res.code == 1) {
                    this.setState({
                        listMiniGame: this.state.listMiniGame.filter(item => item.MiniGameId != miniGame.MiniGameId)
                    })
                }
                showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    updateMiniGame = () => {
        const { inputTitle, inputStartDate, inputEndDate, isPress, miniGameSelect, inputRound } = this.state;
        if (!isPress) {
            this.setState({isPress: true})
            updateMiniGameApi({
                Title: inputTitle,
                Round: inputRound,
                StartDate: moment(inputStartDate).format('YYYY-MM-DD'),
                EndDate: moment(inputEndDate).format('YYYY-MM-DD'),
                MiniGameId: miniGameSelect.MiniGameId,
            }).then(res => {
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.getListMiniGame();
                    this.setState({
                        showModalUpdateMiniGame: false,
                        miniGameSelect: {}
                    })
                }
                showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    handleSaveMiniGameRandom = () => {
        const {inputTitleMiniGameRandom, inputEndTimeMiniGameRandom, inputStartTimeMiniGameRandom, isActiveMiniGameRandom, inputResetTimeMiniGameRandom,isPress} = this.state
        if (!isPress) {
            if(moment(inputResetTimeMiniGameRandom, 'HH:mm').minutes() % 5 != 0){
                return showNotification("Số phút phải chia hết cho 5", 0);
            }
            this.setState({isPress: true})
            updateMiniGameRandomApi({
                Title: inputTitleMiniGameRandom,
                StartTime: inputStartTimeMiniGameRandom,
                EndTime: inputEndTimeMiniGameRandom,
                ResetTime: inputResetTimeMiniGameRandom,
                IsActive: isActiveMiniGameRandom ? '1' : 0,
            }).then(res => {
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.getMiniGameRandom();
                }
                showNotification(res.message, res.code)
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    renderModalMiniGame = () => {
        const { inputTitle, inputStartDate, inputEndDate, miniGameSelect, inputRound } = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalUpdateMiniGame}
                onCancel={() => this.setState({ showModalUpdateMiniGame: false })}
                centered
            >
                <TitleSuccess>{miniGameSelect.MiniGameId ? "Sửa" : "Thêm"} minigame</TitleSuccess>
                <p>Tiêu đề:</p>
                <input
                    className='form-control mgt'
                    value={inputTitle}
                    onChange={e => this.setState({ inputTitle: e.target.value })}
                    placeholder='Nhập tiêu đề'
                />
                <p className='mgt'>Ngày bắt đầu:</p>
                <div className='flex align-center date-study-picker'>
                    <ReactDatePicker
                        selected={inputStartDate}
                        onChange={inputStartDate => this.setState({ inputStartDate })}
                        dateFormat="d-M-yyyy"
                        placeholderText='Chọn ngày bắt đầu'
                        className='text-left'
                    />
                </div>
                <p className='mgt'>Ngày kết thúc:</p>
                <div className='flex align-center date-study-picker'>
                    <ReactDatePicker
                        selected={inputEndDate}
                        onChange={inputEndDate => this.setState({ inputEndDate })}
                        dateFormat="d-M-yyyy"
                        placeholderText='Chọn ngày kết thúc'
                        className='text-left'
                    />
                </div>
                <p className='mgt'>Vòng:</p>
                <div className='flex align-center date-study-picker'>
                    <input
                        className='form-control mgt'
                        value={inputRound}
                        onChange={e => this.setState({ inputRound: e.target.value })}
                        placeholder='Nhập số vòng'
                        type='number'
                    />
                </div>
                <div className='item-center'>
                    <Button
                        className='bg-success mgt-3'
                        onClick={() => this.updateMiniGame()}
                    >
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        )
    }

    renderTab = () => {
        const {
            selectTab,
            isLoading,
            editorState,
            listMiniGame,
            youtubeLink1,
            youtubeLink2,
            inputTitleMiniGameRandom,
            inputEndTimeMiniGameRandom,
            inputStartTimeMiniGameRandom,
            isActiveMiniGameRandom,
            inputResetTimeMiniGameRandom,
            isPress
        } = this.state;
        switch (selectTab) {
            case 1:
                return (
                    <>
                        {isLoading ?
                            <Loading small />
                            :
                            <>
                                <div className='row item-center mgt-2'>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <p className='mgr-2 mgb-0'>Kích hoạt:</p>
                                        <Switch
                                            checked={isActiveMiniGameRandom}
                                            onChange={(state) => this.setState({ isActiveMiniGameRandom: state })}
                                        />
                                    </div>
                                </div>
                                <div className='row item-center mgt-2'>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <input
                                            className='form-control mgt'
                                            value={inputTitleMiniGameRandom}
                                            onChange={e => this.setState({ inputTitleMiniGameRandom: e.target.value })}
                                            placeholder='Nhập tiêu đề'
                                        />
                                    </div>
                                </div>
                                <div className='row item-center mgt-2'>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <p className='mgr-2 mgb-0' style={{width: '12vh'}}>Giờ bắt đầu:</p>
                                        <TimePicker
                                            ref={e => this.timePiclerStart = e}
                                            value={moment(inputStartTimeMiniGameRandom, "HH:mm:ss")}
                                            format={"HH:mm"}
                                            onChange={(time, timeString) => this.setState({ inputStartTimeMiniGameRandom: timeString })}
                                        />
                                    </div>
                                </div>
                                <div className='row item-center mgt-2'>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <p className='mgr-2 mgb-0' style={{width: '12vh'}}>Giờ kết thúc:</p>
                                        <TimePicker
                                            ref={e => this.timePiclerEnd = e}
                                            value={moment(inputEndTimeMiniGameRandom, "HH:mm:ss")}
                                            format={"HH:mm"}
                                            onChange={(time, timeString) => this.setState({ inputEndTimeMiniGameRandom: timeString })}
                                        />
                                    </div>
                                </div>
                                <div className='row item-center mgt-2'>
                                    <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                        <p className='mgr-2 mgb-0' style={{width: '12vh'}}>Giờ tạo KQ:</p>
                                        <TimePicker
                                            ref={e => this.timePiclerEnd = e}
                                            value={moment(inputResetTimeMiniGameRandom, "HH:mm:ss")}
                                            format={"HH:mm"}
                                            onChange={(time, timeString) => this.setState({ inputResetTimeMiniGameRandom: timeString })}
                                            minuteStep={5}
                                        />
                                    </div>
                                </div>
                                <div className='item-center mgt-3'>
                                    <Button loading={isPress} className='bg-success' onClick={this.handleSaveMiniGameRandom}>Lưu lại</Button>
                                </div>
                            </>
                        }
                    </>
                )
            case 2:
                return (
                    <>
                        <div className='item-center'>
                            <Button
                                className='bg-success'
                                onClick={() => this.setState({ showModalUpdateMiniGame: true, miniGameSelect: {} })}
                            >
                                Thêm minigame
                            </Button>
                        </div>
                        {isLoading ?
                            <Loading small />
                            :
                            <>
                                {listMiniGame.map((item, key) =>
                                    <div className='row box-primary mgt' key={item.MiniGameId}>
                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                            <p>{item.Title}</p>
                                            <div className='flex align-center'>
                                                <p className='mgb-0 mgr'>Kích hoạt:</p>
                                                <Switch
                                                    checked={item.IsActive > 0}
                                                    onChange={(state) => this.handleChangeStateMiniGame(state, item)}
                                                />
                                            </div>
                                            <p className='mgb-0 mgr'>Vòng: {item.Round}</p>
                                        </div>
                                        <div className='col-md-3 col-sm-3 col-xs-12 flex flex-column'>
                                            <p>Bắt đầu: {moment(item.StartDate).format('DD/MM/YYYY')}</p>
                                            <p>Kết thúc: {moment(item.EndDate).format('DD/MM/YYYY')}</p>
                                        </div>
                                        <div className='col-md-3 col-sm-3 col-xs-12 flex align-center' style={{ justifyContent: 'flex-end' }}>
                                            <div className='flex-row'>
                                                <img
                                                    src={require('../../assets/image/pen.png')}
                                                    style={{ width: '3vh' }}
                                                    className='pointer mgr'
                                                    onClick={() => {
                                                        this.setState({
                                                            showModalUpdateMiniGame: true,
                                                            miniGameSelect: item,
                                                            inputTitle: item.Title,
                                                            inputStartDate: moment(item.StartDate).toDate(),
                                                            inputEndDate: moment(item.EndDate).toDate(),
                                                            inputRound: item.Round,
                                                        })
                                                    }}
                                                />
                                                <img
                                                    src={require('../../assets/image/delete-red.png')}
                                                    style={{ width: '3vh' }}
                                                    className='pointer mgl'
                                                    onClick={() => this.deleteMiniGame(item)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                        {this.renderModalMiniGame()}
                    </>
                )
            case 3:
                return (
                    <>
                        {isLoading ?
                            <Loading small />
                            :
                            <>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="editor-admin"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                                <div className='item-center'>
                                    <Button
                                        className='bg-success mgt-3'
                                        onClick={this.saveNotif}
                                    >
                                        Lưu lại
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                )
            case 4:
                return (
                    <>
                        <p>Youtube Link1</p>
                        <textarea
                            className='form-control'
                            style={{ height: '22vh', lineHeight: '3vh' }}
                            value={youtubeLink1}
                            onChange={e => this.setState({ youtubeLink1: e.target.value })}
                        />
                        <p className='mgt'>Youtube Link2</p>
                        <textarea
                            className='form-control'
                            style={{ height: '22vh', lineHeight: '3vh' }}
                            value={youtubeLink2}
                            onChange={e => this.setState({ youtubeLink2: e.target.value })}
                        />
                        <div className='item-center'>
                            <Button
                                className='bg-success mgt-3'
                                onClick={this.saveYoutubeLink}
                            >
                                Lưu lại
                            </Button>
                        </div>
                    </>
                )
            case 5:
                return (
                    <>
                        <TitleSuccess>Phần trăm random cổ tức {'>'} 0</TitleSuccess>
                        <div className='item-center'>
                            <input
                                className='form-control col-md-4'
                                value={this.state.inputInvestPercentUp}
                                onChange={e => this.setState({ inputInvestPercentUp: e.target.value })}
                                onBlur={this.saveInvestValue}
                            />
                        </div>
                        <TitleSuccess>Phần trăm random cổ tức {'<'} 0</TitleSuccess>
                        <div className='item-center'>
                            <input
                                className='form-control col-md-4'
                                value={this.state.inputInvestPercentDown}
                                onChange={e => this.setState({ inputInvestPercentDown: e.target.value })}
                                onBlur={this.saveInvestValue}
                            />
                        </div>
                    </>
                )
            case 6:
                return <Notification />
        }
    }

    getYoutubeTheory = () => {
        getYoutubeTheoryApi().then(res => {
            if (res.code == 1) {
                const links = res.data;
                this.setState({
                    youtubeLink1: links?.[0] || '',
                    youtubeLink2: links?.[1] || '',
                })
            }
        })
    }

    getValueInvest = () => {
        getValueInvestApi().then(res => {
            this.setState({
                inputInvestPercentUp: parseFloat(res.data.PercentUp),
                inputInvestPercentDown: parseFloat(res.data.PercentDown)
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getListMiniGame = () => {
        getListMiniGameApi().then(res => {
            this.setState({
                listMiniGame: res.listMiniGame,
                isLoading: false
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    getMiniGameRandom = () => {
        getMiniGameRandomApi().then(res => {
            this.setState({
                miniGameRandom: res.miniGame,
                isLoading: false,
                inputTitleMiniGameRandom: res.miniGame.Title,
                inputEndTimeMiniGameRandom: res.miniGame.EndTime,
                inputStartTimeMiniGameRandom: res.miniGame.StartTime,
                inputResetTimeMiniGameRandom: res.miniGame.ResetTime,
                isActiveMiniGameRandom: res.miniGame.IsActive > 0
            })
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    componentDidMount() {
        this.getMiniGameRandom();
    }

    render() {
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 }, this.getListMiniGame)} className={this.buttonGroupClass(1)}>MiniGame hằng ngày</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 }, this.getListMiniGame)} className={this.buttonGroupClass(2)}>MiniGame hằng tuần</Button>
                    <Button onClick={() => this.setState({ selectTab: 3 }, this.getContentAdmin)} className={this.buttonGroupClass(3)}>Thông báo</Button>
                    <Button onClick={() => this.setState({ selectTab: 4 }, this.getYoutubeTheory)} className={this.buttonGroupClass(4)}>Hướng dẫn</Button>
                    <Button onClick={() => this.setState({ selectTab: 5 }, this.getValueInvest)} className={this.buttonGroupClass(5)}>Cổ phiếu</Button>
                    <Button onClick={() => this.setState({ selectTab: 6 }, this.getValueInvest)} className={this.buttonGroupClass(6)}>App Notification</Button>
                </ButtonGroup>
                {this.renderTab()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(ZCrazy)