import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint, getUserData } from '../../actions/home/';
import HeaderLottie from '../../components/HeaderLottie';
import club from '../../assets/lottie/club.json';
import {getClubCreateCostApi, createClubApi} from '../../api/club/create';
import { showNotification, showNotificationBonus, showNotificationProperty } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import Loading from '../../components/Loading';
import { formatDecimal } from '../../utils/common';
import {Button} from 'antd';
import {withRouter} from 'react-router-dom';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';

class ClubCreate extends Component{
    constructor(props){
        super(props);
        this.state={
            clubCreateCost: 0,
            isLoading: true,
            clubName: '',
            clubDesc: '',
            isPress: false
        }
    }

    getClubCreateCost= () =>{
        getClubCreateCostApi().then(res =>{
            if(res.code == 1){
                this.setState({
                    clubCreateCost: res.clubCreateCost,
                    isLoading: false
                })
            }
            else showNotification(res.message, res.code);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    createClub = ()=>{
        let {clubName, clubDesc, clubCreateCost, isPress} = this.state;
        let idNotification = '#notificationCreateClub';
        if(!isPress && clubName != ''){
            this.setState({isPress: true});
            createClubApi(clubName, clubDesc).then(res =>{
                if(res.code == 1){
                    showNotificationProperty(res.property);
                    showNotificationBonus(clubCreateCost, -1, 2);
                    this.props.changePoint([null, -clubCreateCost]);
                    this.props.getUserData();
                    setTimeout(() =>{
                        this.props.history.push('/menu/club-info');
                    }, 2000);
                    showNotification(res.message, res.code, idNotification);
                }
                else if (res.code == -99) {
                    this.props.showModalBuyVip();
                }
                else{
                    this.setState({isPress: false});
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            })
        }
        else if(clubName == '') showNotification('Vui lòng nhập tên Club', 0, idNotification);
    }

    componentDidMount(){
        this.getClubCreateCost();
    }

    render(){
        let {isLoading, clubCreateCost, clubName, clubDesc, isPress} = this.state;
        if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={club} disableLoop/>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift'>Tạo Club mới</p>
                </div>
                <p className='text-center text-large mgt-2'>Tên Club</p>
                <div className='row item-center'>
                    <div className='col-md-6 col-sm-8 col-xs-12'>
                        <input
                            value={clubName}
                            onChange={e => this.setState({clubName: e.target.value})}
                            className='form-control text-center'
                            placeholder='Nhập tên Club'
                        />
                    </div>
                </div>
                {clubName &&
                    <div className='flex item-center'>
                        <p className='club-create-title'>Tên hiển thị: {clubName}</p>
                    </div>
                }
                <p className='text-center text-large mgt'>Mô tả ngắn về Club</p>
                <div className='row item-center'>
                    <div className='col-md-6 col-sm-8 col-xs-12'>
                        <textarea
                            value={clubDesc}
                            onChange={e => this.setState({clubDesc: e.target.value})}
                            className='form-control text-center'
                            style={{minHeight: '18vh'}}
                            placeholder='Nhập nội dung'
                        />
                    </div>
                </div>
                <p className='text-center text-large'>Phí tạo Club: {formatDecimal(clubCreateCost)} $[z]</p>
                <div className='flex-column item-center mgt'>
                    <div className='mgt' id='notificationCreateClub'></div>
                    <Button
                        className='bg-success'
                        onClick={this.createClub}
                        loading={isPress}
                    >
                        Tạo Club
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, getUserData, showModalBuyVip})(withRouter(ClubCreate));