import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint, updateDataUser } from '../../actions/home/';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import { Button, Modal, Progress } from 'antd';
import { getUserPresent } from '../../actions/home';
import { updateCountMission } from '../../actions/menu';
import moment from 'moment';
import { TitleSuccess } from '../../components/frame';
import Lottie from 'react-lottie';
import TextAnswer from '../question/TextAnswer';
import ResultMessage from '../question/ResultMessage';
import Loading from '../Loading';
import gift from '../../assets/lottie/gift.json';
import space from '../../assets/lottie/space.json';
import win from '../../assets/lottie/win.json';
import { formatDecimal, speakEng } from '../../utils/common';
import { getListRankApi } from '../../api/minigamerandom/rank';
import TooltipUser from '../TooltipUser';
import ClubDeputy from '../club/ClubDeputy';
import ClubVipLevel from '../club/ClubVipLevel';
import { showModalEat } from '../../actions/home/modalEat';
import { withRouter } from 'react-router-dom';
import { getInfoMiniGameRandomApi, playMiniGameApi, checkAnswerApi } from '../../api/minigamerandom';

class MiniGameRandom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
            miniGame: null,
            showModalRule: false,
            showModalWaitingRank: false,
            showModalQuestion: false,
            showModalWaiting: false,
            showModalPlaySuccess: false,
            showModalRank: false,
            showModalMissing: false,
            questionList: [],
            numberCorrect: 0,
            textTime: '--:--',
            selectAnswerKey: '',
            resultAnswer: false,
            isAnswer: false,
            listRank: [],
            isLoadingRank: true,
            miniGameRandomPlayId: '',
            resultData: {}
        }
        this.timeDiff = 99999;
        this.numberQuestion = 0;
        this.countDown = 99999;
        this.correctAnswer = '';
        this.questionInterval = '';
    }

    renderMiniGame = () => {
        const { miniGame, isPress } = this.state
        if (!miniGame) {
            return null;
        }
        return (
            <>
                <div className='flex-column item-center'>
                    <div className='container-list-gift flex-column' style={{ padding: '2vh', width: '100%' }}>
                        <p className='bold text-title-gift'>{miniGame.Title}</p>
                        <div className='flex-row row mgt-4'>
                            {miniGame.ListResult.map((item) =>
                                <div className='col-md-3 col-sm-3 col-xs-6 container-result-minigame-random' key={item.MiniGameRandomResultId}>
                                    <div className='container-result-minigame-random-box'>
                                        <div className="result-minigame-random-circle">
                                            <p className='text-result-minigame-random'>{item.Result}</p>
                                        </div>
                                        <p>{item.Title}</p>
                                        <div className='result-minigame-random'>
                                            <p className='text-center mgb-0'>{formatDecimal(item.Balance)} vnđ</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex-row row mgt-4'>
                            <div className='col-md-4 col-sm-12 col-xs-12 item-center flex-column'>
                                <Button
                                    className='bg-success mgb button-info-mini-game'
                                    onClick={() => {
                                        this.getListRank();
                                    }}
                                    loading={isPress}
                                >Xem kết quả</Button>
                                <Button
                                    className='bg-warning mgt button-info-mini-game'
                                    onClick={() => this.setState({ showModalRule: true })}
                                    style={{ fontSize: '2vh' }}
                                >
                                    Thể lệ & Phần thưởng
                                </Button>
                            </div>
                            <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                {this.renderButtonPlay()}
                            </div>
                            <div className='col-md-4 col-sm-12 col-xs-12 item-center flex-column'>
                                <p className='text-large'>{moment(miniGame.StartTime, "HH:mm:ss").format("H:mm")} - {moment(miniGame.EndTime, "HH:mm:ss").format("H:mm")}</p>
                                <p className='text-large'>Từ thứ 2 đến thứ 6</p>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalRule()}
                {this.renderModalQuestion()}
                {this.renderModalWait()}
                {this.renderPlaySuccess()}
                {this.renderModalRank()}
                {this.renderModalMissing()}
                {this.renderModalWaitingRank()}
            </>
        )
    }

    renderButtonPlay = () => {
        const { miniGame } = this.state;
        if (miniGame.MiniGameRandomPlayId) {
            return (
                <div className='flex item-center'>
                    <div className="result-minigame-random-circle">
                        <p className='text-result-minigame-random'>{miniGame.MiniGameRandomPlayId}</p>
                    </div>
                </div>
            )
        }
        return (
            <Button
                className={`mgb button-reg-mini-game mgt mgb ${miniGame.IsPlayable ? 'bg-success shadow-pulse' : 'bg-primary'}`}
                onClick={this.playMiniGame}
            >
                Điểm danh
            </Button>
        )
    }

    renderModalWait = () => {
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalWaiting}
                onCancel={() => this.setState({ showModalWaiting: false })}
                centered
            >
                <Lottie options={{ loop: true, autoplay: true, animationData: space }}
                    height='30vh'
                    width='30vh'
                />
                <p className='text-large text-center'>Minigame sẽ tiếp tục vào thứ 2 tuần tới</p>
            </Modal>
        )
    }

    renderModalWaitingRank = () => {
        const { miniGame } = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalWaitingRank}
                onCancel={() => this.setState({ showModalWaitingRank: false })}
                centered
            >
                <Lottie options={{ loop: true, autoplay: true, animationData: space }}
                    height='30vh'
                    width='30vh'
                />
                <p className='text-large text-center'>Bảng kết quả sẽ đc giữ bí mật từ {moment(miniGame.StartTime, "HH:mm:ss").format("H:mm")} - {moment(miniGame.EndTime, "HH:mm:ss").format("H:mm")}</p>
            </Modal>
        )
    }

    renderModalMissing = () => {
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalMissing}
                onCancel={() => this.setState({ showModalMissing: false })}
                centered
            >
                <div className='item-center'>
                    <img
                        className='minigame-fail-img'
                        src={require('../../assets/image/minigame-fail.png')}
                    />
                </div>
                <p className='text-large text-center mgt-3'>Ngủ quên rồi</p>
            </Modal>
        )
    }

    renderPlaySuccess = () => {
        const { miniGame, resultData } = this.state;
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalPlaySuccess}
                onCancel={() => this.setState({ showModalPlaySuccess: false })}
                width='750px'
                centered
            >
                <Lottie options={{ loop: true, autoplay: true, animationData: win }}
                    height='30vh'
                    width='30vh'
                />
                <p className='mgt-4 text-center bold text-large'>Xin chúc mừng! Số thứ tự điểm danh của bạn hôm nay là: </p>
                <div className='flex item-center'>
                    <div className="result-minigame-random-circle">
                        <p className='text-result-minigame-random'>{miniGame.MiniGameRandomPlayId}</p>
                    </div>
                </div>
                {resultData?.MiniGameRandomResultId &&
                    <div className='mgt flex-column item-center'>
                        <p className='text-large text-center bold'>Bạn đã trúng {resultData.Title} trị giá {formatDecimal(resultData.Balance ?? 0)} đ</p>
                        <p className='text-large text-center bold'>Phần thưởng đã được cộng vào tài khoản của bạn trong phần <u>Doanh thu</u></p>
                        <Button
                            className='mgt-3 bg-success'
                            onClick={() => this.props.history.push('/affiliate')}
                        >
                            Doanh thu
                        </Button>
                    </div>
                }
            </Modal>
        )
    }

    renderModalRule = () => {
        const { showModalRule, miniGame } = this.state;
        const startTime = moment(miniGame.StartTime, 'HH:mm');
        const endTime = moment(miniGame.EndTime, 'HH:mm');
        const totalMinutes = (endTime.hours()* 60 - startTime.hours() * 60)  - endTime.minutes() - startTime.minutes()
        return (
            <Modal
                {...PROP.MODAL}
                visible={showModalRule}
                onCancel={() => this.setState({ showModalRule: false })}
                centered
                width='700px'
            >
                <TitleSuccess>THỂ LỆ MINI GAME</TitleSuccess>
                <p className='mgt-4 text-large bold'>Luật chơi:</p>
                <p className='mgt-2 text-large'>• Thời gian điểm danh trong {totalMinutes} phút: {moment(miniGame.StartTime, "HH:mm:ss").format("H:mm")} - {moment(miniGame.EndTime, "HH:mm:ss").format("H:mm")}</p>
                <p className='mgt text-large'>• Trả lời đúng 4 câu hỏi NGOẠI NGỮ để điểm danh thành công.</p>
                <p className='mgt text-large'>• Người chơi trả lời sai có thể trả lời lại trong phạm vi {totalMinutes} phút.</p>
                <p className='mgt text-large'>• Người chiến thắng là người có số thứ tự điểm danh trùng vào con số trúng giải.</p>
                <p className='mgt-4 text-large bold'>Phần thưởng:</p>
                <p className='mgt-2 text-large'>Phần thưởng vui hằng ngày là 1 bữa sáng</p>
                <p className='mgt text-large'>• Giải gói xôi: 10.000 đ</p>
                <p className='mgt text-large'>• Giải bánh mì ba tê: 20.000 đ</p>
                <p className='mgt text-large'>• Giải phở gà: 30.000 đ</p>
                <p className='mgt text-large'>• Giải phở bò: 40.000 đ</p>
                <p className='mgt-4 text-large bold'>Tri ân mọi người tham gia xây dựng cộng đồng, giải thưởng được tài trợ bởi thầy Nguyễn Minh Ngọc!</p>
                <div className='flex item-center'>
                    <div className='hr-aff'></div>
                </div>
                <p className='mgt text-large bold'><i>!!! Lưu ý: Để minigame là một cuộc vui, mỗi người dùng 1 tài khoản để chơi, lập nhiều tài khoản rác sẽ bị khoá toàn bộ tài khoản.</i></p>
            </Modal>
        )
    }

    playMiniGame = () => {
        const { miniGame } = this.state;
        if (miniGame.IsWeekEnd) {
            this.setState({ showModalWaiting: true })
        }
        else {
            playMiniGameApi().then(res => {
                if (res.code == 1) {
                    this.numberQuestion = res.numberQuestion;
                    this.timeDiff = res.timeDiff;
                    this.countDown = res.countDown;
                    this.setState({
                        isLoading: false,
                        questionList: res.questionList,
                        resultAnswer: false,
                        selectAnswerKey: '',
                        isAnswer: false,
                        showModalQuestion: true
                    });
                    this.questionInterval = setInterval(() => {
                        this.timeDiff -= 1;
                        if (this.timeDiff < 10) this.setState({ textTime: '00:0' + this.timeDiff });
                        else this.setState({ textTime: '00:' + this.timeDiff });
                        if (this.timeDiff <= 0) {
                            let { isAnswer, resultAnswer } = this.state;
                            if (isAnswer == true && resultAnswer == 1) {
                                this.timeDiff = 30;
                                this.numberQuestion += 1;
                                if (this.numberQuestion > 4) {
                                    clearInterval(this.questionInterval);
                                }
                                this.correctAnswer = '';
                                this.setState({
                                    resultAnswer: false,
                                    selectAnswerKey: '',
                                    isAnswer: false,
                                    textTime: '--:--'
                                });
                            }
                            else {
                                this.setState({
                                    resultAnswer: false,
                                    selectAnswerKey: '',
                                    isAnswer: false,
                                    showModal: false,
                                    textTime: '--:--'
                                });
                                clearInterval(this.questionInterval);
                                this.hideModalQuestion();
                            }
                        }
                    }, 1000);
                }
                else {
                    if (res.code == -2) {
                        this.setState({ showModalMissing: true })
                    }
                    else showNotification(res.message, res.code)
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                clearInterval(this.questionInterval);
            })
        }
    }

    getInfoMiniGame = () => {
        getInfoMiniGameRandomApi().then(res => {
            if (res.code == 1) {
                this.setState({ miniGame: res.miniGame })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    hideModalQuestion = () => {
        this.setState({
            showModalQuestion: false,
            resultAnswer: false,
            selectAnswerKey: '',
            isAnswer: false,
            textTime: '--:--'
        });
        clearInterval(this.questionInterval);
    }

    renderContentQuestion = () => {
        let { questionList, selectAnswerKey, resultAnswer, textTime } = this.state;
        if (this.timeDiff >= 0) {
            return (
                <div>
                    <Lottie options={{ loop: false, autoplay: true, animationData: gift }}
                        height='16vh'
                        width='16vh'
                    />
                    {questionList.map((item, index) => (
                        <div key={index}>
                            {index + 1 == this.numberQuestion && (
                                <div className='animated fadeIn fast'>
                                    <p className='text-large text-center mgb'>Vượt qua 4 câu hỏi để điểm danh</p>
                                    <p className='title-question'>Câu hỏi {this.numberQuestion}: {item.QuestionTitle}</p>
                                    <div className='count-down-question-container'>
                                        <p className={'count-down-question ' + (this.timeDiff <= 10 ? 'danger' : 'success')}>{textTime}</p>
                                    </div>
                                    {item.answers.map((value, key) => {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => this.saveAnswer(value, key, item.QuestionId)}
                                            >
                                                <TextAnswer
                                                    answer={value}
                                                    keySelect={key}
                                                    selectAnswerKey={selectAnswerKey}
                                                    correctAnswer={this.correctAnswer}
                                                />
                                            </div>
                                        )
                                    })}
                                    {resultAnswer !== false && (
                                        <ResultMessage resultAnswer={resultAnswer} />
                                    )}
                                    <div className='notificationQuestionCheck'></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )
        }
        return (
            <Loading small />
        )
    }

    saveAnswer = (answer, keySelect, questionId) => {
        if (!this.state.isAnswer) {
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            let idNotification = '#notificationQuestionCheck';
            checkAnswerApi(questionId, answer).then(res => {
                if (res.code == 1 || res.code == 0) {
                    if (res.isDone) {
                        clearInterval(this.questionInterval);
                        this.setState({
                            showModalQuestion: false,
                            showModalPlaySuccess: true,
                            miniGame: {
                                ...this.state.miniGame,
                                MiniGameRandomPlayId: res.miniGameRandomPlayId,
                                ListResult: res.results
                            },
                            resultData: res.resultData
                        })
                        if(res.resultData?.MiniGameRandomResultId){
                            this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff + (+res.resultData.Balance))
                        }
                    }
                    else {
                        this.timeDiff = 3;
                        this.correctAnswer = res.answer;
                        this.setState({
                            resultAnswer: res.code,
                        });
                        setTimeout(() => {
                            speakEng(res.answer)
                        }, 0)
                    }
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.correctAnswer = '';
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(error => {
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                showNotification(MESSAGE.ERROR, -1, idNotification);
            })
        }
    }

    renderModalQuestion = () => {
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalQuestion}
                onCancel={this.hideModalQuestion}
                destroyOnClose={true}
                width='750px'
                centered
            >
                {this.renderContentQuestion()}
            </Modal>
        )
    }

    renderModalRank = () => {
        const { listRank } = this.state;
        const { dataUser } = this.props;
        const indexUser = listRank.findIndex(i => i.UserId == dataUser.UserId)
        return (
            <Modal
                {...PROP.MODAL}
                visible={this.state.showModalRank}
                onCancel={() => this.setState({ showModalRank: false })}
                width='750px'
                centered
            >
                <div className='item-center'>
                    <TitleSuccess>KẾT QUẢ NGÀY HÔM NAY</TitleSuccess>
                </div>
                {indexUser >= 0 &&
                    this.renderRankItem(listRank[indexUser], indexUser)
                }
                <div style={{ height: '4vh' }} />
                {listRank.map(this.renderRankItem)}
            </Modal>
        )
    }

    renderRankItem = ((item, key) => {
        const { miniGame } = this.state;
        return (
            <div key={item.UserId} className='rank-container item-center'>
                <div className='flex flex-2 item-center'>
                    <div className="result-minigame-random-circle-rank">
                        <p className='text-result-minigame-random'>{item.MiniGameRandomPlayId}</p>
                    </div>
                </div>
                <div className='col-rank-avatar'>
                    <div style={{ position: 'relative' }}>
                        <TooltipUser
                            userInfo={item}
                        />
                        {item.AssetImage && (
                            <img
                                className='avatar-asset-rank'
                                alt='asset-user'
                                src={`${URI_PATH}${item.AssetImage}`}
                                style={{ marginLeft: '-5.5vh' }}
                            />
                        )}
                    </div>
                </div>
                <div className='col-rank-name'>
                    <div className='row item-center'>
                        <ClubDeputy clubRoleId={item.ClubRoleId} />
                        <p>{item.ClubName}</p>
                    </div>
                    <div className='level-rank'>
                        <ClubVipLevel vipLevel={item.ClubVipLevel} className='star-rank' />
                    </div>
                    <p className='bold'>{item.FullName}</p>
                    <p>{item.ClubRank}</p>
                </div>
                <div className='col-rank-info flex-column item-center'>
                    <p className='text-center mgb-0'>{moment(item.CrDateTime).format('HH:mm:ss')}</p>
                </div>
            </div>
        )
    })

    getListRank = () => {
        const {isPress} = this.state;
        if(!isPress){
            this.setState({isPress: true})
            getListRankApi().then(res => {
                this.setState({isPress: false})
                if (res.code == 1) {
                    this.setState({
                        listRank: res.listRank,
                        isLoadingRank: false,
                        showModalRank: true
                    })
                }
                else if (res.code == -10) {
                    this.setState({
                        showModalWaitingRank: true,
                        showModalRank: false
                    })
                }
                else showNotification(MESSAGE.ERROR, -1);
            }).catch(error => {
                showNotification(MESSAGE.ERROR, -1);
                this.setState({isPress: false})
            })
        }
    }

    componentDidMount() {
        this.getInfoMiniGame();
    }

    componentWillUnmount() {
        clearInterval(this.questionInterval);
    }

    render() {
        return this.renderMiniGame();
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, getUserPresent, updateCountMission, showModalEat, updateDataUser })(withRouter(MiniGameRandom));