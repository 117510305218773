import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getMiniGameRandomApi = () =>{
    let url = `${URI_PATH}app/admin/minigamerandom/action/getMiniGameRandom`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const updateMiniGameRandomApi = ({
    Title, IsActive, StartTime, EndTime, ResetTime
}) =>{
    let url = `${URI_PATH}app/admin/minigamerandom/action/updateMiniGameRandom`;
    let body = {
        Title, IsActive, StartTime, EndTime, ResetTime
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}