import React, { Component } from 'react';
import { toggleCartAction, selectCartAction, updateListCart } from '../../actions/charge/sell';
import { changePoint } from '../../actions/home';
import { URI_PATH, MESSAGE, PROP } from '../../utils/constants';
import { formatDecimal } from '../../utils/common';
import { renewCartApi, openSellFoodApi, openSellAssetApi } from '../../api/charge/sell';
import Countdown, { zeroPad } from 'react-countdown-now';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { showNotification, showNotificationBonus } from '../../utils/notification';
import { getUserItem } from '../../actions/home';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';

class ListCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayRent: '',
            calculateBalance: '',
            isPress: false,
            showModal: false
        }
        this.isToggleCart = false;
    }

    calculateBalanceRent = (e) => {
        let input = parseInt(e.target.value);
        if (Number.isInteger(input)) {
            if (this.props.selectCart) this.setState({ dayRent: input, calculateBalance: formatDecimal(`${input * this.props.selectCart.CartBalance}`) });
            else showNotification('Vui lòng chọn cửa hàng để thuê', 0, '#notificationRenewCart');
        }
        else this.setState({ dayRent: '', calculateBalance: '' });
    }

    selectCart = (item) => {
        let { onCartFood, onCartAsset } = this.props;
        if (onCartFood == true || onCartAsset == true) return showNotification('Vui lòng chuyển trạng thái đăng bán để đổi cửa hàng', 0, '#notificationRenewCart');
        this.setState({ dayRent: '', calculateBalance: '', showModal: true });
        this.props.selectCartAction(item);
    }

    renewCart = () => {
        if (!this.state.isPress) {
            let { selectCart } = this.props;
            let { dayRent } = this.state;
            let idNotification = '#notificationRenewCart';
            if (selectCart == false) return showNotification('Bạn chưa chọn cửa hàng', 0, idNotification);
            if (dayRent == '' || dayRent < 1) return showNotification('Số ngày thuê phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({ isPress: true })
            renewCartApi(selectCart.FoodcartId, dayRent).then((res) => {
                if (res.code == 1) {
                    this.setState({ dayRent: '', isPress: false, calculateBalance: '' });
                    showNotificationBonus(res.balance, -1, 2);
                    showNotificationBonus(res.balanceZ, -1, 3);
                    this.props.changePoint([null, -res.balance, -res.balanceZ]);
                    showNotification('Thuê cửa hàng thành công', 1, idNotification);
                    this.props.updateListCart(this.props.type);
                    this.props.onRenewCart(selectCart.CartImage);
                    if (this.props.loadingItem0) this.props.getUserItem(0);
                }
                else if (res.code == -99) {
                    this.setState({ isPress: false, showModal: false });
                    this.props.showModalBuyVip();
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                    this.setState({ isPress: false });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({ isPress: false });
            });
        }
    }

    toggleCart = (value, type) => {
        if (this.isToggleCart === false) {
            let isOpen = (value == true) ? 2 : 1;
            let idNotification = '#notificationToggleCart';
            let { selectCart } = this.props;
            this.props.toggleCartAction(value, type);
            if (selectCart == false) {
                showNotification('Bạn chưa chọn cửa hàng', 0, idNotification);
                this.props.toggleCartAction(!value, type);
                return;
            }
            this.isToggleCart = true;
            if (type == 1) {
                openSellFoodApi(selectCart.FoodcartId, isOpen).then(res => {
                    this.isToggleCart = false;
                    if (res.code == 1) showNotification(res.message, res.code, idNotification);
                    else if (res.code == -99) {
                        this.setState({ isPress: false, showModal: false });
                        this.props.showModalBuyVip();
                    }
                    else {
                        this.props.toggleCartAction(!value, type);
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(e => {
                    this.isToggleCart = false;
                    this.props.toggleCartAction(!value, type);
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
            else {
                openSellAssetApi(selectCart.FoodcartId, isOpen).then(res => {
                    this.isToggleCart = false;
                    if (res.code == 1) showNotification(res.message, res.code, idNotification);
                    else if (res.code == -99) {
                        this.setState({ isPress: false, showModal: false });
                        this.props.showModalBuyVip();
                    }
                    else {
                        this.props.toggleCartAction(!value, type);
                        showNotification(res.message, res.code, idNotification);
                    }
                }).catch(error => {
                    this.isToggleCart = false;
                    this.props.toggleCartAction(!value, type);
                    showNotification(MESSAGE.ERROR, 0, idNotification);
                });
            }
        }
    };

    render() {
        let { listCart, selectCart } = this.props;
        let { dayRent, calculateBalance, isPress, showModal } = this.state;
        if (listCart) {
            return (
                <div>
                    <div id='notificationToggleCart'></div>
                    <div className='flex-row flex-wrap mgt-3 item-center'>
                        {listCart.map((item, key) =>
                            <div
                                key={key}
                                className={`flex-1 flex-column item-block item-center pointer ${(item.FoodcartId == selectCart.FoodcartId) && 'bg-success'}`}
                                onClick={() => this.selectCart(item)}
                            >
                                <img className='item-img' alt={item.CartName} src={`${URI_PATH}${item.CartImage}`} />
                                <p>Tối đa: {item.AmountMax}sp</p>
                                <p className='bold'>{formatDecimal(`${item.CartBalance}`)} đ[z]/ngày</p>
                                {item.Expire ?
                                    <p>
                                        <Countdown
                                            date={item.CartTime * 1000}
                                            renderer={({ days, hours, minutes, seconds }) => (<span>{days} ngày {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                                        />
                                    </p>
                                    :
                                    <p>{item.CartTime}</p>
                                }
                            </div>
                        )}
                    </div>
                    <Modal
                        {...PROP.MODAL}
                        visible={showModal}
                        onCancel={() => {
                            this.setState({ showModal: false })
                            this.props.selectCartAction({});
                        }}
                        centered
                    >
                        <div className='mgt-2 item-center'>
                            <div id='notificationRenewCart'></div>
                            <input
                                className='form-control text-center mgt'
                                placeholder='Nhập số ngày thuê'
                                value={dayRent}
                                onChange={this.calculateBalanceRent}
                            />
                            {calculateBalance &&
                                <p className='text-center mgt'>Hệ thống sẽ trừ {calculateBalance} đ[z]</p>
                            }
                            <p className='warning text-center mgt'>Thuê cửa hàng mới sẽ mất số ngày thuê cửa hàng cũ</p>
                            <Button
                                className='bg-success mgt'
                                loading={isPress}
                                onClick={this.renewCart}
                            >
                                Thuê cửa hàng
                            </Button>
                        </div>
                    </Modal>
                </div>
            )
        }
        return (<Loading small />)
    }
}

const mapStateToProps = (state) => {
    const { listCart, onCartFood, onCartAsset, selectCart } = state.getItemCartSell;
    const { loadingItem0 } = state.getUserItem;
    const { dataUser } = state.getUserData;
    return { listCart, onCartFood, onCartAsset, selectCart, loadingItem0, dataUser };
}

export default connect(mapStateToProps, { toggleCartAction, selectCartAction, updateListCart, getUserItem, changePoint, showModalBuyVip })(ListCart);