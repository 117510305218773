import React from 'react';
import {getAvatarFb} from './common';

export const URI_PATH = process.env.NODE_ENV === 'production' ? 'https://zcity.vn/backend/' : 'http://localhost/zcity-backend/';

export const URI_SOCKET = process.env.NODE_ENV === 'production' ? 'https://zcity.vn:4567' : 'http://localhost:4567';

export const STYLE_WALLPAPER = {
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
}

export const KEY = {
	TOKEN: '@token',
	WALLPAPER: '@wallpaper',
	API_KEY_YOUTUBE: 'AIzaSyBosBKwT5Z-Lc7m47-vDxKKlj6KIrWmFpA',
	FB_APP_ID: 303130995330773,
	FB_PAGE_ID: 791079167942333,
	STUDY_ANSWER: '@study_answer',
	STUDY_DOCUMENT_LINK: '@study_document_link',
	STUDY_DOCUMENT_CONTENT: '@study_document_content',
	STUDY_DOCUMENT_IMAGE: '@study_document_image',
	STUDY_IS_DOCUMENT_YOUTUBE: '@study_is_document_youtube',
	STUDY_IS_DOCUMENT_TIKTOK: '@study_is_document_tiktok',
	AFF_INVITE: '@aff_invite',
}

export const COLOR = {
	BACKGROUND_DEFAULT: '#1b2634',
	BACKGROUND_PRIMARY: 'rgba(91, 91, 91, 0.3)',
	BACKGROUND_MODAL: 'rgba(0, 0, 0, 0.9)',
	BACKGROUND_HEADER: '#191919',
	BACKGROUND_POST_VIDEO: '#F7F1E3',
	COLOR_DEFAULT: 'white',
	COLOR_UNDEFAULT: 'black',
	COLOR_SUCCESS: '#00A65A',
	COLOR_WARNING: '#FF9300',
	COLOR_DANGER: '#DD4B39',
	COLOR_PLACEHOLDER: '#9c9c9c'
}

export const LIST_DONATE = [1000, 5000, 10000, 50000, 100000, 500000];

export const TICKET = {
	DECIMAL_COUNT: 0,
	MIN_TICKET: 1
}

export const MESSAGE = {
	ERROR : 'Có lỗi xảy ra trong quá trình thực hiện'
}

const isTouch = () =>{
	return ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
}

export const PROP = {
	MODAL:{
		title: null,
		footer: null,
		bodyStyle: {background: 'linear-gradient(to bottom, #232526, #414345)', borderRadius: '2vh'},
		zIndex: 9999,
		width: '70vh'
	},
	TOOLTIP_HOME:{
		placement: 'topLeft',
		trigger: isTouch() ? 'click' : 'hover',
		style: {width: '240px'},
		mouseEnterDelay: 0.05,
		mouseLeaveDelay: 0.05
	},
	TYPE_AHEAD:{
		isLoading: false,
		minLength: 4,
		labelKey: 'name',
		filterBy: ['name'],
		id: 'input-search-user',
		emptyLabel: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Không tìm thấy người dùng</p>
				</div>
			</div>
		),
		searchText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tìm kiếm</p>
				</div>
			</div>
		),
		promptText: (
			<div className='flex item-center'>
				<div className='flex-row search-user'>
					<p className='search-user-text'>Đang tải ...</p>
				</div>
			</div>
		),
		renderMenuItemChildren: (option) => (
			<div className='flex-row item-center'>
				<div className='flex-row search-user'>
					<img alt={option.name} className='avatar-guest-small' src={getAvatarFb(option.facebookId)} />
					<p className='search-user-text'>{option.name}</p>
				</div>
			</div>
		)
	}
}

export const BALANCE_TYPE = {
	1: 'Gold[z]',
	2: '$[z]',
	3: 'đ[z]'
}

export const WALLPAPER = {
	1: 'linear-gradient(to left, #766b87, #2b1331)',
	2: 'linear-gradient(to right, #dc2424, #4a569d)',
	3: `url(${require('../assets/image/tet.png')})`,
	4: `url(${require('../assets/image/tet2.png')})`,
	5: 'linear-gradient(to right, #243949, #517fa4)',
	6: 'linear-gradient(to right, #1e3c72, #2a5298)',
	7: 'linear-gradient(to right, #29323c, #485563)',
	8: 'linear-gradient(to left, #F2A65A, #772F1A)',
	9: 'rgb(104, 103, 170)',
	10: 'rgb(81, 172, 104)',
	11: 'rgb(69, 83, 206)',
	12: 'rgb(238, 223, 203)',
	13: 'rgb(205, 167, 98)',
	14: 'rgb(190, 84, 145)',
	15: 'rgb(216, 77, 51)',
	16: 'rgb(241, 213, 207)',
	17: 'rgb(128, 194, 164)',
	18: 'rgb(122, 123, 128)',
	19: 'rgb(51, 119, 114)',
	20: 'rgb(84, 85, 84)',
	21: 'rgb(211, 176, 123)',
	22: 'linear-gradient(to right, #5D9FFF 0%, rgb(128, 187, 247) 48%, #6BBBFF 100%)',
}

export const LIST_BET_TYPE_3 = [
	{value: '100000', label: '100.000 đ[z]'},
	{value: '1000000', label: '1.000.000 đ[z]'},
	{value: '10000000', label: '10.000.000 đ[z]'},
]

export const LIST_BET_TYPE_2 = [
	{value: '10000', label: '10.000 $[z]'},
	{value: '100000', label: '100.000 $[z]'},
	{value: '1000000', label: '1.000.000 $[z]'},
]

export const LIST_BET_TYPE_1 = [
	{value: '1', label: '1 Gold [z]'},
	{value: '5', label: '5 Gold[z]'},
	{value: '10', label: '10 Gold [z]'},
]

export const LANGUAGE_NAME = {
	1: 'tiếng Anh',
	2: 'tiếng Nhật',
	3: 'tiếng Hàn',
	4: 'tiếng Trung',
	5: 'tiếng Tây Ban Nha',
	6: 'tiếng Đức',
	7: 'tiếng Pháp',
	8: 'tiếng Nga',
	9: 'tiếng Ả Rập',
}