import { Subject } from 'rxjs';

export default class EventBus {
    static getInstance() {
        if (!EventBus.instance) {
            EventBus.instance = new EventBus();
        }
        return EventBus.instance;
    }

    static instance;

    eventSubject = new Subject();

    get events() {
        return this.eventSubject.asObservable();
    }

    post(event) {
        this.eventSubject.next(event);
    }
}

export const onPushEventBus = (type, payload) => {
    EventBus.getInstance().post({ type, payload });
};

export const EventBusName = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    BUY_ZTICKET: 'BUY_ZTICKET',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    SHOW_MODAL_AFF: 'SHOW_MODAL_AFF',
}
