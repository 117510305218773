import GiftPresent from './GiftPresent';
import GiftDay from './GiftDay';
import GiftEffort from './GiftEffort';
import GiftSpin from './GiftSpin';
import AssetSuccess from './AssetSuccess';
import AssetFail from './AssetFail';
import GiftOffer from './GiftOffer';
import QuestionWin from './QuestionWin';
import UpdateStatus from './UpdateStatus';
import Invest from './Invest';
import Post from './Post';
import Video from './Video';
import QuestionAdd from './QuestionAdd';
import BookReview from './BookReview';
import ClubCreate from './ClubCreate';
import AskQuestion from './AskQuestion';
import Medal from './Medal';
import MiniGame from './MiniGame';

export default {
    GiftPresent,
    GiftDay,
	GiftEffort,
	GiftSpin,
	AssetSuccess,
	AssetFail,
	GiftOffer,
	GiftOffer,
	QuestionWin,
	UpdateStatus,
	Invest,
	Post,
	Video,
	QuestionAdd,
	BookReview,
	ClubCreate,
	AskQuestion,
	Medal,
	MiniGame
}