import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { URI_PATH } from '../../utils/constants';
import { Collapse } from 'react-collapse';
import Icon from 'react-fontawesome';

const guides = [
    { title: 'Số tiền tối đa tôi có thể rút về là bao nhiêu?', content: 'Là toàn bộ số dư của bạn. Nhưng khuyến khích để lại 500.000 đ để hệ thống tự động gia hạn phí hợp tác tháng tiếp theo.' },
    { title: 'Tôi có thể làm affiliate này ở cộng đồng người Việt ở nước ngoài không?', content: 'Hiện tại zcity chỉ trả tiền về các tài khoản ngân hàng tại Việt Nam, không trả tiền được ra nước ngoài. Nếu cộng đồng của bạn có tài khoản ngân hàng Việt Nam cùng tên tài khoản zcity thì có thể làm được.' },
    { title: 'Tôi nên lưu ý gì khi tạo tài khoản?', content: 'Hãy dùng số điện thoại chính chủ của bạn và dùng họ tên đúng với giấy tờ cá nhân, để quá trình rút tiền được xác minh thuận lợi.' },
]

class AffLobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guideSelected: [0]
        }
    }

    toggleGuide = (key) => {
        const { guideSelected } = this.state
        if (guideSelected.includes(key)) {
            this.setState({
                guideSelected: guideSelected.filter(e => e != key),
            })
        }
        else {
            this.setState({
                guideSelected: [...guideSelected, key],
            });
        }
    }

    render() {
        const { guideSelected } = this.state
        return (
            <>
                <div className='box-primary mgt'>
                    <p className='text-center text-gigantic mgt-2'>Kiếm tiền online cùng Zcity</p>
                    <video width="100%" className='mgt-2' controls style={{ borderRadius: '1vh' }} autoPlay>
                        <source src={`${URI_PATH}assets/zcity-aff.mp4#t=0.001`} type="video/mp4" />
                    </video>
                </div>
                <div className='box-primary mgt'>
                    <p className='text-center text-gigantic'>Câu hỏi thường gặp</p>
                    {guides.map((item, key) =>
                        <div className='flex-column' key={key}>
                            <div
                                className='mgt flex align-center space-between pointer'
                                onClick={() => this.toggleGuide(key)}
                            >
                                <p>{key + 1}. {item.title}</p>
                                <Icon
                                    name={guideSelected.includes(key) ? 'minus-circle ' : 'plus-circle'}
                                    className='text-large'
                                />
                            </div>
                            <Collapse isOpened={guideSelected.includes(key)}>
                                <div className='flex-column'>
                                    <p className='text-aff-guide'>
                                        {item.content}
                                    </p>
                                    <div className='hr-aff-guide' />
                                </div>
                            </Collapse>
                        </div>
                    )}
                </div>
            </>
        )
    }
}

export default connect(null)(withRouter(AffLobby));