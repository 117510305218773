import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import Icon from 'react-fontawesome';
import UserBar from './UserBar';
import {getAvatarFb} from '../../../utils/common';
import { Affix } from 'antd';
import {Collapse} from 'react-collapse';
import {showHideWidgetNotification, getListNotification} from '../../../actions/notification';
import {toogleUserBar} from '../../../actions/userbar';
import {updateDataUser} from '../../../actions/home/';
import TooltipUser from '../../TooltipUser';

class NavBar extends Component{
    constructor(props){
        super(props);
    };

    actionWidgetNotification = () =>{
        if(!this.props.showWidgetNotification){
            this.props.getListNotification();
            this.props.updateDataUser('IsNotification', false);
        }
        this.props.showHideWidgetNotification();
    }

    render(){
        let {showWidgetNotification, showUserBar} = this.props;
        return(
            <Affix>
                <div>
                    <div className='header-nav' onClick={() => showUserBar && this.props.toogleUserBar()}>
                        <NavLink className='col-nav' to="/podcast" exact activeClassName='selected-bar'>
                            <Icon
                                name='microphone'
                                size='2x'
                            />
                            <p className='text-nav'>Podcast</p>
                        </NavLink>
                        <NavLink className='col-nav' to="/question-fight" activeClassName='selected-bar'>
                            <Icon
                                name='trophy'
                                size='2x'
                            />
                            <p className='text-nav'>Đấu trường</p>
                        </NavLink>
                        <NavLink className='col-nav' to="/new-feed" activeClassName='selected-bar'>
                            <Icon
                                name='globe'
                                size='2x'
                            />
                            <p className='text-nav'>Bảng tin</p>
                        </NavLink>
                        {/* <NavLink className='col-nav' to="/home" activeClassName='selected-bar'>
                            <Icon
                                name='home'
                                size='2x'   
                            />
                            <p className='text-nav'>Tài sản</p>
                        </NavLink> */}
                        <NavLink className='col-nav' to="/study" activeClassName='selected-bar'>
                            <Icon
                                name='graduation-cap'
                                size='2x'
                            />
                            <p className='text-nav'>Học viện Z</p>
                        </NavLink>
                        <div
                            className='col-nav flex-row'
                            onClick={this.actionWidgetNotification}
                        >
                            <div className='relative'>
                                <Icon name='bell' size='2x' />
                                <p className='text-nav'>Thông báo</p>
                                {this.props.dataUser.IsNotification && <div className='dot-notification-bar' />}
                            </div>
                            {showWidgetNotification && <Icon className='mgl' name='angle-up' /> }
                        </div>
                        <div
                            className='col-nav flex-row'
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.toogleUserBar();
                            }}
                        >
                            <TooltipUser
                                userInfo={this.props.dataUser}
                                avatarClass='avatar-guest-small'
                            />
                            {showUserBar && <Icon className='mgl' name='angle-up' />}
                        </div>
                    </div>
                    <Collapse isOpened={showUserBar}>
                        <UserBar/>
                    </Collapse>
                </div>
            </Affix>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { showWidgetNotification } = state.getListNotification;
    const { showUserBar } = state.getStateUserBar;
    return { dataUser, showWidgetNotification, showUserBar };
}

export default connect(mapStateToProps, {showHideWidgetNotification, getListNotification, toogleUserBar, updateDataUser})(NavBar);