import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const saveContentApi = (Content, ZcrazyContentId) =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/saveContent`;
    let body = {
        Content,
        ZcrazyContentId
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getContentApi = () =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/getContent`;
    let body = {}
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveDiscordLinkApi = (DiscordLink) =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/saveDiscordLink`;
    let body = {
        DiscordLink
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getValueInvestApi = () =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/getValueInvest`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveValueInvestApi = (PercentUp, PercentDown) =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/saveValueInvest`;
    let body = {
        PercentUp,
        PercentDown
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const saveYoutubeLinkApi = (YoutubeLink1, YoutubeLink2) =>{
    let url = `${URI_PATH}app/admin/zcrazy/action/saveYoutubeLink`;
    let body = {
        YoutubeLink1,
        YoutubeLink2
    }
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}