import React, {Component} from 'react';
import {connect} from 'react-redux';
import { changePoint } from '../../actions/home/';
import {bidApi} from '../../api/brand/top';
import {Button, Modal} from 'antd';
import {getAvatarFb, getDecimalCount, formatDecimal, replaceCost} from '../../utils/common';
import { showNotification, showNotificationBonus} from '../../utils/notification';
import {MESSAGE, PROP, URI_PATH, TICKET, COLOR} from '../../utils/constants';
import hammer from '../../assets/lottie/hammer.json';
import Lottie from 'react-lottie';
import TooltipUser from '../../components/brand/TooltipUser';
import TooltipUserDefault from '../../components/TooltipUser';
import {showModalEat} from '../../actions/home/modalEat';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';

class Bid extends Component{
    constructor(props){
        super(props);
        this.state={
            listBid: [],
            inputBidValue: ''
        }
    }

    getTimeTomorow = () =>{
        let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()
        return day + '/' + month + '/' + year;
    }

    bid = () =>{
        let {isPress, inputBidValue} = this.state;
        if(!isPress){
            let idNotification = '#notificationBid';
            inputBidValue = replaceCost(inputBidValue, true)
            if(inputBidValue == '' || inputBidValue <= 0) return showNotification('Số $[z] phải lớn hơn 0 và không được để trống', 0, idNotification);
            bidApi(inputBidValue).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        inputBidValue: ''
                    });
                    showNotificationBonus(inputBidValue, -1, 2);
                    showNotificationBonus(res.userLike, 1, 5);
                    this.props.changePoint([null, -inputBidValue, null, null, res.userLike]);
                }
                else if(res.code == -99){
                    this.setState({showModal: false});
                    this.props.showModalBuyVip();
                    this.setState({isPress: false})
                }
                else{
                    if(res.code == -5) this.props.showModalEat(res.listFood);
                    this.setState({isPress: false});
                }
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPress: false});
            })
        }
    }

    render(){
        let {showModal, inputBidValue} = this.state;
        let {dataUser, likeTimeRemain, listBid, enableBid, allBalanceBid, disabled} = this.props;
        const Tooltip = disabled ? TooltipUserDefault : TooltipUser
        
        return(
            <div>
                <div className='flex-row mg-2'>
                    {listBid.map((item, key) =>
                        <div className='flex-1 item-center' key={key}>
                            <div
                                style={{
                                    position: 'relative',
                                    padding: '2vh 1vh',
                                    borderRadius: '1vh',
                                    backgroundColor: item.IsWin > 0 ? COLOR.COLOR_SUCCESS : 'transparent'
                                }}
                            >
                                {item.UserId != dataUser.UserId ? 
                                    <Tooltip
                                        userInfo={item}
                                        likeTimeRemain={likeTimeRemain}
                                        onLikeTimeRemain={this.props.onLikeTimeRemain}
                                        onLike={this.props.onLike}
                                    />
                                :
                                    <TooltipUserDefault
                                        userInfo={item}
                                    />
                                }
                                {item.AssetImage && (
                                    <img className='avatar-asset-rank' alt='asset-user' src={`${URI_PATH}${item.AssetImage}`} />
                                )}
                            </div>
                        </div>
                    )}
                    {new Array(5 - listBid.length).fill(undefined).map((item, key) =>
                        <div className='flex-1 item-center' key={key}>
                            <img className='avatar-rank' src={getAvatarFb()} />
                        </div>
                    )}
                </div>
                {enableBid &&
                    <div>
                        <div className='item-center'>
                            <Button
                                className='bg-success'
                                onClick={() => this.setState({showModal: true})}
                            >
                                Đấu thầu
                            </Button>
                        </div>
                        <Modal
                            {...PROP.MODAL}
                            visible={showModal}
                            onCancel={() => this.setState({showModal: false})}
                            centered
                        >
                            <div className='item-center'>
                                <Lottie
                                    options={{loop: true, autoplay: true, animationData: hammer}}
                                    width='10vh'
                                />
                                <p className='text-huge success bold mgt'>ĐẤU THẦU HỘI ĐỒNG THỊ TRƯỞNG</p>
                                <p className='text-huge success bold mgt'>{this.getTimeTomorow()}</p>
                                <input
                                    className='form-control text-center mgt'
                                    placeholder='Nhập số $[z]'
                                    value={inputBidValue}
                                    onChange={e => this.setState({inputBidValue: formatDecimal(e.target.value)})}
                                />
                                <p className='mgt text-large success'>5 người đạt Huy chương Z trả giá cao nhất sẽ trở thành hội đồng thị trưởng ngày mai</p>
                                <p><i className='success text-center'>Người đấu thầu may mắn ngẫu nhiên trong 5 người sẽ giành phần thưởng 1 Gold[z]</i></p>
                                <div className='mgt' id='notificationBid' />
                                <Button
                                    className='bg-success mgt'
                                    onClick={this.bid}
                                >
                                    Đấu thầu
                                </Button>
                                <p className='text-large success bold mgt-2'>Danh sách đấu thầu thành công gần nhất</p>
                                <div className='row mgt'>
                                    {listBid.map((item, key) =>
                                        <div
                                            key={key}
                                            className='col-md-4 col-sm-4 col-xs-6 item-block item-center pd'
                                            style={{
                                                backgroundColor: item.IsWin > 0 ? COLOR.COLOR_SUCCESS : 'transparent'
                                            }}
                                        >
                                            <TooltipUser userInfo={item} avatarClass='avatar-guest' />
                                            <p className='mgt'>{item.FullName}</p>
                                            <p>{formatDecimal(item.BidValue)} $[z]</p>
                                            <p className='silver text-small'>{item.BidDateTime}</p>
                                        </div>
                                    )}
                                </div>
                                {/* {listBid.length > 2 ?
                                    <p className='success text-large mgt-2 text-center'>Tổng phần thưởng: {allBalanceBid} đ[z] + 0.001 Gold[z]</p>
                                :
                                    <p className='success text-large mgt-2 text-center'>Tổng phần thưởng: {formatDecimal(listBid.reduce(( sum, card ) => sum + parseFloat(card.BidValue), 0 ))} đ[z] + 0.001 Gold[z]</p>
                                } */}
                                <p className='success text-large mgt-2 text-center'>Tổng phần thưởng: 1 Gold[z]</p>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, showModalEat, showModalBuyVip})(Bid);