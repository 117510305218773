import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Icon from 'react-fontawesome';

class ButtonTheory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false
        }
        this.unlisten = () => null;
    }

    handleTheoryClick = () => {
        this.props.history.push('/menu/theory')
    }

    checkPathname = (pathname) =>{
        if(pathname === '/menu/theory' || pathname === '/'){
            this.setState({isFocus: true})
        }
        else this.setState({isFocus: false})
    }

    componentDidMount() {
        this.checkPathname(this.props.history?.location?.pathname)
        this.unlisten = this.props.history.listen((location) => {
            this.checkPathname(location.pathname)
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <>
                <div className='button-thoery-container'>
                    <div
                        className={`button-aff item-center ${this.state.isFocus ? 'bg-success' : 'bg-primary'}`}
                        onClick={this.handleTheoryClick}
                    >
                        <Icon
                            name='bookmark'
                            className='white text-huge mgr'
                        />
                        <p className='mgb-0'>Hướng Dẫn</p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(ButtonTheory));