import {URI_PATH} from '../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const getInfoMiniGameApi = () =>{
    let url = `${URI_PATH}app/minigame/action/getInfoMiniGame`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const registerMiniGameApi = () =>{
    let url = `${URI_PATH}app/minigame/action/registerMiniGame`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const playMiniGameApi = () =>{
    let url = `${URI_PATH}app/minigame/action/playMiniGame`;
    let body = {};
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const checkanswerApi = (questionId, answer) =>{
    let url = `${URI_PATH}app/minigame/action/checkanswer`;
    let body = {
        QuestionId: questionId,
        Answer: answer
    };
	return axios.post(url, qs.stringify(body)).then((res) => res.data);
}