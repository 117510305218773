import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Switch } from 'antd';
import { MESSAGE, PROP } from '../../utils/constants';
import { showNotification } from '../../utils/notification';
import { TitleSuccess } from '../../components/frame';
import ButtonGroup from 'antd/lib/button/button-group';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { getListNotificationApi, updateNotificationApi, deleteNotificationApi } from '../../api/admin/fcm';

const NOTIFICATION_TYPE = {
    99: "Khác",
    22: "Sự kiện ngoại ngữ",
    23: "Phá phong ấn",
    24: 'Quà tăng trưởng',
    26: "Thả tym",
    27: "Quà thần bí",
    28: "Đấu trường",
    29: "Chứng khoán",
}

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectTab: 1,
            inputTime: '',
            inputTitle: '',
            inputBody: '',
            inputType: 99,
            inputExclude: '',
            isQueue: true,
            isPress: false,
            listNotification: [],
            selectNotification: {},
            isRepeat: false,
        }
    }

    buttonGroupClass = (selectTab) => {
        if (selectTab == this.state.selectTab) return 'button-group-home bg-success';
        return 'button-group-home';
    }

    save = () => {
        const { inputTime, inputTitle, inputBody, isRepeat, isPress, selectNotification, inputType, inputExclude } = this.state;
        if (!isPress) {
            if (!inputTime) {
                return showNotification('Thời gian không được để trống', 0);
            }
            this.setState({ isPress: true });
            updateNotificationApi({
                Title: inputTitle,
                Body: inputBody,
                IsRepeat: isRepeat ? 2 : 0,
                Time: moment(inputTime).format("HH:mm"),
                FcmId: selectNotification.FcmId,
                Type: inputType,
                Exclude: isRepeat ? inputExclude : ''
            }).then(res => {
                showNotification(res.message, res.code)
                this.setState({ isPress: false })
                if (res.code == 1) {
                    this.setState({
                        inputTime: '',
                        inputTitle: '',
                        inputBody: '',
                        selectNotification: {},
                        showModal: false,
                    });
                    this.getListNotification();
                }
            }).catch(() => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({ isPress: false })
            })
        }
    }

    deleteNotification = (item) => {
        if (window.confirm('Xác nhận xóa thông báo này')) {
            deleteNotificationApi(item.FcmId).then(res => {
                showNotification(res.message, res.code)
                if (res.code == 1) {
                    this.getListNotification();
                }
            }).catch(() => {
                showNotification(MESSAGE.ERROR, 0);
            })
        }
    }

    getTextRepeat = (data) =>{
        if(data.Exclude == ''){
            return 'Mọi ngày'
        }
        const allDayWeek = Array.from(Array(7).keys());
        const dayExclude = data.Exclude.split(',')
        const allDayRest = allDayWeek.filter(i => !dayExclude.includes(`${i}`))
        return allDayRest.map((i, k) =>{
            return i == 6 ? `${k > 0 ? ' ' : ''}CN` : `${k > 0 ? ' ' : ''}Thứ ${i + 2}`
        }).toString()
    }

    renderTab = () => {
        const { selectTab, inputTime, inputTitle, isPress, listNotification, inputBody, isRepeat, inputType, inputExclude } = this.state;
        if (selectTab == 1) {
            return (
                <>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2 flex flex-start align-center'>
                            <p className='text-left text-large mgr-2'>Lặp lại: </p>
                            <Switch
                                checked={isRepeat}
                                onChange={() => this.setState({ isRepeat: !isRepeat })}
                            />
                        </div>
                    </div>
                    {isRepeat &&
                        <div className='item-center row'>
                            {Array(7).fill(undefined).map((_, key) =>{
                                const day = key == 6 ? 'CN' : `Thứ ${key + 2}`;
                                return(
                                    <div className='col-md-1 col-sm-2 col-xs-4 mgt-2' key={key}>
                                        <p className='text-large'>{day}: </p>
                                        <Switch
                                            checked={!inputExclude.includes(key)}
                                            onChange={(state) => {
                                                const listArr = inputExclude.split(",").filter(n => n);
                                                if(state){
                                                    this.setState({
                                                        inputExclude: listArr.filter(i => i != key).sort().toString()
                                                    })
                                                }
                                                else{
                                                    this.setState({
                                                        inputExclude: [...listArr, key].sort().toString()
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2 flex-start align-center'>
                            <p className='text-left text-large'>Loại thông báo: </p>
                            <select
                                className='form-control'
                                value={inputType}
                                onChange={e => this.setState({ inputType: e.target.value })}
                            >
                                {Object.keys(NOTIFICATION_TYPE).map((item) =>
                                    <option value={item} key={item}>{NOTIFICATION_TYPE[item]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2'>
                            <p className='text-left text-large'>Thời gian:</p>
                            <div className='flex align-center date-study-picker'>
                                <ReactDatePicker
                                    selected={inputTime}
                                    onChange={inputTime => this.setState({ inputTime })}
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Thời gian"
                                    dateFormat="H:mm"
                                    showTimeSelect
                                    minDate={Date.now()}
                                    placeholderText='Chọn thời gian phát'
                                    className='text-left'
                                    showTimeSelectOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2'>
                            <p className='text-left text-large'>Tiêu đề:</p>
                            <input
                                className='form-control'
                                value={inputTitle}
                                onChange={e => this.setState({ inputTitle: e.target.value })}
                                placeholder='Nhập tiêu đề thông báo'
                            />
                        </div>
                    </div>
                    <div className='item-center row'>
                        <div className='col-md-4 col-sm-12 col-xs-12 mgt-2'>
                            <p className='text-left text-large'>Nội dung:</p>
                            <input
                                className='form-control'
                                value={inputBody}
                                onChange={e => this.setState({ inputBody: e.target.value })}
                                placeholder='Nhập nội dung thông báo'
                            />
                        </div>
                    </div>
                    <div className='item-center mgt-3'>
                        <Button
                            className='bg-success mgt'
                            onClick={this.save}
                            loading={isPress}
                        >
                            Lưu
                        </Button>
                    </div>
                </>
            )
        }
        return (
            <>
                {listNotification.map((item, index) =>
                    <div className='box-primary flex space-between align-center' key={index}>
                        <div>
                            <p className='text-left mgt text-large bold'>{item.Title}</p>
                            <p className='text-left'>{item.Body}</p>
                            <p className='text-left'>Ngày tạo: {moment(item.CrDateTime).format('DD/MM/YYYY')} </p>
                            <p className='text-left'>Thời gian: {moment(item.Time, 'HH:mm:ss').format('HH:mm')}</p>
                            <p className='text-left'>Loại thông báo: {NOTIFICATION_TYPE[item.Type]}</p>
                            <div className='flex align-center'>
                                <p className='text-left mgr-2'>Lặp lại: </p>
                                <Switch
                                    checked={item.IsRepeat == 2}
                                />
                            </div>
                            {item.IsRepeat &&
                                <p className='text-left'>{this.getTextRepeat(item)}</p>
                            }
                        </div>
                        <div className='flex'>
                            <img
                                src={require('../../assets/image/pen.png')}
                                style={{ width: '6vh' }}
                                className='pointer mgl'
                                onClick={() => this.setState({
                                    selectNotification: item,
                                    showModal: true,
                                    inputTitle: item.Title,
                                    inputBody: item.Body,
                                    inputTime: moment(item.Time, 'HH:mm:ss').toDate(),
                                    isRepeat: item.IsRepeat == 2,
                                    inputExclude: item.Exclude,
                                    inputType: item.Type
                                })}
                            />
                            <img
                                src={require('../../assets/image/delete.png')}
                                style={{ width: '6vh' }}
                                className='pointer mgl'
                                onClick={() => this.deleteNotification(item)}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }

    getListNotification = () => {
        this.setState({ listNotification: [] })
        getListNotificationApi().then(res => {
            this.setState({ listNotification: res.data });
        }).catch(() => {
            showNotification(MESSAGE.ERROR, 0);
        })
    }

    render() {
        const { showModal, inputTitle, inputBody, inputTime, isRepeat, isPress, inputType, inputExclude } = this.state;
        return (
            <>
                <ButtonGroup className='item-center flex mgt mgb' style={{ width: '100%' }}>
                    <Button onClick={() => this.setState({ selectTab: 1 })} className={this.buttonGroupClass(1)}>Đặt lịch Thông báo</Button>
                    <Button onClick={() => this.setState({ selectTab: 2 }, this.getListNotification)} className={this.buttonGroupClass(2)}>Danh sách thông báo</Button>
                </ButtonGroup>
                {this.renderTab()}
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => {
                        this.setState({
                            showModal: false,
                        })
                    }}
                    centered
                >
                    <TitleSuccess>Sửa thông báo</TitleSuccess>
                    <div className='flex mgt-2 align-center'>
                        <p className='text-left text-large mgr-2 mgb-0'>Lặp lại: </p>
                        <Switch
                            checked={isRepeat}
                            onChange={() => this.setState({ isRepeat: !isRepeat })}
                        />
                    </div>
                    {isRepeat &&
                        <div className='item-center row'>
                            {Array(7).fill(undefined).map((_, key) =>{
                                const day = key == 6 ? 'CN' : `Thứ ${key + 2}`;
                                return(
                                    <div className='col-md-2 col-sm-2 col-xs-4 mgt-2' key={key}>
                                        <p className='text-large'>{day}: </p>
                                        <Switch
                                            checked={!inputExclude.includes(key)}
                                            onChange={(state) => {
                                                const listArr = inputExclude.split(",").filter(n => n);
                                                if(state){
                                                    this.setState({
                                                        inputExclude: listArr.filter(i => i != key).sort().toString()
                                                    })
                                                }
                                                else{
                                                    this.setState({
                                                        inputExclude: [...listArr, key].sort().toString()
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <p className='text-left text-large mgt-2'>Loại thông báo: </p>
                    <select
                        className='form-control'
                        value={inputType}
                        onChange={e => this.setState({ inputType: e.target.value })}
                    >
                        {Object.keys(NOTIFICATION_TYPE).map((item) =>
                            <option value={item} key={item}>{NOTIFICATION_TYPE[item]}</option>
                        )}
                    </select>
                    <p className='text-left text-large mgt-2'>Thời gian:</p>
                    <div className='flex align-center date-study-picker mgt'>
                        <ReactDatePicker
                            selected={inputTime}
                            onChange={inputTime => this.setState({ inputTime })}
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            timeCaption="Thời gian"
                            dateFormat="H:mm"
                            showTimeSelect
                            minDate={Date.now()}
                            placeholderText='Chọn thời gian phát'
                            className='text-left'
                            showTimeSelectOnly
                        />
                    </div>
                    <p className='text-left text-large mgt-2'>Tiêu đề:</p>
                    <input
                        className='form-control mgt'
                        value={inputTitle}
                        onChange={e => this.setState({ inputTitle: e.target.value })}
                        placeholder='Nhập tiêu đề'
                    />
                    <p className='text-left text-large mgt-2'>Nội dung:</p>
                    <input
                        className='form-control mgt'
                        value={inputBody}
                        onChange={e => this.setState({ inputBody: e.target.value })}
                        placeholder='Nhập nội dung'
                    />
                    <div className='item-center mgt-3'>
                        <Button
                            className='bg-success mgt'
                            onClick={this.save}
                            loading={isPress}
                        >
                            Lưu
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps)(Notification)