import React, { Component } from "react";
import { changeLanguageApi } from "../../api/language";
import { connect } from "react-redux";
import { showNotification } from "../../utils/notification";
import { getCountMission, updateDataUser } from "../../actions/home";
import { MESSAGE } from "../../utils/constants";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import { addVoice } from "../../actions/voice";

const LANGUAGE_SELECT = {
    UK: 1,
    JA: 2,
    KO: 3,
    CN: 4,
    SP: 5,
    GE: 6,
    FR: 7,
    RU: 8,
    AR: 9,
}

const languages = [
    {image: require('../../assets/image/uk.png'), type: LANGUAGE_SELECT.UK},
    {image: require('../../assets/image/ja.png'), type: LANGUAGE_SELECT.JA},
    {image: require('../../assets/image/ko.png'), type: LANGUAGE_SELECT.KO},
    {image: require('../../assets/image/cn.png'), type: LANGUAGE_SELECT.CN},
    {image: require('../../assets/image/sp.png'), type: LANGUAGE_SELECT.SP},
    {image: require('../../assets/image/ge.png'), type: LANGUAGE_SELECT.GE},
    {image: require('../../assets/image/fr.png'), type: LANGUAGE_SELECT.FR},
    {image: require('../../assets/image/ru.png'), type: LANGUAGE_SELECT.RU},
    {image: require('../../assets/image/ar.png'), type: LANGUAGE_SELECT.AR},
];

class ButtonLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false
        }
    }

    handleChangeLanguage = (type) =>{
        if(!this.state.isPress && type != this.props.dataUser.Language){
            this.props.updateDataUser('Language', type);
            this.setState({
                isPress: true
            })
            changeLanguageApi(type).then(res =>{
                if(res.code == 1){
                    onPushEventBus(EventBusName.CHANGE_LANGUAGE);
                    const voices = window.speechSynthesis.getVoices();
                    let voice = voices.find(i => i?.name === 'Karen' || i?.name === 'Google US English')
                    if(type == 2){
                        voice = voices.find(i => i?.name === 'Kyoko' || i?.name === 'Google 日本語')
                    }
                    else if(type == 3){
                        voice = voices.find(i => i?.name === 'Yuna' || i?.name === 'Google 한국의')
                    }
                    else if(type == 4){
                        voice = voices.find(i => i?.name === 'Tingting' || i?.name === 'Google 普通话（中国大陆）')
                    }
                    else if(type == 5){
                        voice = voices.find(i => i?.name === 'Monica' || i?.name === 'Google español de Estados Unidos')
                    }
                    else if(type == 6){
                        voice = voices.find(i => i?.name === 'Anna' || i?.name === 'Google Deutsch')
                    }
                    else if(type == 7){
                        voice = voices.find(i => i?.name === 'Amelie' || i?.name === 'Google français')
                    }
                    else if(type == 8){
                        voice = voices.find(i => i?.name === 'Yuri' || i?.name === 'Google русский')
                    }
                    else if(type == 9){
                        voice = voices.find(i => i?.name === 'Maged' || i?.name === 'Google हिन्दी')
                    }
                    if(voice?.name){
                        this.props.addVoice(voice)
                    }
                    this.props.getCountMission();
                }
                showNotification(res.message, res.code);
                this.setState({
                    isPress: false
                })
            }).catch(error => {
                showNotification(MESSAGE.ERROR, 0);
                this.setState({
                    isPress: false
                })
            })
        }
    }

    render() {
        const {Language} = this.props.dataUser;
        return (
            <div className={this.props.widget ? 'flex flex-row mgt mgb' : 'flex flex-row relative'} style={{top: '0.5vh'}}>
            {languages.map(item =>(
                <div className=' flex flex-1 item-center' key={item.type}>
                    <div
                        className={`language-flag-container ${Language == item.type ? 'bg-success' : ''}`}
                        onClick={() => this.handleChangeLanguage(item.type)}
                    >
                        <img src={item.image} className={this.props.normal ? 'language-flag' : 'language-flag-small'} />
                    </div>
                </div>
            ))}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser }
}

export default connect(mapStateToProps, {updateDataUser, addVoice, getCountMission})(ButtonLanguage);