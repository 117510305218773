import React, {Component} from 'react';
import {connect} from 'react-redux';
import HeaderLottie from '../../components/HeaderLottie';
import governor from '../../assets/lottie/governor.json';
import {getListClubApi, getListClaimApi, claimClubApi} from '../../api/club/list';
import { showNotification } from '../../utils/notification';
import { MESSAGE } from '../../utils/constants';
import Loading from '../../components/Loading';
import { priceFormat } from '../../utils/common';
import {Button} from 'antd';
import {withRouter} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import Countdown, { zeroPad } from 'react-countdown-now';

class ClubListJoin extends Component{
    constructor(props){
        super(props);
        this.state={
            listClub: [],
            listClaim: [],
            timeQueue: false,
            searchText: '',
            isLoading: true,
            endList: false,
            isPress: false
        }
        this.page = 1;
        this.searchText = '';
    }

    getListClaim = () =>{
        getListClaimApi().then(res =>{
            if(res.code == 1) this.setState({
                listClaim: res.listClaim,
                timeQueue: res.timeQueue
            });
        }).catch(e =>showNotification(MESSAGE.ERROR, 0));
    }

    getListClub = (isSearch = false) =>{
        getListClubApi(this.page, this.searchText).then(res =>{
            if(res.code == 1){
                this.setState({
                    listClub: isSearch ? res.listClub : [...this.state.listClub, ...res.listClub],
                    endList: res.listClub.length == 0,
                    isLoading: false
                })
            }
            else{
                this.setState({
                    listClub: [],
                    isLoading: false,
                    endList: true
                });
                showNotification(MESSAGE.ERROR, 0);
            }
        }).catch(e =>{
            this.setState({
                listClub: [],
                isLoading: false,
                endList: true
            });
            showNotification(MESSAGE.ERROR, 0);
        });
    }

    loadMore = () =>{
        this.page += 1;
        this.getListClub();
    }

    renderClaimClub = (clubId) =>{
        let {isPress, listClaim} = this.state;
        if(listClaim.includes(clubId)){
            return(
                <div className='flex-column item-center'>
                    <div className='mgt' id={'notificationClamClub' + clubId} />
                    <Button
                        className='bg-warning mgt'
                        onClick={() => this.claimClub(clubId, 2)}
                        loading={isPress == clubId}
                    >
                        Huỷ yêu cầu
                    </Button>
                </div>
            )
        }
        return(
            <div className='flex-column item-center'>
                <div className='mgt' id={'notificationClamClub' + clubId} />
                <Button
                    className='bg-success mgt'
                    onClick={() => this.claimClub(clubId, 1)}
                    loading={isPress == clubId}
                >
                    Xin gia nhập
                </Button>
            </div>
        )
    }

    claimClub = (clubId, action) =>{
        let {listClaim, isPress} = this.state;
        if(!isPress){
            this.setState({isPress: clubId});
            let idNotification = '#notificationClamClub' + clubId;
            claimClubApi(clubId).then(res =>{
                if(res.code == 1){
                    this.setState({
                        isPress: false,
                        listClaim: action == 1 ? [...listClaim, clubId] : listClaim.filter(el => el != clubId)
                    });
                }
                else this.setState({isPress: false});
                showNotification(res.message, res.code, idNotification);
            }).catch(e =>{
                console.log(e)
                this.setState({isPress: false});
                showNotification(MESSAGE.ERROR, 0, idNotification);
            })
        }
    }

    searchClub = e =>{
        this.searchText = e.target.value;
        this.page = 1;
        this.setState({searchText: this.searchText});
        this.getListClub(true);
    }

    componentDidMount(){
        this.getListClub();
        if(!this.props.dataUser.ClubId) this.getListClaim();
    }

    render(){
        let {isLoading, endList, listClub, timeQueue, searchText} = this.state;
        if(isLoading) return(<Loading />);
        return(
            <div>
                <HeaderLottie source={governor}/>
                <div className='flex-column item-center'>
                    <p className='bold text-title-gift mgb'>Danh sách Club</p>
                </div>
                <div className='row item-center'>
                    <div className='col-sm-6 col-xs-12'>
                        <input
                            className='form-control text-center'
                            placeholder='Nhập tên Club 🔎'
                            value={searchText}
                            onChange={this.searchClub}
                        />
                    </div>
                </div>
                {timeQueue && 
                    <div className='club-claim-time-queue'>
                        <p className='text-large'>Thời gian chờ để gia nhập Club mới:
                            <Countdown 
                                date={timeQueue}
                                renderer={({ days, hours, minutes, seconds}) =>(<span> {days} ngày {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                            />
                        </p>
                    </div>
                }
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endList}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {listClub.map((item, key) =>
                        <div key={key} className='club-list-container'>
                            <div className='flex-row item-center'>
                                <div className='flex-column item-center'>
                                    <div className='rank-club-cirle '>
                                        {item.Rank}
                                    </div>
                                    <p className='mgb-2 text-center text-large mgl'>{item.ClubName}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-6 col-xs-12 flex align-center'>
                                    <div className='flex-column'>
                                        <div className='flex-row align-center'>
                                            <p className='mgb-0 text-left'>Cấp độ: {item.ClubVipLevel}</p>
                                            <img src={require("../../assets/image/club-level.png")} className='star-rank mgl' />
                                        </div>
                                        <p className='mgb-0 text-left'>Thành viên: {item.CountMember}/50</p>
                                        <p className='mgb-0 text-left'>Chủ nhiệm: {item.FullName}</p>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-12 flex-column'>
                                    <p className='mgb-0 text-left'>Tổng: {item.SumAchievement} thành tựu</p>
                                    <p className='mgb-0 text-left'>Quỹ thăng hạng: {priceFormat(item.ClubBalance)} đ[z]</p>
                                    <p className='mgb-0 text-left'>Offline: {item.Address}</p>
                                </div>
                            </div>
                            {!this.props.dataUser.ClubId && this.renderClaimClub(item.ClubId)}
                        </div>
                    )}
                </InfiniteScroll>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps)(withRouter(ClubListJoin));