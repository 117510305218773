import {URI_PATH} from '../../../utils/constants';
const axios = require('axios');
const qs = require('qs');

export const updateNotificationApi = ({
    Title, Body, IsRepeat, Time, FcmId, Type, Exclude
}) =>{
    let url = `${URI_PATH}app/admin/fcm/action/updateNotification`;
    let body = {
        Title, Body, IsRepeat, Time, FcmId, Type, Exclude
    };
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const getListNotificationApi = () =>{
    let url = `${URI_PATH}app/admin/fcm/action/getListNotification`;
    let body = {};
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}

export const deleteNotificationApi = (FcmId) =>{
    let url = `${URI_PATH}app/admin/fcm/action/deleteNotification`;
    let body = {
        FcmId
    }
    return axios.post(url, qs.stringify(body)).then((res) => res.data);
}