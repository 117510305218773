import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LessonStudent from '../../components/study/LessonStudent';
import { isMaster, isStudent, isTeacher } from '../../utils/common';
import LessonTeacher from '../../components/study/LessonTeacher';
import LessonMaster from '../../components/study/LessonMaster';
import { updateStateNavigate } from '../../actions/menu';
import StudyClass from './StudyClass';
import StudySummary from './StudySummary';
import StudySubject from './StudySubject';
import { Button } from 'antd';
import HeaderLottie from '../../components/HeaderLottie';
import teacher from '../../assets/lottie/teacher.json';
import { COLOR, MESSAGE, PROP } from '../../utils/constants';
import { getListPackageApi } from '../../api/study/user';
import { showErrorZcrazy, showNotification } from '../../utils/notification';
import { showHideModal } from '../../actions/study';
import { getDayExpireKTNApi } from '../../api/study/lesson';
import Logo from '../../components/lobby/Logo';
import { checkManageSupportApi, getCountManageSupportApi } from '../../api/admin/study/support';
import CSHV from './CSHV';
import KTN from './KTN';
const ButtonGroup = Button.Group;

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelect: props.studyTab || this.getTabSelect(),
            showModal: false,
            listPackage: [],
            dayExpireKTN: -1,
            isManageSupport: false,
            listCountSupport: {}
        }
    }

    getDayExpireKTN = () => {
        getDayExpireKTNApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    dayExpireKTN: res.data
                })
            }
        })
    }

    getTabSelect = () => {
        switch (true) {
            case isMaster():
                return 1;
            case isTeacher():
                return 2;
            case isStudent():
                return 4
        }
        return 5;
    }

    renderTab = () => {
        switch (this.state.tabSelect) {
            case 1:
                return <LessonMaster />
            case 2:
                return <LessonTeacher />
            case 3:
                return <StudyClass />
            case 4:
                return <LessonStudent onNavigateStudySubject={() => this.setTab(6)} />
            case 5:
                return <StudySummary />
            case 6:
                return <StudySubject onNavigateStudyLearn={() => this.setTab(4)} />
            case 7:
                return <CSHV />
            case 8:
                return <KTN />
            case 9:
                return (
                    <>
                        <div className='content-study-register-container'>
                            <div className='item-center'>
                                <p className='text-large bold mgt'>CỘNG ĐỒNG TRẺ THẾ HỆ Z</p>
                                <p className='text-large bold mgt'>HỌC HỎI TRÊN CON ĐƯỜNG THÀNH CÔNG</p>
                            </div>
                            <div className='content-study-register'>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Học tập thông minh</i></p>
                                        <p><i>"Thầy đứng trên vai người khổng lồ</i></p>
                                        <p><i> còn bạn hãy đứng trên vai thầy!"</i></p>
                                        <p><i> Nguyễn Minh Ngọc ™</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/ht.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/mt.png')} className='img-study-alpha-intro' />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column'>
                                        <p className='bold text-center text-large mgt-2'><i>Bám chắc lộ trình</i></p>
                                        <p className='text-content-study-register'><i>{`• Bước 1: Học tập
• Bước 2: Kết nối
• Bước 3: Xây dựng thương hiệu cá nhân
• Bước 4: Định hướng cuộc sống
• Bước 5: Khởi nghiệp thông minh
• Bước 6: Làm chủ cuộc đời lớn.
`}</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Phát huy đúng chỗ</i></p>
                                        <p><i>“Cá không thể leo cây, hãy chọn đúng môi trường.”</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/kd.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/z.png')} className='img-study-alpha-intro' style={{ border: '1px solid #9c9c9c', borderRadius: 35 }} />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Uy tín bền lâu</i></p>
                                        <p className='text-content-study-register'><i>{`“Người uy tín, chọn làm sản phẩm uy tín.”`}</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold text-large text-center mgt-2'><i>Sống thật nhẹ đầu</i></p>
                                        <p className='text-content-study-register'><i>{`“Có sao nói vậy, thật là nhất.”`}</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 item-center'>
                                        <img src={require('../../assets/image/alpha/sm.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex item-center'>
                                        <img src={require('../../assets/image/alpha/cs.png')} className='img-study-alpha-intro' />
                                    </div>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold  text-large mgt-2'><i>Cùng nhau lợi thế</i></p>
                                        <p><i>“Cộng đồng tốt, gia tăng uy tín cá nhân cực tốt.”</i></p>
                                    </div>
                                </div>
                                <div className='box-study-alpha-intro row'>
                                    <div className='col-md-8 col-sm-7 col-xs-12 flex-column item-center'>
                                        <p className='bold text-center text-large mgt-2'><i>Chắc chắn thành công</i></p>
                                        <p className='text-content-study-register text-center'><i>{`“Thực sự có cầu, bắt đầu sản xuất.”`}</i></p>
                                    </div>
                                    <div className='col-md-4 col-sm-5 col-xs-12 flex-column item-center'>
                                        <img src={require('../../assets/image/alpha/tn.png')} className='img-study-alpha-intro' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
        }
        return null;
    }

    setTab = (tabSelect) => {
        this.setState({ tabSelect })
        this.props.updateStateNavigate({ studyTab: tabSelect })
    }

    buttonGroupClass = (selectItem) => {
        if (selectItem == this.state.tabSelect) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    checkManageSupport = () => {
        checkManageSupportApi().then(res => {
            if (res.code == 1) {
                this.setState({ isManageSupport: res.isManageSupport })
                this.getCountManageSupport();
            }
        })
    }

    getCountManageSupport = () => {
        getCountManageSupportApi().then(res => {
            if (res.code == 1) this.setState({ listCountSupport: res.data })
        })
    }

    // renderNavigate = () => (
    //     <div className="row">
    //         <ButtonGroup className='item-center flex mgb-2' style={{ width: '100%' }}>
    //             {isMaster() &&
    //                 <Button className={this.buttonGroupClass(1)} onClick={() => this.setTab(1)}>Chấm điểm</Button>
    //             }
    //             {isTeacher() &&
    //                 <>
    //                     <Button className={this.buttonGroupClass(2)} onClick={() => this.setTab(2)}>Hỗ trợ</Button>
    //                     <Button className={this.buttonGroupClass(3)} onClick={() => this.setTab(3)}>Giao bài</Button>
    //                 </>
    //             }
    //             {isStudent() &&
    //                 <Button className={this.buttonGroupClass(4)} onClick={() => this.setTab(4)}>Bài học</Button>
    //             }
    //             <Button className={this.buttonGroupClass(5)} onClick={() => this.setTab(5)}>Bảng điểm</Button>
    //             <Button className={this.buttonGroupClass(6)} onClick={() => this.setTab(6)}>Ds. Môn học</Button>
    //         </ButtonGroup>
    //     </div>
    // )

    viewPoint = () => {
        this.setTab(5)
    }

    renderNavigate = () => (
        <div className="row">
            <ButtonGroup className='item-center flex mgb' style={{ width: '100%' }}>
                {isMaster() &&
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.setTab(1)}>Chấm điểm</Button>
                }
                {isTeacher() &&
                    <>
                        <Button className={this.buttonGroupClass(2)} onClick={() => this.setTab(2)}>Hỗ trợ</Button>
                        <Button className={this.buttonGroupClass(3)} onClick={() => this.setTab(3)}>Giao bài</Button>
                    </>
                }
                {isStudent() &&
                    <Button className={this.buttonGroupClass(4)} onClick={() => this.setTab(4)}>Bài học</Button>
                }
                <Button className={this.buttonGroupClass(5)} onClick={this.viewPoint}>Bảng điểm</Button>
                <Button className={this.buttonGroupClass(9)} onClick={() => this.setTab(9)}>Mục tiêu</Button>
                <Button className={this.buttonGroupClass(8)} onClick={() => this.setTab(8)}>Khóa học</Button>
                {this.state.isManageSupport &&
                    <Button className={this.buttonGroupClass(7)} onClick={() => this.setTab(7)}>CSHV</Button>
                }
                {this.props.listPackage.length > 0 &&
                    <Button className={this.buttonGroupClass(99)} onClick={() => this.props.showHideModal(true)}>QL Học phí</Button>
                }
            </ButtonGroup>
        </div>
    )

    showModalPackage = () => {
        this.setState({ showModal: true })
        getListPackageApi().then(res => {
            if (res.code == 1) {
                this.setState({ listPackage: res.data })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(error => {
            showNotification(MESSAGE.ERROR, 0);
            this.setState({ isPress: false });
        })
    }

    componentDidMount() {
        this.getDayExpireKTN();
        this.checkManageSupport();
    }

    render() {
        const { listPackage } = this.props;
        return (
            <>
                {/* <div style={{ position: 'relative', bottom: '2vh' }}>
                    <HeaderLottie
                        source={teacher}
                        size='25vh'
                    />
                </div> */}
                <Logo />
                {this.renderNavigate()}
                {this.renderTab()}
                <div style={{ height: '15vh' }} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { studyTab } = state.getStateNavigate;
    const { listPackage, showModal } = state.getDataPackage;
    return { dataUser, studyTab, listPackage, showModal };
}

export default connect(mapStateToProps, { updateStateNavigate, showHideModal })(withRouter(Root));