import { combineReducers } from 'redux';
//home
import getUserData from './home/getUserData';
import getUserItem from './home/getUserItem';
import getUserPresent from './home/getUserPresent';
import getResourceCook from './home/getResourceCook';
import eatModal from './home/eatModal';
import buyVipModal from './home/buyVipModal';
//category
import getCategory from './category/getCategory';
//top
import getDataTop from './top/getDataTop';
//charge
import getItemMarket from './charge/getItemMarket';
import getListCartFood from './charge/getListCartFood';
import getListCartAsset from './charge/getListCartAsset';
import getListBlackMarket from './charge/getListBlackMarket';
import getItemCartSell from './charge/getItemCartSell';
//chat
import getListChat from './chat/getListChat';
//quesion
import getQuestionTrain from './question/getQuestionTrain';
//notification
import getListNotification from './notification/getListNotification';
//setting
import getListTransactionlog from './setting/getListTransactionlog';
//menu
import getCountMission from './menu/getCountMission';
import getStateNavigate from './menu/getStateNavigate';
import getListGuide from './menu/getListGuide';
//newfeed
import getListNewfeed from './newfeed/getListNewfeed';
//config
import getWallpaper from './config/getWallpaper';
//userbar
import getStateUserBar from './userbar/getStateUserBar';
//audio
import getAudio from './audio/getAudio';
//search
import getListSearch from './search/getListSearch';
//study
import getDataPackage from './study/getDataPackage';
//voice
import getVoice from './voice/getVoice';

export default combineReducers({
    getUserData, // home
    getUserItem,
    getUserPresent,
    getResourceCook,
    eatModal,
    buyVipModal,
    getCategory, // category
    getDataTop, // top,
    getItemMarket, // charge
    getListCartFood,
    getListCartAsset,
    getListBlackMarket,
    getItemCartSell,
    getListChat, // chat
    getQuestionTrain,//question
    getListNotification, //notification
    getListTransactionlog, //setting
    getCountMission, //menu,
    getStateNavigate,
    getListGuide,
    getListNewfeed, // newfeed,
    getWallpaper, //config,
    getStateUserBar, // userbar,
    getAudio, //audio,
    getListSearch, // search
    getDataPackage, //study
    getVoice, //voice
});