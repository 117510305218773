import React, { Component } from 'react';
import {connect} from 'react-redux';
import {changePoint, updateDataUser} from '../../actions/home';
import {addQueueApi, actionTransactionApi, cancelTransactionApi, getTicketCostApi} from '../../api/charge/blackmarket';
import {getList, deleteTransaction} from '../../actions/charge/blackmarket';
import { MESSAGE, TICKET, PROP} from '../../utils/constants';
import {formatDecimal, replaceCost, getDecimalCount, priceFormat} from '../../utils/common';
import {showNotification, showNotificationBonus} from '../../utils/notification';
import Loading from '../../components/Loading';
import blackmarket from '../../assets/lottie/blackmarket.json';
import HeaderLottie from '../../components/HeaderLottie';
import { Button, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import TooltipUser from '../../components/TooltipUser';
import UserInfoAff from '../../components/affiliate/UserInfoAff';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';
const ButtonGroup = Button.Group;

class ChargeBlackMarket extends Component{
    constructor(props){
        super(props);
        this.state = {
            ticketMaxSell: 0,
            ticketMaxBuy: 0,
            selectItem: 0,
            amountBuy: '',
            calculateBalancePublish: '',
            isRefreshing: false,
            showModalPublish: false,
            showModalBuySell: false,
            inputZticketPublish: '',
            inputBalancePublish: '',
            isPressAddQueue: false,
            isPressBuySell: false,
            inputZticketBuySell: '',
            selectBuySell: false,
            calculateBalancePublishBuySell: '',
            isPressCancelTransaction: false
        };
    }

    getTicketCost = () =>{
        getTicketCostApi().then(res =>{
            if(res.code == 1) this.setState({ticketMaxBuy: +res.ticketMaxBuy, ticketMaxSell: +res.ticketMaxSell});
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    selectItem = (selectItem) =>{
        if(selectItem != this.props.selectItem){
            if(selectItem == 0) this.props.getList(selectItem, this.props.pageSell + 1);
            else if(selectItem == 1){
                if(this.props.pageBuy == 1) this.props.getList(selectItem);
                else this.props.getList(selectItem, this.props.pageBuy + 1);
            }
            else{
                if(this.props.pageTransaction == 1) this.props.getList(selectItem, 1);
                else this.props.getList(selectItem, this.props.pageTransaction + 1);
            }
        }
    }

    loadMore = () =>{
        let {
            selectItem,
            endListBuy,
            endListSell,
            endListTransaction,
            loadingFirstListBuy,
            loadingFirstListSell,
            loadingFirstListTransaction,
            pageSell,
            pageBuy,
            pageTransaction
        } = this.props;
        if(selectItem == 0 && !endListSell && !loadingFirstListSell) this.props.getList(selectItem, pageSell + 1);
        else if (selectItem == 1 && !endListBuy && !loadingFirstListBuy) this.props.getList(selectItem, pageBuy + 1);
        else if (selectItem == 2 && !endListTransaction && !loadingFirstListTransaction) this.props.getList(selectItem, pageTransaction + 1);
    }

    hideModal = () => this.setState({
        showModalPublish: false,
        showModalBuySell: false,
        isPressAddQueue: false,
        isPressBuySell: false,
        inputZticketPublish: '',
        inputBalancePublish: '',
        inputZticketBuySell: '',
        calculateBalanceBuySell: ''
    });

    openModalPublish = () =>{
        this.setState({showModalPublish: true})
    }

    changeInputPublish = (e, type = 1) =>{
        let num = e.target.value;
        let {inputBalancePublish, inputZticketPublish} = this.state;
        if(type == 1){
            this.setState({
                inputZticketPublish: num?.replace(/,|\./g, ''),
                calculateBalancePublish: formatDecimal((Math.round(parseFloat(num) * replaceCost(inputBalancePublish, false))).toString())
            })
        }
        else if(type == 2){
            this.setState({
                inputBalancePublish: formatDecimal(num?.replace(/,|\./g, '')),
                calculateBalancePublish: formatDecimal((Math.round(replaceCost(num, false) * parseFloat(inputZticketPublish))).toString())
            })
        }
    }

    addQueue = () =>{
        if(this.state.isPressAddQueue === false){
            let idNotification = '#notificationPublishBlackMarket';
            let inputZticketPublish = this.state.inputZticketPublish;
            let inputBalancePublish = replaceCost(this.state.inputBalancePublish, false);
            if(inputZticketPublish == '' || inputZticketPublish <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', 0, idNotification);
            if(getDecimalCount(inputZticketPublish) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', 0, idNotification);
            if(inputBalancePublish == '' || inputBalancePublish < 1) return showNotification('Số VNĐ phải lớn hơn 0 và không được để trống', 0, idNotification);
            this.setState({isPressAddQueue: true});
            let type = this.props.selectItem == 0 ? 1 : 2;
            addQueueApi(inputZticketPublish, inputBalancePublish, type).then(res =>{
                if(res.code == 1){
                    if(type == 1){
                        this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff - +res.data)
                    }
                    else{
                        showNotificationBonus(res.data, -1, 1);
                        this.props.changePoint([-res.data]);
                    }
                    this.hideModal();
                    showNotification(res.message, res.code);
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPressAddQueue: false});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPressAddQueue: false});
            })
        }
    }

    openModalBuySell = (item) =>{
        this.setState({showModalBuySell: true, selectBuySell: item});
    }

    changeTicketBuySell = e =>{
        let num = e.target.value?.replace(/,|\./g, '');
        let idNotification = '#notificationBuySellBlackMarket';
        let ticketAmountMax = this.state.selectBuySell.TicketAmount;
        let balancePerTicket = this.state.selectBuySell.BalancePerTicket;
        if(num > ticketAmountMax){
            showNotification(`Bạn chỉ có thể ${this.props.selectItem == 0 ? 'mua' : 'bán'} tối đa ${ticketAmountMax} Gold[z] từ người ${this.props.selectItem == 0 ? 'bán' : 'mua'}`, 0, idNotification);
            return this.setState({
                inputZticketBuySell: '',
                calculateBalanceBuySell: ''
            });
        }
        this.setState({
            inputZticketBuySell: num,
            calculateBalanceBuySell: num > 0 ? formatDecimal(`${num * balancePerTicket}`) : ''
        })
    }

    buySell = () =>{
        if(this.state.isPressBuySell === false){
            let idNotification = '#notificationBuySellBlackMarket';
            let inputZticketBuySell = this.state.inputZticketBuySell;
            if(inputZticketBuySell == '' || inputZticketBuySell <= 0) return showNotification('Số Gold[z] phải lớn hơn 0 và không được để trống', 0, idNotification);
            if(getDecimalCount(inputZticketBuySell) > TICKET.DECIMAL_COUNT) return showNotification('Số Gold[z] lẻ tối thiểu là ' + TICKET.MIN_TICKET + ' Gold[z]', 0, idNotification);
            this.setState({isPressBuySell: true});
            let type = this.props.selectItem == 0 ? 2 : 1;
            let blackmarketId = this.state.selectBuySell.BlackmarketId;
            let ticketAmount = inputZticketBuySell;
            actionTransactionApi(blackmarketId, ticketAmount).then(res =>{
                if(res.code == 1){
                    if(type == 1){
                        showNotificationBonus(res.ticketAmount, -1 , 1);
                        this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff + +res.money)
                        this.props.changePoint([-res.ticketAmount]);
                    }
                    else{
                        showNotificationBonus(res.ticketAmount, 1 , 1);
                        this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff - +res.money)
                        this.props.changePoint([res.ticketAmount]);
                    }
                    this.hideModal();
                    showNotification(res.message, res.code);
                }
                else if (res.code == -99) {
                    this.hideModal();
                    this.props.showModalBuyVip();
                }
                else{
                    showNotification(res.message, res.code, idNotification);
                    this.setState({isPressBuySell: false});
                }
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.setState({isPressBuySell: false});
            })
        }
    }

    cancelTransaction = (blackmarketId) =>{
        if(this.state.isPressCancelTransaction === false){
            this.setState({isPressCancelTransaction: true});
            cancelTransactionApi(blackmarketId).then(res =>{
                if(res.code == 1){
                    if(res.action == 1){
                        this.props.updateDataUser('BalanceAff', +this.props.dataUser.BalanceAff + +res.data)
                    }
                    else{
                        showNotificationBonus(res.data, 1, 1);
                        this.props.changePoint([res.data]);
                    }
                    this.props.deleteTransaction(blackmarketId);
                }
                this.setState({isPressCancelTransaction: false});
                showNotification(res.message, res.code);
            }).catch(e =>{
                showNotification(MESSAGE.ERROR, -1);
                this.setState({isPressCancelTransaction: false});
            })
        }
    }

    buttonGroupClass= (selectItem) =>{
        if(selectItem === this.props.selectItem) return 'button-group-market ' + (selectItem == 0 ? 'bg-success' : 'bg-warning');
        return 'button-group-market';
    }

    renderItem = ()=>{
        let {listSell, listBuy, listTransaction, endListBuy, endListSell, endListTransaction, selectItem} = this.props;
        let {
            isPressBuySell,
            showModalPublish,
            showModalBuySell,
            selectBuySell,
            inputZticketBuySell,
            calculateBalanceBuySell,
            inputBalancePublish,
            inputZticketPublish,
            isPressAddQueue,
            calculateBalancePublish
        } = this.state;
        let dataList;
        let buttonColor = selectItem == 0 ? 'bg-success' : 'bg-warning';
        let textColor = selectItem == 0 ? 'success' : 'warning';
        let borderColor = selectItem == 0 ? 'border-success' : 'border-warning';
        //
        if(selectItem == 0) dataList = listSell;
        else if(selectItem == 1) dataList = listBuy;
        else dataList = listTransaction;
        //
        if(selectItem == 0 || selectItem == 1){
            return(
                <div className='text-center'>
                    <Button
                        className={'mgb ' + buttonColor}
                        loading={isPressBuySell}
                        onClick={this.openModalPublish}
                    >
                        {selectItem == 0 ? 'Đăng mua' : 'Đăng bán'}
                    </Button>
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={selectItem == 0 ? !endListSell : !endListBuy}
                        loader={<Loading key={0} small/>}
                        threshold={10}
                    >
                        {dataList.map((item, key) =>(
                            <div
                                className='flex item-center'
                                key={key}
                                onClick={() => this.openModalBuySell(item)}
                            >
                                <div className='container-blackmarket flex-row pointer'>
                                    <div className='flex-2 item-center'>
										<TooltipUser userInfo={item} avatarClass='avatar-guest'/>
                                    </div>
                                    <div className='flex-2 item-center'>
                                        <p className='text-center'>{formatDecimal(item.BalancePerTicket)} VNĐ/Gold[z]</p>
                                    </div>
                                    <div className='flex-2 item-center'>
                                        <p className='text-center'>Còn {item.TicketAmount} Gold[z]</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                    {showModalBuySell &&
                        <Modal
                            {...PROP.MODAL}
                            visible={showModalBuySell}
                            onCancel={this.hideModal}
                        >
                            <div>
                                <p className={'text-huge text-center bold ' + textColor}>
                                    {selectItem == 0 ? 'MUA Gold[z]' : 'BÁN Gold[z]'}
                                </p>
                                <p className={'text-large text-center ' + textColor}>
                                    Thị trường tự do
                                </p>
                                <div className='item-center mgt'>
									<TooltipUser userInfo={selectBuySell} avatarClass='avatar-guest'/>
                                    <p className='text-center mgt'>{formatDecimal(selectBuySell.BalancePerTicket)} VNĐ/Gold[z]</p>
                                    <p className='text-center'>Còn {selectBuySell.TicketAmount} Gold[z]</p>
                                    <div className="row item-center">
                                        <div className='col-xs-6'>
                                            <p className={'text-center text-large mgt bold ' + textColor}>
                                                Số lượng Gold[z]:
                                            </p>
                                            <input
                                                className={'form-control text-center mgt ' + borderColor}
                                                value={`${inputZticketBuySell}`}
                                                placeholder="Nhập số lượng"
                                                onChange={this.changeTicketBuySell}
                                                type='number'
                                            />
                                        </div>
                                        <div className='col-xs-6'>
                                            <p className={'text-center text-large mgt bold ' + textColor}>
                                                Thành tiền (VNĐ):
                                            </p>
                                            <input
                                                className={'form-control text-center mgt ' + borderColor}
                                                value={calculateBalanceBuySell ? `${calculateBalanceBuySell} VNĐ` : ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='mgt' id='notificationBuySellBlackMarket'/>
                                    <Button
                                        className={'mgt-2 ' + buttonColor}
                                        loading={isPressBuySell}
                                        onClick={this.buySell}
                                    >
                                        Xác nhận
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    }
                    {showModalPublish &&
                        <Modal
                            {...PROP.MODAL}
                            visible={showModalPublish}
                            onCancel={this.hideModal}
                        >
                            <div>
                                <p className={'text-huge text-center bold ' + textColor}>
                                    {selectItem == 0 ? 'ĐĂNG MUA Gold[z]' : 'ĐĂNG BÁN Gold[z]'}
                                </p>
                                <p className={'text-large text-center ' + textColor}>
                                    Thị trường tự do
                                </p>
                                <p className={'text-large text-center mgt ' + textColor}>
                                    {selectItem == 0 ?
                                        `Giá đăng mua tối thiểu: ${priceFormat(this.state.ticketMaxBuy)}VNĐ/Gold[z]`
                                    :
                                        `Giá đăng bán tối đa: ${priceFormat(this.state.ticketMaxSell)}VNĐ/Gold[z]`
                                    }
                                </p>
                                <div className='flex-column item-center'>
                                    <p className={'text-center text-large mgt bold ' + textColor}>
                                        Số lượng Gold[z]:
                                    </p>
                                    <input
                                        className={'form-control text-center mgt ' + borderColor}
                                        value={`${inputZticketPublish}`}
                                        placeholder="Nhập số lượng"
                                        onChange={e => this.changeInputPublish(e)}
                                    />
                                    <p className={'text-center text-large mgt bold ' + textColor}>
                                        Số tiền 1 Gold[z](VNĐ):
                                    </p>
                                    <input
                                        className={'form-control text-center mgt ' + borderColor}
                                        value={`${inputBalancePublish}`}
                                        placeholder="Nhập số VNĐ"
                                        onChange={e => this.changeInputPublish(e, 2)}
                                    />
                                    {calculateBalancePublish && calculateBalancePublish != 'NaN' ? 
                                        <p className={'text-center bold mgt ' + textColor}>
                                            Số VNĐ bạn thực {selectItem == 0 ? 'trả' : 'nhận'}: {calculateBalancePublish} VNĐ
                                        </p>
                                    : null }
                                    <div className='mgt' id='notificationPublishBlackMarket'></div>
                                    <Button
                                        className={'mgt-2 ' + buttonColor}
                                        loading={isPressAddQueue}
                                        onClick={this.addQueue}
                                    >
                                        Xác nhận
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    }
                </div>
            )
        }
        return(
            <div className='text-center'>
                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={!endListTransaction}
                    loader={<Loading key={0} small/>}
                    threshold={10}
                >
                    {dataList.map((item, key) =>(
                        <div
                            key={key}
                            className={'container-blackmarket flex-column pointer ' + (item.Type == 1 ? 'border-success' : 'border-warning')}
                            style={{borderWidth: '0.4vh'}}
                        >
                            <div className='flex-row flex-1 item-center'>
                                <div className='flex-1 item-center'>
                                    <p className={'text-center button-sell-blackmarket ' + (item.Type == 1 ? 'bg-success' : 'bg-warning')}>
                                        {item.Type == 1 ? 'Mua vào' : 'Bán ra'}
                                    </p>
                                </div>
                                <div className='flex-1 item-center'>
                                    <p className='text-center'>{item.TicketAmount} Gold[z]</p>
                                </div>
                                <div className='flex-1 item-center'>
                                    <p className='text-center'>{formatDecimal(item.BalancePerTicket)} VNĐ/Gold[z]</p>
                                </div>
                            </div>
                            <p className='placeholder'>{item.CrDateTime}</p>
                            <div className='item-center'>
                                <Button
                                    className='mgb bg-danger'
                                    loading={isPressBuySell}
                                    onClick={() => this.cancelTransaction(item.BlackmarketId)}
                                >
                                    Huỷ giao dịch
                                </Button>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        )
    }

    componentDidMount(){
        this.props.getList();
        this.getTicketCost();
    }

    render(){
        return(
            <div>
                <HeaderLottie source={blackmarket} />
                <UserInfoAff hideInfo />
                <ButtonGroup className='item-center mgt-2 mgb' style={{display: 'flex', width: '100%'}}>
                    <Button className={this.buttonGroupClass(0)} onClick={() => this.selectItem(0)}>Mua Gold[z]</Button>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectItem(1)}>Bán Gold[z]</Button>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.selectItem(2)}>Giao dịch của tôi</Button>
                </ButtonGroup>
                {this.renderItem()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        listBuy,
        listSell,
        listTransaction,
        loadingBuy,
        loadingSell,
        loadingTransaction,
        pageBuy,
        pageSell,
        pageTransaction,
        endListBuy,
        endListSell,
        endListTransaction,
        selectItem,
        loadingFirstListBuy,
        loadingFirstListSell,
        loadingFirstListTransaction
    } = state.getListBlackMarket;
    const { dataUser } = state.getUserData;
    return {
        listBuy,
        listSell,
        listTransaction,
        loadingBuy,
        loadingSell,
        loadingTransaction,
        pageBuy,
        pageSell,
        pageTransaction,
        endListBuy,
        endListSell,
        endListTransaction,
        selectItem,
        loadingFirstListBuy,
        loadingFirstListSell,
        loadingFirstListTransaction,
        dataUser
    };
}

export default connect(mapStateToProps, {getList, changePoint, deleteTransaction, updateDataUser, showModalBuyVip})(ChargeBlackMarket);