import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePoint } from '../../actions/home';
import Loading from '../../components/Loading';
import { getDataAffiliateApi, setUsetAffApi, deleteAffApi, unsetUserAffApi } from '../../api/charge/affiliate';
import { showNotification, showNotificationProperty } from '../../utils/notification';
import { MESSAGE, PROP, URI_PATH } from '../../utils/constants';
import { priceFormat, getAvatarFb } from '../../utils/common';
import SearchUser from '../../components/SearchUser';
import TooltipUser from '../../components/TooltipUser';
import { Modal, Button, Tooltip } from 'antd';
import Icon from 'react-fontawesome';
import {
    getListChatPeople,
    showHideChat
} from '../../actions/chat';
import { showModalBuyVip } from '../../actions/home/modalBuyVip';

class ChargeAffiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataAffiliate: [],
            showModal: false,
            userInfo: {},
            modalType: 1
        }
        this.isPress = false;
        this.page = 1;
        this.linkAff = 'https://zcity.vn/lobby?affiliate=' + this.props.dataUser.UserId
    }

    getDataAffiliate = () => {
        getDataAffiliateApi().then(res => {
            if (res.code == 1) {
                this.setState({
                    dataAffiliate: res.dataAffiliate,
                    isLoading: false
                })
            }
            else showNotification(MESSAGE.ERROR, 0);
        }).catch(e => showNotification(MESSAGE.ERROR, 0));
    }

    setUserAff = selectPhone => {
        if (selectPhone[0]) {
            let userId = selectPhone[0].userId;
            let idNotification = '#notificationUserAff';
            setUsetAffApi(userId).then(res => {
                if (res.code == 1) {
                    showNotificationProperty(res.property);
                    this.setState({
                        dataAffiliate: {
                            ...this.state.dataAffiliate,
                            userAff: {
                                UserId: res.toUserId,
                                FullName: res.fullName,
                                PhoneNumber: selectPhone[0].name,
                                FacebookId: res.facebookId,
                                TodayBalance: 0,
                                TodayAchievement: 0
                            }
                        }
                    })
                    showNotification(res.message, res.code, idNotification);
                }
                else if (res.code == -99) {
                    this.props.showModalBuyVip();
                }
                else {
                    showNotification(res.message, res.code, idNotification);
                }
            }).catch(e => showNotification(MESSAGE.ERROR, 0, idNotification));
        }
    }

    deleteAff = userAff => {
        if (!this.isPress) {
            this.isPress = true;
            let idNotification = '#notificationDeleteAff';
            deleteAffApi(userAff).then(res => {
                if (res.code == 1) {
                    this.setState({
                        dataAffiliate: {
                            ...this.state.dataAffiliate,
                            listUserTarget: this.state.dataAffiliate.listUserTarget.filter(el => { return el.UserId != userAff; }
                            )
                        },
                        showModal: false
                    });
                    showNotification(res.message, res.code);
                }
                else showNotification(res.message, res.code, idNotification);
                this.isPress = false;
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0, idNotification);
                this.isPress = false;
            });
        }
    }

    showModal = userInfo => {
        this.setState({
            userInfo,
            showModal: true,
            modalType: 1
        });
    }

    copyLinkAff = () => {
        var textArea;
        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        function selectText() {
            var range, selection;
            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        createTextArea(this.linkAff);
        selectText();
        copyTo();
        showNotification('Sao chép link kết nạp thành công', 1, '#notificationCopyLinkAff');
    }

    unsetUserAff = () => {
        if (!this.isPress) {
            this.isPress = true;
            unsetUserAffApi().then(res => {
                if (res.code == 1) {
                    this.setState({
                        showModal: false,
                        dataAffiliate: {
                            ...this.state.dataAffiliate,
                            userAff: null
                        }
                    })
                }
                showNotification(res.message, res.code);
                this.isPress = false;
            }).catch(e => {
                showNotification(MESSAGE.ERROR, 0);
                this.isPress = false;
            });
        }
    }

    componentDidMount() {
        this.getDataAffiliate();
    }

    render() {
        let { isLoading, dataAffiliate, userInfo, showModal, modalType } = this.state;
        if (isLoading) return <Loading />
        let { dataUser } = this.props;
        let listSecondary = dataAffiliate.listSecondary?.sort((a, b) => b.ListMemberSub.length > a.ListMemberSub.length ? 1 : -1) || []
        return (
            <div>
                <div className='box-primary item-center' style={{ marginBottom: '0.2vh' }}>
                    {dataAffiliate.userAff ?
                        <div className='flex' style={{ padding: '0 4vh' }}>
                            <div>
                                <TooltipUser userInfo={dataAffiliate.userAff} avatarClass='avatar-guest-huge' />
                                <div className='flex mgt'>
                                    <div
                                        className='row-call-user'
                                    >
                                        <a href={`tel:${dataAffiliate.userAff.PhoneNumber}`}>
                                            <Icon name='phone' style={{ fontSize: '3vh' }} />
                                        </a>
                                    </div>
                                    <div
                                        className='row-call-user'
                                        onClick={() => {
                                            this.props.getListChatPeople(dataAffiliate.userAff.UserId);
                                            this.props.showHideChat(true, false, {
                                                UserId: dataAffiliate.userAff.UserId,
                                                FacebookId: dataAffiliate.userAff.FacebookId,
                                                FullName: dataAffiliate.userAff.FullName,
                                            });
                                        }}
                                    >
                                        <Icon name='comments' style={{ fontSize: '3vh' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='flex-column flex-1 item-center'>
                                <p className='mgt text-center text-large'>{dataAffiliate.userAff.FullName}</p>
                                <p className='mgt text-center text-large'>Phụ trách</p>
                                <img
                                    src={require('../../assets/image/delete.png')}
                                    className='delete-image'
                                    onClick={() => this.setState({
                                        showModal: true,
                                        modalType: 2
                                    })}
                                />
                            </div>
                            {/* <p className='mgt'>Chức vụ: Quân sư</p>
                            <p className='mgt'>+ {priceFormat(dataAffiliate.userAff.TodayBalance)} $[z]</p>
                            <p>+ {priceFormat(dataAffiliate.userAff.TodayAchievement)} thành tựu</p>
                            <p>Mang về 5% thành tựu chủ động</p> */}
                        </div>
                        :
                        <div className='item-center'>
                            <img src={getAvatarFb()} className='avatar-guest-huge' />
                            <p className='mgt'>Chọn người hướng dẫn</p>
                            <SearchUser
                                ref='searchUser'
                                onChange={this.setUserAff}
                                placeholder='Nhập họ tên hoặc số điện thoại'
                            />
                        </div>
                    }
                    <div id='notificationUserAff'></div>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-sm-3 col-xs-12 box-primary flex-column'>
                        <p className='mgb-2 mgt-2 text-large text-center' style={{ fontSize: '2.2vh' }}>
                            Thưởng ngày
                            <Tooltip
                                overlayClassName='tooltip-user'
                                overlayStyle={{ zIndex: 99999 }}
                                title={
                                    <p>Bạn sẽ nhận được 10% giá trị quà thần bí của 12 thành viên bạn trực tiếp phụ trách & 20% giá trị quà thần bí thành viên thứ cấp do 12 thành viên kia quản lý.</p>
                                }
                            >
                                <Icon name='question-circle' style={{ fontSize: '3vh', marginLeft: '1vh' }} />
                            </Tooltip>
                        </p>
                        <p className='mgt-3 text-large text-center'>{priceFormat(dataAffiliate.sumBalanceToday)} $[z]</p>
                    </div>
                    <div className='col-md-6 col-sm-6 col-xs-12 box-primary item-center'>
                        <TooltipUser userInfo={dataUser} avatarClass='avatar-guest-huge mgt' />
                        <p className='mgt text-center text-huge'>{dataUser.FullName}</p>
                    </div>
                    <div className='col-md-3 col-sm-3 col-xs-12 box-primary flex-column'>
                        <p className='mgb-2 mgt-2 text-large text-center' style={{ fontSize: '2.2vh' }}>
                            Thành tựu hôm nay
                            <Tooltip
                                overlayClassName='tooltip-user'
                                overlayStyle={{ zIndex: 99999 }}
                                title={
                                    <p> Bạn sẽ nhận được 5% thành tựu hoạt động của người phụ trách và 2% thành tựu hoạt động của 12 người bạn trực tiếp quản lý</p>
                                }
                            >
                                <Icon name='question-circle' style={{ fontSize: '3vh', marginLeft: '1vh' }} />
                            </Tooltip>
                        </p>
                        <p className='mgt-3 text-large text-center'>{dataAffiliate.sumAchievementToday}</p>
                    </div>
                </div>
                <div className='box-primary'>
                    <p className='text-large text-center'>12 thành viên bạn phụ trách trực tiếp</p>
                    {/* <p className='text-center'>Mang về 10% doanh thu quà thần bí và 2% thành tựu</p> */}
                    {/* <p className='text-center'>Link đăng ký cho người chưa có tài khoản:
                        <b style={{ WebkitUserSelect: 'text' }}> {this.linkAff}</b>
                        <Tooltip
                            {...PROP.TOOLTIP_HOME}
                            overlayClassName='black-tooltip'
                            title={
                                <p>Sao chép</p>
                            }
                        >
                            <Icon className='mgl pointer text-large' name='clipboard' onClick={this.copyLinkAff} />
                        </Tooltip>
                        <span id='notificationCopyLinkAff' />
                    </p> */}
                    <div className='row mgt-2'>
                        {dataAffiliate.listUserTarget.map((item, key) =>
                            <div className='col-md-3 col-sm-4 col-xs-6 item-center mgb-2' key={key}>
                                <TooltipUser userInfo={item} avatarClass='avatar-guest-large' />
                                <p className='mgt'>+ {priceFormat(item.TodayBalance)} $[z]</p>
                                <p>+ {item.TodayAchievement} thành tựu</p>
                                <img
                                    src={require('../../assets/image/delete.png')}
                                    className='delete-image'
                                    onClick={() => this.showModal(item)}
                                />
                            </div>
                        )}
                        {new Array(12 - dataAffiliate.listUserTarget.length).fill(undefined).map((item, key) =>
                            <div className='col-md-3 col-sm-4 col-xs-6 item-center mgb' key={key}>
                                <img src={getAvatarFb()} className='avatar-guest-large' />
                                <p className='mgt'>Tuyển quân</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='box-primary'>
                    <p className='text-large text-center mgb-2'>144 thành viên phụ trách bởi thành viên của bạn</p>
                    {listSecondary.map((item, key) =>
                        <div className='box-primary' style={{ margin: '2vh' }} key={key}>
                            <div className='flex-column item-center'>
                                <div className='flex item-center'>
                                    <TooltipUser userInfo={item} avatarClass='avatar-guest-large' />
                                    <div style={{ padding: '0 4vh' }}>
                                        <p className='text-large'>{item.FullName}</p>
                                        <p className='text-large'>Phụ trách</p>
                                    </div>
                                </div>
                                <div className='hr-aff' />
                                <div className='row mgt-2'>
                                    {item.ListMemberSub.map((item, key) =>
                                        <div className='col-md-3 col-sm-4 col-xs-6 item-center mgb-2' key={key}>
                                            <TooltipUser userInfo={item} avatarClass='avatar-guest-large' />
                                            <p className='mgt'>+ {priceFormat(item.TodayBalance)} $[z]</p>
                                        </div>
                                    )}
                                    {new Array(12 - item.ListMemberSub.length).fill(undefined).map((i, key) =>
                                        <div className='col-md-3 col-sm-4 col-xs-6 item-center mgb' key={key}>
                                            <img src={getAvatarFb()} className='avatar-guest-large' />
                                            <p className='mgt'>+0 $[z]</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {new Array(12 - listSecondary.length).fill(undefined).map((i, key) =>
                        <div className='box-primary mgb-2' key={key}>
                            <div className='flex-column item-center'>
                                <img src={getAvatarFb()} className='avatar-guest-large' />
                                <div className='hr-aff' />
                                <div className='row mgt-2'>
                                    {new Array(12).fill(undefined).map((e, k) =>
                                        <div className='col-md-3 col-sm-4 col-xs-6 item-center mgb' key={k}>
                                            <img src={getAvatarFb()} className='avatar-guest-large' />
                                            <p className='mgt'>+0 $[z]</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    {...PROP.MODAL}
                    visible={showModal}
                    onCancel={() => this.setState({ showModal: false })}
                    centered
                >
                    {modalType == 1 ?
                        <div className='flex-column item-center'>
                            <p className='text-large mgt'>Xác nhận huỷ liên kết với {userInfo.FullName} ? </p>
                            <img src={getAvatarFb(userInfo.FacebookId)} className='avatar-guest mgt' />
                            <div className='mgt-6' id='notificationDeleteAff' />
                            <Button
                                className='bg-success'
                                onClick={() => this.deleteAff(userInfo.UserId)}
                            >
                                Đồng ý
                            </Button>
                        </div>
                        :
                        <div className='flex-column item-center'>
                            {dataAffiliate.userAff &&
                                <>
                                    <p className='text-large mgt mgb-2'>Xác nhận huỷ liên kết với người phụ trách ? </p>
                                    <TooltipUser userInfo={dataAffiliate.userAff} avatarClass='avatar-guest-huge' />
                                    <p className='mgt text-center text-large'>{dataAffiliate.userAff.FullName}</p>
                                    <Button
                                        className='bg-success mgt-3'
                                        onClick={() => this.unsetUserAff()}
                                    >
                                        Đồng ý
                                    </Button>

                                </>
                            }
                        </div>
                    }
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, getListChatPeople, showHideChat, showModalBuyVip })(ChargeAffiliate);