const INITAL_STATE = {
    showModalBuyVip: false,
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_MODAL_BUY_VIP':
            return { ...state, showModalBuyVip: true };
        case 'CLOSE_MODAL_BUY_VIP':
            return { ...state, showModalBuyVip: false };
        default:
            return state;
    }
};