import { Component } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { MESSAGE, URI_SOCKET} from '../../utils/constants';
import {changePoint, updateDataUser} from '../../actions/home/';
import {acceptInviteApi} from '../../api/brand/group';
import {getAvatarFb} from '../../utils/common';
import {showNoificationWinBoss, showNotification, showNotificationAvatar, showNotificationBonus, showNotificationFight} from '../../utils/notification';
import socketIO from 'socket.io-client';
import iziToast from 'izitoast';
import '../../assets/css/iziToast.css';

export const socket = socketIO(URI_SOCKET, {jsonp: false});

class ReadSocketEvent extends Component{
    constructor(props){
        super(props);
        socket.emit('create_userId', props.dataUser.UserId);
        socket.on('get_notify', function(data){
            let message = data.message;
            delete data.UserId;
            delete data.message;
            let i = 0;
            Object.keys(data).forEach(function(key) {
                if(key != 'DiamondCount'){
                    i++;
                    showNotificationBonus(data[key], 1, i);
                }
            });
            if(message){
                showNotificationAvatar(message);
            }
            if(data.TicketCount > 0) props.changePoint([data.TicketCount]);
            if(data.Balance > 0) props.changePoint([null, data.Balance]);
            if(data.BalanceZ > 0) props.changePoint([null, null, data.BalanceZ]);
            if(data.Achievement > 0) props.changePoint([null, null, null, data.Achievement]);
            if(data.UserLike > 0) props.changePoint([null, null, null, null, data.UserLike]);
            if(data.DiamondCount > 0) props.changePoint([null, null, null, null, null, data.DiamondCount]);
        });
        //fight
        socket.on('invite_user_fight', roomInfo => showNotificationFight(roomInfo, props.history));
        //invite group
        socket.on('notify_property', function(data){
            iziToast.show({
                timeout: 20000, //20s
                backgroundColor: '#4b5056',
                messageColor: 'white',
                message: data.userName + ' muốn mời bạn vào nhóm thuộc tính',
                position: 'bottomCenter',
                image: getAvatarFb(data.userAvatar),
                pauseOnHover: false,
                balloon: true,
                progressBarColor: 'green',
                displayMode: 1,
                buttons: [
                    ['<button style="color: white; background: #06A65A">Đồng ý</button>', function (instance, toast) {
                        acceptInviteApi(data.userId).then(res =>{
                            if(res.code == 1){
                                if(window.location.pathname == '/menu/brand-group'){
                                    props.history.push('/blank');
									setTimeout(() => {
										props.history.push('/menu/brand-group');
									});
                                }
                                else props.history.push('/menu/brand-group');
                            }
                            else showNotification(res.message, res.code);
                        }).catch(e =>{
                            showNotification(MESSAGE.ERROR, 0);
                        });
                        instance.hide({
                            transitionOut: 'fadeOutUp',
                        }, toast, '');
                    }],
                    ['<button style="color: white; background: #DD4B38">Từ chối</button>', function (instance, toast) {
                        instance.hide({
                            transitionOut: 'fadeOutUp',
                        }, toast, '');
                    }]
                ]
            });
        });
        //blackmarket
        socket.on('receive_blackmarket', function(data){
            if(data.action == 1){
                props.updateDataUser('BalanceAff', +props.dataUser.BalanceAff + +data.money)
            }
            else{
                showNotificationBonus(data.ticketAmount, 1, 1);
                props.changePoint([data.ticketAmount]);
            }
        });
        //notificationDoneFight
        socket.on('notificationDoneFight', function(data){
            iziToast.show({
                timeout: 10000,
                message: data,
                messageColor: 'white',
                backgroundColor: '#4b5056',
                position: 'bottomCenter',
                progressBarColor: '#06A65A',
                image: require('../../assets/image/sword-vs.png'),
                imageWidth: 50,
            });
        });
        //notificationGiftSpin
        socket.on('push_gift_spin', function(data){
            iziToast.show({
                timeout: 10000,
                message: data,
                messageColor: 'white',
                backgroundColor: '#4b5056',
                position: 'topRight',
                progressBarColor: '#06A65A',
                image: require('../../assets/image/money.png'),
                imageWidth: 50,
                iconColor: 'rgb(0, 255, 184)'
            });
        });
        //
        socket.on('receive_dame_boss', function(data){
            if (data.bossData.IsDie == 2){
                showNoificationWinBoss(data.bossData)
            }
        });
    }

    componentDidMount(){
        this.reconnectSocket = setInterval(() =>{ //fix disconnect socket
            if(socket.disconnected){
                socket.connect();
                socket.emit('create_userId', this.props.dataUser.UserId);
            }
        }, 3000);
    }

    componentWillUnmount(){
        clearInterval(this.reconnectSocket);
        socket.close();
    }

    render(){
        return null;
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, {changePoint, updateDataUser})(withRouter(ReadSocketEvent));