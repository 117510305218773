import React, { Component } from 'react';
import { COLOR, MESSAGE, URI_PATH } from '../../utils/constants';
import { connect } from 'react-redux';
import { getBossHistoryApi, getQuestionApi, saveAnswerApi } from '../../api/question/boss';
import { getListRankApi } from '../../api/question/lobby/rank';
import { changePoint } from '../../actions/home/';
import { formatDecimal, speakEng } from '../../utils/common';
import { showNotification, showNotificationBonus, showNotificationAsset, showNoificationDameBoss, showNoificationWinBoss } from '../../utils/notification';
import Loading from '../../components/Loading';
import ResultMessage from '../../components/question/ResultMessage';
import TextAnswer from '../../components/question/TextAnswer';
import Lottie from 'react-lottie';
import firework from '../../assets/lottie/firework.json';
import { showModalEat } from '../../actions/home/modalEat';
import { Button, Progress } from 'antd';
import TooltipUser from '../../components/TooltipUser';
import { getBossTodayApi } from '../../api/question/boss';
import { socket } from '../../components/realtime/ReadSocketEvent';
import { TitleSuccess } from '../../components/frame';
import InfiniteScroll from 'react-infinite-scroller';
import Icon from 'react-fontawesome';
import Countdown, { zeroPad } from 'react-countdown-now';
import { withRouter } from 'react-router-dom';
import { Subscription } from "rxjs";
import EventBus, { EventBusName } from '../../services/event-bus';
import ButtonNewbie from '../../components/newbie/ButtonNewbie';

const ButtonGroup = Button.Group;

class QuestionBoss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percentTime: '0%',
            resultAnswer: false,
            selectAnswerKey: '',
            isAnswer: false,
            questionList: [],
            isLoadingQuestion: true,
            isLoadingHistory: true,
            selectNavigate: 0,
            listHistory: [],
            endList: false,
            pageId: 1,
            userBonus: {},
            isLoading: true,
            bossData: {},
            timeQueue: 999999999,
            timeDelay: 3
        };
        this.timeDiff = 5;
        this.correctAnswer = '';
        this.resultColor = '';
        this.questionInterval = '';
        this.subScription = new Subscription();
    }

    onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res) => {
            if (res.type === EventBusName.CHANGE_LANGUAGE) {
                if (!(this.state.bossData.IsDie == 2 || this.state.timeQueue > 0)) {
                    this.getQuestion();
                    clearInterval(this.questionInterval);
                    this.timeDiff -= 1;
                }
            }
        });
        this.subScription.add(sub);
    };

    getBossToday = () => {
        getBossTodayApi().then(res => {
            if (res.code == 1) {
                const { data, timeQueue } = res;
                this.setState({
                    isLoading: false,
                    bossData: data,
                    timeQueue
                })
                if (data.IsDie == 2 || timeQueue > 0) {
                    this.getBossHistory();
                }
                else {
                    this.getQuestion();
                }
            }
            else showNotification(MESSAGE.ERROR, -1);
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    getQuestion = () => {
        getQuestionApi().then(res => {
            if (res.code == 1) {
                this.questionLobby = res.questionList[0];
                this.timeDiff = res.timeDiff;
                this.setState({
                    isAnswer: false,
                    percentTime: '0%',
                    resultMessage: '',
                    selectAnswerKey: '',
                    isLoadingQuestion: false,
                });
                this.questionInterval = setInterval(() => {
                    this.timeDiff -= 1;
                    this.setState({ percentTime: `${(30 - this.timeDiff) / 30 * 100}%` });
                    if (this.timeDiff <= -1) {
                        this.setState({
                            isAnswer: false,
                            resultAnswer: false,
                            selectAnswerKey: '',
                            isLoadingQuestion: true
                        });
                        this.questionLobby = {};
                        this.correctAnswer = '';
                        this.timeDiff = 30;
                        clearInterval(this.questionInterval);
                        this.getQuestion()
                    }
                }, 1000);
            }
            else {
                if (res.code == -5) this.props.showModalEat(res.listFood);
                else showNotification(res.message, res.code);
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    saveAnswer = (answer, keySelect, questionId) => {
        if (this.state.isAnswer === false) {
            let idNotification = '#notificationQuestionGuest';
            this.setState({ selectAnswerKey: keySelect, isAnswer: true });
            saveAnswerApi(questionId, answer).then(res => {
                if (res.code == 1 || res.code == 0) {
                    this.timeDiff = 3;
                    this.correctAnswer = res.answer;
                    setTimeout(() => {
                        speakEng(res.answer)
                    }, 0)
                    if (res.code == 1) {
                        //assets bonus
                        showNotificationAsset(res.assetInfo);
                        showNoificationDameBoss(this.props.dataUser, res.dame, res.isCrit == 1);
                        const bossData = res.isKillBoss ? res.bossKilledData : {
                            ...this.state.bossData,
                            IsDie: res.isKillBoss ? 2 : 0,
                            CurrentBlood: +this.state.bossData.CurrentBlood - +res.dame
                        }
                        this.setState({
                            resultAnswer: res.code,
                            bossData,
                        })
                        socket.emit('dame_boss', {
                            user: this.props.dataUser,
                            bossData,
                            dame: +res.dame,
                            isCrit: res.isCrit
                        })
                        if (res.isKillBoss) {
                            this.getBossHistory();
                            showNoificationWinBoss(bossData)
                            clearInterval(this.questionInterval);
                        }
                    }
                }
                else if (res.code == -999) {
                    clearInterval(this.questionInterval);
                    this.getBossToday();
                }
                else {
                    if (res.code == -5) this.props.showModalEat(res.listFood);
                    else showNotification(res.message, res.code, idNotification);
                    this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
                }
            }).catch(e => {
                showNotification(MESSAGE.ERROR, -1, idNotification);
                this.setState({ resultAnswer: false, isAnswer: false, selectAnswerKey: '' });
            })
        }
    }

    getBossHistory = (pageId = 1) => {
        if (this.state.listHistory.length == 0) this.setState({ isLoadingHistory: true })
        getBossHistoryApi(pageId).then(res => {
            if (res.code == 1) {
                this.setState({
                    listHistory: pageId == 1 ? res.data : [...this.state.listHistory, ...res.data],
                    isLoadingHistory: false,
                    endList: res.data.length < 20,
                    pageId,
                })
            }
        }).catch(e => {
            showNotification(MESSAGE.ERROR, -1);
        })
    }

    selectNavigate = (selectNavigate) => {
        if (selectNavigate != this.state.selectNavigate) {
            this.setState({ selectNavigate });
        }
    }

    buttonGroupClass = (selectNavigate) => {
        if (selectNavigate === this.state.selectNavigate) return 'button-group-market bg-success';
        return 'button-group-market';
    }

    loadMore = () => {
        if (!this.state.endList) {
            this.getBossHistory(this.state.pageId + 1);
        }
    }

    renderHistory = () => {
        const { isLoadingHistory, endList, listHistory, bossData } = this.state;
        return (
            <>
                {isLoadingHistory ?
                    <Loading small />
                    :
                    <>
                        <InfiniteScroll
                            loadMore={this.loadMore}
                            hasMore={!endList}
                            loader={<Loading key={0} small />}
                            threshold={100}
                        >
                            {listHistory.map((item) =>
                                <div className='question-boss-history-container row' key={item.QuestionBossId}>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                        {item?.UserId > 0 ?
                                            <>
                                                <TooltipUser userInfo={item} />
                                                <p className=''>{item.FullName}</p>
                                            </>
                                            :
                                            <p>Giải cứu thất bại</p>
                                        }

                                    </div>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex-column item-center'>
                                        <p className='text-large'>{item.KillDateTime}</p>
                                    </div>
                                    <div className='col-md-4 col-sm-12 col-xs-12 flex item-center'>
                                        <div className="item-center">
                                            <img className='item-img' alt={item.FoodName} src={`${URI_PATH}${item.FoodImage}`} />
                                            <p>{item.FoodName}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </>
                }
            </>
        )
    }

    renderTimeQueue = () => {
        if (this.state.timeQueue) {
            return (
                <p className='text-center'>Hoạt động giải cứu linh thú diễn ra sau <Countdown
                    date={this.state.timeQueue * 1000}
                    renderer={({ days, hours, minutes, seconds }) => (<span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>)}
                    onComplete={() => {
                        setTimeout(() => {
                            this.getBossToday();
                        }, 2000)
                    }}
                /></p>
            )
        }
        return null;
    }

    renderTab = () => {
        let { isLoadingQuestion, selectAnswerKey, resultAnswer, percentTime, selectNavigate, bossData, timeQueue, timeDelay } = this.state;
        let { dataUser } = this.props;
        switch (selectNavigate) {
            case 0:
                if (bossData.IsDie == 2 || timeQueue > 0) {
                    return (
                        <>
                            {+bossData.UserId > 0 &&
                                <div className='box-primary item-center row'>
                                    <p className='text-center text-large'>
                                        <TooltipUser userInfo={bossData} />
                                        <span className='bold'> {bossData.FullName} </span>
                                        <span>đã giải cứu Linh thú. Thật dũng mãnh!</span>
                                    </p>
                                </div>
                            }
                            <TitleSuccess>Lịch sử giải cứu linh thú</TitleSuccess>
                            {this.renderHistory()}
                        </>
                    )
                }
                return (
                    <>
                        {timeDelay > 0 ?
                            <>
                                <p className='time-delay-question'>{timeDelay}</p>
                            </>
                            :
                            <>
                                <div className='relative'>
                                    <Progress
                                        strokeColor={{ '0%': '#FF0000', '100%': '#2EFF2E' }}
                                        percent={100 * +bossData.CurrentBlood / +bossData.Blood}
                                        showInfo={false}
                                        status='active'
                                        strokeLinecap='square'
                                        className='club-level-progress'
                                    />
                                    <p className='question-boss-blood'>{formatDecimal(bossData.CurrentBlood)} HP</p>
                                </div>
                                <div className='text-center mgt mgb'>
                                    <p>Sức mạnh sẽ tăng khi <u className='pointer' onClick={() => this.props.history.push('/menu/brand-group')}>ghép nhóm có nhiều thành viên</u> và mua ngôi sao may mắn.</p>
                                    <p><i><b>Bất kì ai tham gia giải cứu linh thú đều có cơ hội chiến thắng!</b>!</i></p>
                                </div>
                                <div className='button-question-lobby-container'>
                                    <div className="progress-div mgb" style={{ width: '100%' }}>
                                        <div style={{ width: percentTime, background: this.timeDiff <= 3 ? COLOR.COLOR_DANGER : COLOR.COLOR_SUCCESS }} className="progress" />
                                    </div>
                                </div>
                                {isLoadingQuestion ?
                                    <Loading small />
                                    :
                                    <div className='animated fadeInUp faster'>
                                        <p className='title-question'>{this.questionLobby.QuestionTitle}</p>
                                        {this.questionLobby.answers.map((value, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    onClick={() => this.saveAnswer(value, key, this.questionLobby.QuestionLobbyId)}
                                                >
                                                    <TextAnswer
                                                        answer={value}
                                                        keySelect={key}
                                                        selectAnswerKey={selectAnswerKey}
                                                        correctAnswer={this.correctAnswer}
                                                    />
                                                </div>
                                            )
                                        })}
                                        {resultAnswer !== false && (
                                            <ResultMessage resultAnswer={resultAnswer} />
                                        )}
                                        <div className='notificationQuestionGuest'></div>
                                        {this.state.resultAnswer == 1 &&
                                            <Lottie
                                                options={{ loop: true, autoplay: true, animationData: firework }}
                                                width='50vh'
                                                style={{ position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0 }}
                                            />
                                        }
                                    </div>
                                }
                            </>
                        }
                    </>
                )
        }
        return null
    }

    readDameBossEvent = (data) => {
        try {
            const { bossData } = this.state
            if (bossData.IsDie == 0 && data.bossData.IsDie == 0) {
                showNoificationDameBoss(data.user, data.dame, data.isCrit == 1)
                this.setState({
                    bossData: {
                        ...data.bossData,
                        CurrentBlood: +this.state.bossData.CurrentBlood - +data.dame
                    }
                })
            }
            else if (bossData.IsDie == 0 && data.bossData.IsDie == 2) {
                this.getBossHistory();
                this.setState({ bossData: data.bossData })
            }
        } catch (error) {
            console.log({ error });
        }
    }

    componentDidMount() {
        this.getBossToday();
        socket.on('receive_dame_boss', this.readDameBossEvent);
        this.onRegisterEventBus();

        let counter = 3;
        const delayInterval = setInterval(() => {
            counter--;
            this.setState({ timeDelay: this.state.timeDelay - 1 })
            if (counter == 0) clearInterval(delayInterval)
        }, 1000);
    }

    componentWillUnmount() {
        socket.removeListener('receive_dame_boss', this.readDameBossEvent);
        clearInterval(this.questionInterval);
        if (this.subScription) this.subScription.unsubscribe();
    }

    render() {
        const { isLoading, bossData } = this.state;
        if (isLoading) {
            return <Loading small />
        }
        return (
            <>
                <div className='item-center' style={{ height: '45vh' }}>
                    {bossData.IsDie == 0 &&
                        <>
                            <img
                                src={require('../../assets/image/boss-back-door.png')}
                                className='boss-back-door'
                            />
                            <img
                                src={require('../../assets/image/boss-front-door.png')}
                                className='boss-front-door'
                            />
                        </>
                    }
                    <img
                        src={`${URI_PATH}${bossData.FoodImage}`}
                        className='question-boss-img'
                    />
                </div>
                <ButtonNewbie />
                {this.renderTimeQueue()}
                {/* <ButtonGroup className='item-center' style={{ display: 'flex', width: '100%' }}>
                    <Button className={this.buttonGroupClass(0)} onClick={() => this.selectNavigate(0)}>Siêu trí nhớ</Button>
                    <Button className={this.buttonGroupClass(2)} onClick={() => this.selectNavigate(2)}>Lý thuyết</Button>
                    <Button className={this.buttonGroupClass(1)} onClick={() => this.selectNavigate(1)}>Xếp hạng ngày</Button>
                </ButtonGroup> */}
                {this.renderTab()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    return { dataUser };
}

export default connect(mapStateToProps, { changePoint, showModalEat })(withRouter(QuestionBoss));