import React, { Component } from 'react';
import { PROP, COLOR } from '../utils/constants';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { closeModalBuyVip } from '../actions/home/modalBuyVip';
import { EventBusName, onPushEventBus } from '../services/event-bus';

class ModalBuyVip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPress: false,
        }
    }

    showModalAff = () => {
        this.props.closeModalBuyVip();
        onPushEventBus(EventBusName.SHOW_MODAL_AFF)
    }

    render() {
        return (
            <>
                <Modal
                    {...PROP.MODAL}
                    bodyStyle={{
                        ...PROP.MODAL.bodyStyle,
                        background: COLOR.COLOR_SUCCESS
                    }}
                    visible={this.props.showModalBuyVip}
                    onCancel={this.props.closeModalBuyVip}
                    destroyOnClose={true}
                    centered
                >
                    <div className='item-center'>
                        <p className='text-large text-center mgt-2'>🌟Tính năng đặc quyền dành riêng cho VIP 🎁</p>
                        <p className='text-large text-center mgt'>Zcity trân trọng cảm ơn thành viên VIP vì sự ủng hộ kinh phí giúp nâng cấp cộng đồng phát triển 🚀</p>
                        <Button
                            className='bg-primary mgt-4'
                            onClick={this.showModalAff}
                        >
                            Nâng cấp VIP
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { dataUser } = state.getUserData;
    const { showModalBuyVip } = state.buyVipModal;
    return { showModalBuyVip, dataUser };
}

export default connect(mapStateToProps, { closeModalBuyVip })(ModalBuyVip);
